import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { RolesEnum } from '@irembo-andela/irembogov3-common';
import IIremboUser from '../../../../core/models/users/irembo-user-model';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';
import { ITabLinks } from '../../../../core/models/tablinks.interface';
import { DefaultManageUsersTabLinks } from '../../../../core/config/manager-users-tablinks.config';

@Component({
  selector: 'irembogov-list-users-for-irembogov-admin',
  templateUrl: './list-users-for-irembogov-admin.component.html',
  styleUrls: ['./list-users-for-irembogov-admin.component.scss'],
})
export class ListUsersForIremboGovAdminComponent implements OnDestroy {
  userProfile!: IIremboUser;
  tabFragment: string | null = null;
  selectedTabTemplate!: TemplateRef<unknown> | null;
  private unsubscribe$: Subject<void>;

  RolesEnum = RolesEnum;

  @ViewChild('usersTabTemplate', { static: true })
  usersTabTemplate!: TemplateRef<unknown>;
  @ViewChild('changeRequestsTabTemplate', { static: true })
  changeRequestsTabTemplate!: TemplateRef<unknown>;

  constructor(public route: ActivatedRoute, private authService: AuthService) {
    this.unsubscribe$ = new Subject<void>();

    this.authService.userProfile$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile: IIremboUser) => (this.userProfile = profile));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTabRendersByRole(): ITabLinks[] {
    if (!(this.userProfile && this.userProfile.primaryRole)) return [];

    return Object.values(DefaultManageUsersTabLinks).filter(
      (tablink: ITabLinks) => {
        return tablink.allowedRoles.includes(this.userProfile.primaryRole.key);
      }
    );
  }

  getTabTemplate(fragment: string | null) {
    if (fragment === 'change-requests') {
      return this.changeRequestsTabTemplate;
    } else {
      return this.usersTabTemplate;
    }
  }
}
