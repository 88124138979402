import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { UiModule } from '@irembo-andela/irembogov3-common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared-module/shared.module';
import { InviteNewUserFormComponent } from './manage-users/form-components/invite-new-user-form/invite-new-user-form.component';
import { ViewUserComponent } from './manage-users/view-user/view-user.component';
import { UserStatusBadgeComponent } from './widgets/user-status-badge/user-status-badge.component';
import { ListUsersComponent } from './manage-users/list-users/list-users.component';
import { ListChangeRequestsComponent } from './manage-users/list-change-requests/list-change-requests.component';
import { UserChangeRequestBadgeComponent } from './widgets/user-change-request-badge/user-change-request-badge.component';
import { UserBasicInfoComponent } from './widgets/user-basic-info/user-basic-info.component';
import { UserChangeRequestBasicInfoComponent } from './widgets/user-change-request-basic-info/user-change-request-basic-info.component';
import { ViewUserChangeRequestComponent } from './manage-users/view-user-change-request/view-user-change-request.component';
import { CreateUserComponent } from './manage-users/form-components/create-user/create-user.component';
import { ListUsersForSuperAdminComponent } from './manage-users/list-users-for-super-admin/list-users-for-super-admin.component';
import { ListUsersForIremboGovAdminComponent } from './manage-users/list-users-for-irembogov-admin/list-users-for-irembogov-admin.component';
import { RoleToLabelPipe } from '../../pipes/role-to-label.pipe';
import { ListUsersForClientManagerComponent } from './manage-users/list-users-for-client-manager/list-users-for-client-manager.component';
import { ClientManagerChangeRequestListComponent } from './manage-users/client-manager-change-request-list/client-manager-change-request-list.component';
import { ClientManagerUsersListComponent } from './manage-users/client-manager-users-list/client-manager-users-list.component';
import { ClientManagerInviteServiceManagerComponent } from './manage-users/form-components/client-manager-invite-service-manager/client-manager-invite-service-manager.component';
import { ClientManagerIniviteQaReviewerComponent } from './manage-users/form-components/client-manager-inivite-qa-reviewer/client-manager-inivite-qa-reviewer.component';
import { ClientManagerIniviteGovExecComponent } from './manage-users/form-components/client-manager-inivite-gov-exec/client-manager-inivite-gov-exec.component';
import { ListIrembogovAdminChangeRequestComponent } from './manage-users/list-irembogov-admin-change-request/list-irembogov-admin-change-request.component';
import { ClientManagerChangeRequestFormComponent } from './manage-users/form-components/client-manager-change-request-form/client-manager-change-request-form.component';
import { UsersTableForIremboGovAdminComponent } from './manage-users/users-table-for-irembogov-admin/users-table-for-irembogov-admin.component';

@NgModule({
  declarations: [
    ManageUsersComponent,
    InviteNewUserFormComponent,
    ViewUserComponent,
    UserStatusBadgeComponent,
    ListUsersComponent,
    ListChangeRequestsComponent,
    UserChangeRequestBadgeComponent,
    UserBasicInfoComponent,
    UserChangeRequestBasicInfoComponent,
    ViewUserChangeRequestComponent,
    CreateUserComponent,
    ListUsersForSuperAdminComponent,
    ListUsersForIremboGovAdminComponent,
    ListIrembogovAdminChangeRequestComponent,
    ListUsersForClientManagerComponent,
    ClientManagerChangeRequestListComponent,
    ClientManagerUsersListComponent,
    ClientManagerInviteServiceManagerComponent,
    ClientManagerIniviteQaReviewerComponent,
    ClientManagerIniviteGovExecComponent,
    ClientManagerChangeRequestFormComponent,
    UsersTableForIremboGovAdminComponent,
    RoleToLabelPipe,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    UiModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [ManageUsersComponent, UserStatusBadgeComponent],
})
export class UsersModule {}
