import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import {
  IAccountActivationRequest,
  IAccountActivationResponse,
  IAuthEngineHttpBaseResponse,
  ICheckAccountActivationOtpResponseData,
  ICheckPasswordResetOtpTokenResponseData,
  ICheckUserExistsRequest,
  IRequestPasswordResetTokenRequest,
  IRequestPasswordResetTokenResponseData,
  IResendActivationTokenRequest,
  IResendActivationTokenRequestByExpiredToken,
  IResendActivationTokenResponseData,
  IResetPasswordRequest,
  IResetPasswordResponseData,
} from '../interfaces';
import { EUserType } from '../enums/user-type.enum';
import { ERecipientTypeEnum } from '../enums/recipient-type.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private keyCloakService: KeycloakService,
    private router: Router
  ) {}

  activateAccount(
    authorizationServiceBaseUrl: string,
    request: IAccountActivationRequest
  ) {
    return this.http.post<IAccountActivationResponse>(
      `${authorizationServiceBaseUrl}/v1/user/activate-account`,
      request
    );
  }

  /**
   *  Verify Activation OTP without Invalidating it
   *
   */
  checkActivationOtp(
    authorizationServiceBaseUrl: string,
    oneTimePassword: string,
    userType: EUserType,
    username: string
  ) {
    const headerParams = { oneTimePassword, userType, username };
    const headers = new HttpHeaders(headerParams);
    const url = `${authorizationServiceBaseUrl}/v1/account-activation/check-token-validity`;
    return this.http.get<
      IAuthEngineHttpBaseResponse<ICheckAccountActivationOtpResponseData>
    >(url, { headers });
  }

  resendActivationOtp(
    authorizationServiceBaseUrl: string,
    clientId: string,
    username: string,
    usernameType: ERecipientTypeEnum
  ) {
    const headers = new HttpHeaders();
    const req: IResendActivationTokenRequest = {
      usernameType,
      username,
      clientId,
      userType: EUserType.OTHER,
    };
    const url = `${authorizationServiceBaseUrl}/v1/resend-activation-token`;
    return this.http.post<
      IAuthEngineHttpBaseResponse<IResendActivationTokenResponseData>
    >(url, req, { headers });
  }

  resendActivationOtpUsingExpiredToken(
    authorizationServiceBaseUrl: string,
    clientId: string,
    token: string,
    username: string
  ) {
    const headers = new HttpHeaders();
    const req: IResendActivationTokenRequestByExpiredToken = {
      token,
      clientId,
      userType: EUserType.OTHER,
      username,
    };
    const url = `${authorizationServiceBaseUrl}/v1/account-activation/resend-token`;
    return this.http.post<
      IAuthEngineHttpBaseResponse<IResendActivationTokenResponseData>
    >(url, req, { headers });
  }

  /**
   *  Verify OTP and Invalidates it
   *
   */
  verifyOtpAndInvalidate(
    authorizationServiceBaseUrl: string,
    oneTimePassword: string,
    username: string
  ) {
    const headerParams = {
      oneTimePassword: atob(oneTimePassword),
      username: atob(username),
    };
    const headers = new HttpHeaders(headerParams);
    const url = `${authorizationServiceBaseUrl}/v1/verify-one-time-password`;
    return this.http.get<IAuthEngineHttpBaseResponse<boolean>>(url, {
      headers,
    });
  }

  /**
   *  Check Password reset OTP without invalidating
   *
   */
  checkPasswordResetOtp(
    authorizationServiceBaseUrl: string,
    oneTimePassword: string,
    userType: EUserType,
    username: string
  ) {
    const headerParams = { oneTimePassword, userType, username };
    const headers = new HttpHeaders(headerParams);
    const url = `${authorizationServiceBaseUrl}/v1/reset-password/check-token-validity`;
    return this.http.get<
      IAuthEngineHttpBaseResponse<ICheckPasswordResetOtpTokenResponseData>
    >(url, { headers });
  }

  /**
   * Sends a token to be used while resetting the passwords
   * @param authorizationServiceBaseUrl
   * @param request
   * @returns
   */
  requestPasswordResetToken(
    authorizationServiceBaseUrl: string,
    request: IRequestPasswordResetTokenRequest
  ) {
    const headers = new HttpHeaders({});
    const url = `${authorizationServiceBaseUrl}/v1/reset-password/send-token`;
    return this.http.post<
      IAuthEngineHttpBaseResponse<IRequestPasswordResetTokenResponseData>
    >(url, request, { headers });
  }

  resetPassword(
    authorizationServiceBaseUrl: string,
    request: IResetPasswordRequest
  ) {
    const headers = new HttpHeaders({});
    const url = `${authorizationServiceBaseUrl}/v1/reset-password`;
    return this.http.put<
      IAuthEngineHttpBaseResponse<IResetPasswordResponseData>
    >(url, request, { headers });
  }

  redirectToLogin() {
    return this.keyCloakService.login({
      redirectUri:
        window.location.origin + this.router.routerState.snapshot.url,
    });
  }

  checkUserExists(
    authorizationServiceBaseUrl: string,
    request: ICheckUserExistsRequest
  ) {
    const url = `${authorizationServiceBaseUrl}/v1/user/user-exist-status`;
    const headers = new HttpHeaders({ ...request });
    return this.http.get<IAuthEngineHttpBaseResponse<any>>(url, { headers });
  }
}
