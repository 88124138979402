export const environment = {
  production: true,
  customemailValidationRegex:
    /^[^<>()[\]\\,;:%#^\s"$&!@]+@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/,
  authServiceUrl: 'https://hub-keycloak.uat.irembolabs.com',
  authRealm: 'irembo',
  authClientId: 'administration-management-portal',
  authSuccessRedirectUrl: 'https://admin-portal.uat.irembolabs.com',
  apiGatewayBaseUrl: 'https://admin-gateway.uat.irembolabs.com',
  idleTimeOutDuration: 1800,
  idleTimeOutCountDown: 30,
  enablekeycloakMockService: false,
  personNameRegex: /^[^`~!@#$%^&*()\-_=+[\]{}|\\:;'",.<>?/]*$/,
  translationSourceUrl: 'https://admin-gateway.uat.irembolabs.com',
};
