import { INavbarLinkItem } from '../models/navbar-link-item.model';
import { UserRolesEnum } from '../models/users/user-roles.model';

export const MainNavBarLinkItems: Record<string, INavbarLinkItem> = {
  dashboard: {
    name: 'Dashboard',
    icon: 'fa-solid fa-grip',
    routerLink: ['/'],
  },
  users: {
    name: 'Users',
    routerLink: ['/users'],
    icon: 'fa-solid fa-users',
    allowedRoles: [
      UserRolesEnum.ROLE_SUPER_ADMIN,
      UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
      UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
    ],
  },
  organizations: {
    name: 'Organizations',
    routerLink: ['/org'],
    icon: 'fa-regular fa-building',
    allowedRoles: [
      UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
      UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
    ],
  },
  agencies: {
    name: 'Agencies',
    routerLink: ['/agencies'],
    icon: 'fa-solid fa-headset',
    allowedRoles: [
      UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
      UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
    ],
  },
  services: {
    name: 'Services',
    routerLink: ['/services'],
    icon: 'fa-regular fa-rectangle-list',
    allowedRoles: [UserRolesEnum.ROLE_IREMBOGOV_ADMIN],
  },
  reporting: {
    name: 'Reporting',
    routerLink: ['/reporting'],
    icon: 'fa-regular fa-chart-bar',
    allowedRoles: [],
  },
  citizensPortal: {
    name: 'Citizens Portal',
    routerLink: ['/citizens'],
    icon: 'fa-solid fa-people-group',
    allowedRoles: [],
  },
  settings: {
    name: 'Settings',
    routerLink: ['/settings'],
    icon: 'fa-solid fa-gear',
    allowedRoles: [],
  },

  agents: {
    name: 'Agents',
    routerLink: ['/agents'],
    icon: 'fa-solid fa-users-viewfinder',
    allowedRoles: [],
  },

  translations: {
    name: 'Translations',
    routerLink: ['/translations'],
    icon: 'fa-solid fa-language',
    allowedRoles: [
      UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
      UserRolesEnum.ROLE_CONTENT_MANAGER,
    ],
  },
};
