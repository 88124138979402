<ng-container *ngIf="isVerifyingToken">
  <div
    class="w-99 d-flex align-items-center justify-content-center text-center h-100">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isVerifyingToken">
  <ng-template [ngIf]="resetRequestStatus === 'tokenRequest'">
    <irembogov-password-reset
      [title]="resetPasswordTitle | translate"
      [description]="resetPasswordDescription | translate"
      [buttonActionLabel]="resetPasswordButtonLabel | translate"
      (formSubmit)="onEmailFormSubmit($event)"
      [isLoading]="isSubmitting"
      [showErrorTitle]="showErrorTitle"
      [authorizationBaseUrl]="authorizationServiceBaseUrl"
      [authorizationClientId]="authorizationClientId"
      [showBackToLogin]="showBackToLogin"
      [accountNotFoundErrorTitle]="accountNotFoundErrorTitle | translate"
      [accountNotFoundErrorMessage]="accountNotFoundErrorMessage | translate"
      [accountNotActiveErrorTitle]="accountNotActiveErrorTitle | translate"
      [accountNotActiveErrorMessage]="accountNotActiveErrorMessage | translate"
      (goBack)="onGoToLogin()">
    </irembogov-password-reset>
  </ng-template>

  <ng-template [ngIf]="resetRequestStatus === 'tokenRequestSent'">
    <irembogov-token-sent
      [recipient]="recipient"
      (goBack)="onGoToLogin()"
      [showBackToLogin]="showBackToLogin"
      (resend)="onEmailFormSubmit(recipient!)"
      [resendLinkText]="resendLinkLabel | translate"
      [noReceiveQuestion]="
        noReceiveQuestion | translate
      "></irembogov-token-sent>
  </ng-template>

  <ng-template [ngIf]="resetRequestStatus === 'passwordReset'">
    <irembogov-activation-activate-account
      [title]="createPasswordTitle | translate"
      [description]="createPasswordDescription | translate"
      [buttonActionLabel]="createPasswordBtnActionLabel | translate"
      [showTermsCheckbox]="showTermsCheckbox"
      [isLoading]="isSubmitting"
      (formSubmit)="onPasswordFormSubmit($event)"
      [showBackToLogin]="showBackToLogin"
      [showPasswordStrengthMeter]="showPasswordStrengthMeter"
      [regExpErrorMsg]="resetPasswordRegexErrorMessage | translate"
      [passwordMatchErrorMsg]="resetPasswordMatchErrorMessage | translate"
      (goBack)="onGoToLogin()"
      (resendClicked)="
        resetToRequestNewToken()
      "></irembogov-activation-activate-account>
  </ng-template>

  <ng-template [ngIf]="resetRequestStatus === 'success'">
    <irembogov-auth-action-successful
      [title]="resetPasswordSuccessTitle | translate"
      [description]="resetPasswordSuccessDescription | translate"
      [showResendButton]="false"
      [buttonActionLabel]="resetPasswordSuccessButtonActionLabel"
      (continue)="onGoToLogin()"></irembogov-auth-action-successful>
  </ng-template>

  <ng-template [ngIf]="resetRequestStatus === 'failure'">
    <irembogov-activation-link-expired
      [title]="failureTitle"
      [description]="failureMessage"
      (resendLinkClick)="resetToRequestNewToken()"
      [showNavigation]="showBackToLogin"
      [showResendLink]="showResendLink"
      [resendLinkType]="resendLinkType"
      [resendLinkText]="resendLinkLabel | translate"
      (goBack)="onGoToLogin()"></irembogov-activation-link-expired>
  </ng-template>
</ng-container>
