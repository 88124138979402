/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  ApproveUserRequest,
  IremboClientUsersData,
  IremboClientUsersPostData,
} from './../models/users/irembo-user-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IHttpPagedResponse } from '@irembo-andela/irembogov3-common';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  constructor(private http: HttpClient) {}

  getChangeRequest(
    roleType: string,
    queryStr: string
  ): Observable<IHttpPagedResponse<IremboClientUsersData>> {
    const headers: HttpHeaders = new HttpHeaders();
    let endpoint;
    if (roleType === 'ROLE_SERVICE_MANAGER') {
      endpoint = 'service-manager-request';
    } else if (roleType === 'ROLE_QA_REVIEWER') {
      endpoint = 'qa-reviewer-request';
    }
    return this.http.get<IHttpPagedResponse<IremboClientUsersData>>(
      `${environment.apiGatewayBaseUrl}/admin/v1/${endpoint}?${queryStr}`,
      { headers }
    );
  }

  getUsers(
    roleName: string,
    sortBy: string = 'createdOn',
    sortDirection: 'ASC' | 'DESC' = 'DESC',
    page: number = 0,
    size = 10
  ): Observable<any> {
    const headerParams: Record<string, string | string[]> = {
      sortBy,
      sortDirection,
    };
    const headers: HttpHeaders = new HttpHeaders(headerParams);

    return this.http.get<IHttpPagedResponse<IremboClientUsersData>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/users/roles/${roleName}?page=${page}&size=${size}`,
      { headers }
    );
  }

  createServiceManagerRequest(createUserRequest: any): Observable<any> {
    const formData = new FormData();

    Object.keys(createUserRequest).forEach(key => {
      const data: string | Blob | undefined =
        createUserRequest[key as keyof IremboClientUsersPostData];
      if (data === undefined) return;
      formData.append(key, data);
    });

    const headers = new HttpHeaders({});
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/admin/v1/service-manager-request`,
      formData,
      {
        headers,
      }
    );
  }

  initiateRequestToCreateQAReviewer(createUserRequest: any) {
    const headers = new HttpHeaders({});
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/admin/v1/qa-reviewer-request`,
      createUserRequest,
      {
        headers,
      }
    );
  }

  approveOrRejectClientManagerRequest(
    request: ApproveUserRequest,
    roleType: string
  ): Observable<any> {
    if (roleType === 'ROLE_SERVICE_MANAGER') {
      return this.http.patch(
        `${environment.apiGatewayBaseUrl}/admin/v1/service-manager-request/take-action`,
        request
      );
    } else {
      return this.http.patch(
        `${environment.apiGatewayBaseUrl}/admin/v1/qa-reviewer-request/take-action`,
        request
      );
    }
  }
}
