export enum UserRolesEnum {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_IREMBOGOV_ADMIN = 'ROLE_IREMBOGOV_ADMIN',
  ROLE_CONTENT_MANAGER = 'ROLE_CONTENT_MANAGER',
  ROLE_PARTNERSHIP_MANAGER = 'ROLE_PARTNERSHIP_MANAGER',
  ROLE_INTEGRATION_MANAGER = 'ROLE_INTEGRATION_MANAGER',
  ROLE_SERVICE_MANAGER = 'ROLE_SERVICE_MANAGER',
  ROLE_QA_REVIEWER = 'ROLE_QA_REVIEWER',
  ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',
  ROLE_OFFICER_ADMIN = 'ROLE_OFFICER_ADMIN',
  ROLE_ORGANIZATION_SUPPORT = 'ROLE_ORGANIZATION_SUPPORT',
  ROLE_ORGANIZATION_EXECUTIVE = 'ROLE_ORGANIZATION_EXECUTIVE',
  ROLE_OFFICER = 'ROLE_OFFICER',
  ROLE_OFFICER_MANAGER = 'ROLE_OFFICER_MANAGER',
  ROLE_INTEGRATION_OPS = 'ROLE_INTEGRATION_OPS',
  ROLE_GOVERNMENT_EXEC = 'ROLE_GOVERNMENT_EXEC',
  ROLE_CITIZEN = 'ROLE_CITIZEN',
  ROLE_AGENT = 'ROLE_AGENT',
  ROLE_SERVICE_DISTRIBUTION_MANAGER = 'ROLE_SERVICE_DISTRIBUTION_MANAGER',
  ROLE_AGENCY_ADMIN = 'ROLE_AGENCY_ADMIN',
  _UNKNOWN_ROLE_ = 'UNKNOWN_ROLE',
}

export interface IUserRolesEnumValuePair {
  label: string;
  key: UserRolesEnum;
}

export const adminPortalAllowedRoles: UserRolesEnum[] = [
  UserRolesEnum.ROLE_SUPER_ADMIN,
  UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
  UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
  UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
  UserRolesEnum.ROLE_CONTENT_MANAGER,
];
