import { PendingChangeRequestEnums } from '../config/manage-organizations-change-requests.config';
import { EChangeRequestStatus } from '../models/change-request-status.enum';
import { ChangeRequestTypeEnum } from '../models/change-request-type.enum.model';

export interface IChangeRequestEnumToLabelAndClassPair<T> {
  key: T;
  label: string;
  class:
    | 'bg-danger'
    | 'bg-success'
    | 'bg-warning'
    | 'bg-info'
    | 'bg-primary'
    | 'bg-secondary'
    | 'bg-muted'
    | 'bg-none';
  icon: string;
}

export const ChangeRequestStatusEnumToLabelKeyPair = (
  key: EChangeRequestStatus | undefined
): IChangeRequestEnumToLabelAndClassPair<EChangeRequestStatus> => {
  switch (key) {
    case EChangeRequestStatus.APPROVED:
      return {
        label: 'Approved',
        class: 'bg-success',
        icon: 'fa-regular fa-circle-check',
        key,
      };
    case EChangeRequestStatus.CREATED:
      return {
        label: 'Pending',
        class: 'bg-warning',
        icon: 'fa-regular fa-hourglass-half',
        key,
      };
    case EChangeRequestStatus.REJECTED:
      return {
        label: 'Rejected',
        class: 'bg-danger',
        icon: 'fa-regular fa-circle-xmark',
        key,
      };
    default:
      return {
        label: 'unknown',
        class: 'bg-warning',
        icon: '',
        key: EChangeRequestStatus._UNKOWN_,
      };
  }
};

/**
 *
 * @deprecated
 */
export const ChangeRequestStatusEnumToLabelKeyPairDeprecated = (
  key: ChangeRequestTypeEnum | undefined
): IChangeRequestEnumToLabelAndClassPair<ChangeRequestTypeEnum> => {
  switch (key) {
    case ChangeRequestTypeEnum.APPROVED:
      return {
        label: 'Approved',
        class: 'bg-success',
        icon: 'fa-regular fa-circle-check',
        key,
      };
    case ChangeRequestTypeEnum.REJECT_DEACTIVATION:
      return {
        label: 'Deactivation Rejected',
        class: 'bg-success',
        icon: 'fa-regular fa-circle-check',
        key,
      };
    case ChangeRequestTypeEnum.REACTIVATED:
      return {
        label: 'Reactivated',
        class: 'bg-success',
        icon: 'fa-regular fa-circle-check',
        key,
      };
    case ChangeRequestTypeEnum.DEACTIVATED:
      return {
        label: 'Deactivated',
        class: 'bg-danger',
        icon: 'fa-regular fa-circle-xmark',
        key,
      };
    case ChangeRequestTypeEnum.REJECT_REACTIVATION:
      return {
        label: 'Reactivation Rejected',
        class: 'bg-danger',
        icon: 'fa-regular fa-circle-xmark',
        key,
      };
    case ChangeRequestTypeEnum.PENDING_APPROVAL:
      return {
        label: 'Pending Approval',
        class: 'bg-warning',
        icon: 'fa-regular fa-hourglass-half',
        key,
      };
    case ChangeRequestTypeEnum.PENDING_DEACTIVATION:
      return {
        label: 'Pending Deactivation',
        class: 'bg-warning',
        icon: 'fa-regular fa-hourglass-half',
        key,
      };
    case ChangeRequestTypeEnum.PENDING_REACTIVATION:
      return {
        label: 'Pending Reactivation',
        class: 'bg-warning',
        icon: 'fa-regular fa-hourglass-half',
        key,
      };
    case ChangeRequestTypeEnum.REJECTED:
      return {
        label: 'Rejected',
        class: 'bg-danger',
        icon: 'fa-regular fa-circle-xmark',
        key,
      };
    default:
      return {
        label: 'unknown',
        class: 'bg-warning',
        icon: '',
        key: ChangeRequestTypeEnum._UNKOWN_,
      };
  }
};

/**
 *
 * @returns @deprecated
 */
export const GetNonPendingChangeRequestsEnums = (): ChangeRequestTypeEnum[] => {
  const nonPendingEnums: ChangeRequestTypeEnum[] = [];
  Object.keys(ChangeRequestTypeEnum).forEach((key: string) => {
    if (
      Number.isNaN(+key) &&
      key !== ChangeRequestTypeEnum._UNKOWN_ &&
      !CheckIfStatusIsPendingType(key as ChangeRequestTypeEnum)
    ) {
      nonPendingEnums.push(key as ChangeRequestTypeEnum);
    }
  });

  return nonPendingEnums;
};

/**
 *
 * @deprecated
 */
export const CheckIfStatusIsPendingType = (
  enumStatus: ChangeRequestTypeEnum
): boolean => {
  return PendingChangeRequestEnums.indexOf(enumStatus) > -1;
};
