<div class="create-agency">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="mt-2 invite-user-form">
              <div #errorMessageElement>
                <irembogov-irembo-section-message
                  *ngIf="showFormError"
                  [type]="formAlertContent.type"
                  [title]="formAlertContent.title"
                  [message]="
                    formAlertContent.message
                  "></irembogov-irembo-section-message>
              </div>
              <div class="">
                <form [formGroup]="addNewServiceManager">
                  <div class="form-group">
                    <label for="email" class="form-label required"
                      >User Email</label
                    >
                    <div class="input-group mb-3">
                      <input
                        formControlName="email"
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="User Email"
                        #email />
                      <div
                        class="form-error w-100 small text-danger"
                        *ngIf="
                          addNewServiceManager.controls['email'].touched &&
                          !addNewServiceManager.controls['email'].valid
                        ">
                        User email is required.
                      </div>
                    </div>
                  </div>

                  <ng-template [ngIf]="!isAssigning">
                    <div class="form-group">
                      <label for="firstName" class="form-label required">
                        First Name
                      </label>
                      <div class="input-group mb-3">
                        <input
                          formControlName="firstName"
                          type="text"
                          class="form-control"
                          id="firstName"
                          placeholder="User First Name"
                          #firstName />
                        <div
                          class="form-error w-100 small text-danger"
                          *ngIf="
                            addNewServiceManager.controls['firstName']
                              .touched &&
                            addNewServiceManager.controls['firstName'].errors &&
                            addNewServiceManager.controls['firstName'].errors[
                              'required'
                            ]
                          ">
                          First name is required.
                        </div>
                        <div
                          class="form-error w-100 small text-danger"
                          *ngIf="
                            addNewServiceManager.controls['firstName']
                              .touched &&
                            addNewServiceManager.controls['firstName'].errors &&
                            addNewServiceManager.controls['firstName'].errors[
                              'pattern'
                            ]
                          ">
                          The name provided is invalid
                        </div>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for="lastName" class="form-label required">
                        Last Name
                      </label>
                      <div class="input-group mb-3">
                        <input
                          formControlName="lastName"
                          type="text"
                          class="form-control"
                          id="lastName"
                          placeholder="User Last Name"
                          #lastName />
                        <div
                          class="form-error w-100 small text-danger"
                          *ngIf="
                            addNewServiceManager.controls['lastName'].touched &&
                            addNewServiceManager.controls['lastName'].errors &&
                            addNewServiceManager.controls['lastName'].errors[
                              'required'
                            ]
                          ">
                          Last name is required.
                        </div>
                        <div
                          class="form-error w-100 small text-danger"
                          *ngIf="
                            addNewServiceManager.controls['lastName'].touched &&
                            addNewServiceManager.controls['lastName'].errors &&
                            addNewServiceManager.controls['lastName'].errors[
                              'pattern'
                            ]
                          ">
                          The name provided is invalid
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <div class="form-group">
                    <label class="row supporting-document form-label required">
                      Supporting Document
                    </label>
                    <div class="button-upload">
                      <irembogov-file-upload-input
                        id="file-upload-input"
                        placeholder="Upload Service Manager Certificate"
                        labelDesc="Upload Service Manager Certificate"
                        inputId="certificateDocument"
                        [displayAsButton]="true"
                        formControlName="certificateDocument"
                        [allowedFileTypes]="allowedFileTypes"
                        (change)="
                          updateDocumentCertificateValue($event)
                        "></irembogov-file-upload-input>
                    </div>
                    <div
                      class="form-error w-100 small text-danger"
                      *ngIf="
                        addNewServiceManager.controls['certificateDocument']
                          .touched &&
                        !addNewServiceManager.controls['certificateDocument']
                          .valid
                      ">
                      Business certificate is required.
                    </div>
                  </div>

                  <div class="text-end pt-4">
                    <button
                      [disabled]="isLoading"
                      type="button"
                      class="btn btn-transparent text-muted mx-3"
                      (click)="cancelForm()">
                      Cancel
                    </button>
                    <button
                      [disabled]="isLoading"
                      class="btn btn-primary mt-1 mx-auto"
                      type="button"
                      (click)="onCreateServiceManager()">
                      {{ isAssigning ? 'Assign' : 'Create' }}
                      <div
                        *ngIf="isLoading"
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"></div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
