import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyjson',
})
export class PrettyjsonPipe implements PipeTransform {
  transform(
    value: any,
    options: { escapeHtmlBracket: boolean } | undefined = undefined
  ): any {
    let outputData: string = JSON.stringify(value, null, 2);
    if (options?.escapeHtmlBracket) {
      outputData = outputData.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    }

    return outputData.replace(/ /g, '&nbsp;').replaceAll('\\n', '<br/>');
  }
}
