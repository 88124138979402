<div class="irembogov-list-users">
  <irembogov-button-group
    [itemList]="roleFilterButtons"
    [selectedItem]="(_filterObject | async)?.role?.toString()"
    (itemChange)="updateFilter({ role: $event })">
  </irembogov-button-group>

  <irembogov-custom-table-wrapper
    [isSeachVisible]="false"
    [isSortVisible]="true"
    [sortFieldOptions]="sortOptions"
    [loading]="isLoadingUserList"
    [paginationVisible]="true"
    [totalItems]="collectionSize"
    [itemsPerPage]="(_filterObject | async)?.size || 10"
    [currentPage]="(_filterObject | async)?.page || 0"
    (paginationChange)="
      updateFilter({ page: $event?.pageNumber - 1, size: $event.pageSize })
    "
    (sortChange)="
      updateFilter({
        sortBy: $event.sortBy,
        sortDirection: $event.sortDirection
      })
    ">
    <div
      rightHeader
      class="d-flex justify-content-between"
      style="border-radius: 5px">
      <button
        class="btn btn-primary btn-sm"
        type="button"
        (click)="updateSidePaneToggleState(true, 'CREATE_USER')">
        + Invite New User
      </button>
    </div>
    <table
      body
      class="table table-hover"
      aria-label="Irembo Users Table"
      class="table table-striped">
      <thead>
        <tr>
          <th scope="col">First Name</th>
          <th scope="col">Last Name</th>
          <th scope="col">Email</th>
          <th scope="col">Date added</th>
          <th scope="col">&nbsp;</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody class="text-centre rounded bg-white">
        <tr *ngFor="let user of usersList?.data?.content; let index = index">
          <td>
            {{ user.firstName }}
          </td>
          <td>
            {{ user.lastName }}
          </td>
          <td>{{ user.email }}</td>

          <td>{{ (user.createdDate | date) || 'N/A' }}</td>
          <td></td>
          <td>
            <button
              class="btn btn-sm btn-outline-gray border"
              (click)="setUserForViewPane(user)">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </irembogov-custom-table-wrapper>

  <irembogov-offcanvas
    *ngIf="roleTab === 'ROLE_SERVICE_MANAGER'"
    title="Invite New Service Manager"
    description="Invite new user to join the platform"
    [paneOpen]="isSidePaneOpen && sidePaneViewType === 'CREATE_USER'"
    (OnPaneToggle)="updateSidePaneToggleState($event, 'CREATE_USER')">
    <ng-template>
      <irembogov-client-manager-invite-service-manager
        (closeForm)="close($event)"
        (newServiceManagerFormSubmitted)="
          onNewUserAdded($event)
        "></irembogov-client-manager-invite-service-manager>
    </ng-template>
  </irembogov-offcanvas>

  <irembogov-offcanvas
    *ngIf="roleTab === 'ROLE_GOVERNMENT_EXEC'"
    title="Invite New GOVERNMENT EXECUTIVE"
    description="Invite new user to join the platform"
    [paneOpen]="isSidePaneOpen && sidePaneViewType === 'CREATE_USER'"
    (OnPaneToggle)="updateSidePaneToggleState($event, 'CREATE_USER')">
    <irembogov-client-manager-inivite-gov-exec></irembogov-client-manager-inivite-gov-exec>
  </irembogov-offcanvas>

  <irembogov-offcanvas
    *ngIf="roleTab === 'ROLE_QA_REVIEWER'"
    title="Invite New QA  Reviewer"
    description="Invite new user to join the platform"
    [paneOpen]="isSidePaneOpen && sidePaneViewType === 'CREATE_USER'"
    (OnPaneToggle)="updateSidePaneToggleState($event, 'CREATE_USER')">
    <irembogov-client-manager-inivite-qa-reviewer
      (newQAFormSubmitted)="onNewUserAdded($event)"
      (closeForm)="close($event)">
    </irembogov-client-manager-inivite-qa-reviewer>
  </irembogov-offcanvas>

  <irembogov-offcanvas
    title="User Info"
    [paneOpen]="isSidePaneOpen && sidePaneViewType === 'VIEW_USER'"
    (OnPaneToggle)="userInfoViewClosed()"
    #viewUserPane>
    <ng-template>
      <irembogov-user-basic-info
        [userData]="userForViewPane"
        (userUpdated)="userInfoViewClosed()">
      </irembogov-user-basic-info>
    </ng-template>
  </irembogov-offcanvas>
</div>
