<nav
  class="navbar navbar-expand-lg navbar-dark irembo-main-navbar align-items-stretch">
  <div
    class="container{{ navbarWide ? '-fluid' : '' }} px-0 align-items-stretch">
    <a class="navbar-brand ms-4 me-5 d-flex align-items-center">
      <img height="20" src="assets/images/logo-white.svg" alt="" />
    </a>
    <button
      class="navbar-toggler me-2"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#irembo-navbar-1"
      aria-controls="irembo-navbar-1"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="showActiveRole" class="navbar-active-role">
      <p>{{ activeRole }}</p>
    </div>

    <ng-content select="[orgSelector]"> </ng-content>

    <div
      class="collapse navbar-collapse align-items-stretch"
      id="irembo-navbar-1">
      <ul class="navbar-nav mb-lg-0 nav-main-links">
        <li
          class="nav-item m-0 px-0 d-flex align-items-stretch justify-content-center"
          *ngFor="let item of navbarItems">
          <a
            class="toplink nav-link m-0 px-3 d-flex align-items-center"
            routerLink="{{ item.routerLink }}"
            [queryParams]="getQueryParams(item.queryParams)"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{
              exact: getNavItemExactRouteCheck(item)
            }">
            <i class="{{ item.icon }} me-2">{{ item.iconContent }}</i>
            {{ item.name | translate }}
          </a>
        </li>
      </ul>

      <div class="collapse navbar-collapse align-items-stretch">
        <ng-content select="[customSection]"> </ng-content>
      </div>

      <div
        *ngIf="showNotification"
        class="nav-notifications nav-item ms-auto px-4 d-flex align-items-center">
        <ul class="navbar-nav mb-lg-0 nav-main-links">
          <li
            class="nav-item m-0 px-0 d-flex align-items-stretch justify-content-center"
            *ngFor="let item of navbarActions"
            (click)="actionClick.emit(item.action)">
            <a class="top-action nav-link m-0 px-0 d-flex align-items-center">
              <i class="{{ item.icon }} me-2"></i>
              {{ item.name | translate }}
            </a>
          </li>
        </ul>

        <i
          class="fa-regular fa-bell me-3 position-relative"
          *ngIf="notifications !== undefined">
          <span
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-light">
            <small> {{ notifications?.length }} </small>
          </span>
        </i>
      </div>

      <ng-content select="[profile]"> </ng-content>
    </div>
  </div>
</nav>
