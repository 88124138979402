import { ALLOWED_IMAGE_FILE_TYPES } from './../../core/config/file-upload-file-types.config';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription, finalize } from 'rxjs';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../core/services/users.service';
import { AuthService } from '../../core/services/auth.service';
import IIremboUser, {
  _BLANK_PROFILE,
} from '../../core/models/users/irembo-user-model';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'irembogov-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserProfileComponent implements OnInit, OnDestroy {
  @Input() title = 'Profile Page';
  userProfile: IIremboUser = _BLANK_PROFILE;
  _userProfile!: Subscription;
  isLoggedIn!: boolean;
  isUpdating!: boolean;
  sidePaneOpen = false;
  viewUserPaneOpen = false;
  showLetter = false;
  profileAvatar: string | undefined;
  avatarImageUrl = '../../../assets/img/image-user.png';
  allowFileTypes: string[] = ALLOWED_IMAGE_FILE_TYPES;
  userNameFirstLetter!: string;
  @Output() OnUpdateProfile: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private toastr: ToastService,
    private usersService: UsersService
  ) {}

  editUserForm: FormGroup = this.fb.group({
    profilePicture: [null, [Validators.required]],
  });

  async ngOnInit() {
    this._userProfile = this.authService.userProfile$.subscribe(
      (profile: IIremboUser) => {
        this.userProfile = profile;
      }
    );
    await this.authService.checkLoginProfile();
    this.userNameFirstLetter = this.userProfile?.firstName
      .slice(0, 1)
      .toUpperCase();
    if (this.userProfile?.profileImageUrl) {
      this.showLetter = true;
    } else {
      this.showLetter = false;
    }
  }

  cancelSidePane(state: boolean) {
    this.sidePaneOpen = state;
    this.editUserForm.reset();
  }

  toggleSidePane() {
    this.sidePaneOpen = !this.sidePaneOpen;
    this.profileAvatar = this.userProfile?.profileImageUrl
      ? this.userProfile.profileImageUrl
      : this.avatarImageUrl;
  }

  updateToggleState(openState: boolean) {
    this.sidePaneOpen = openState;
    this.editUserForm.reset();
  }

  updatePreview(e: any) {
    this.editUserForm.get('profilePicture')?.updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.profileAvatar = reader.result as string;
    };
    reader.readAsDataURL(this.editUserForm.get('profilePicture')?.value);
  }

  async onProfileUpdate() {
    if (this.editUserForm.get('profilePicture')?.value == null) {
      this.sidePaneOpen = true;
      const message = `You must select an image`;
      this.toastr.show({ body: message, type: 'error' });
      return;
    }
    const formData: FormData = new FormData();
    formData.append(
      'profilePicture',
      this.editUserForm.get('profilePicture')?.value
    );
    formData.append('clientId', environment.authClientId);
    formData.append('userType', 'OTHER');
    this.isUpdating = true;

    this.usersService
      .updateUserProfilePicture(formData)
      .pipe(finalize(() => (this.isUpdating = false)))
      .subscribe({
        next: response => {
          if (response.status === true) {
            this.authService.checkLoginProfile();
            this.sidePaneOpen = false;
            this.OnUpdateProfile.emit(
              this.editUserForm.get('profilePicture')?.value
            );
            const message = `Profile picture updated`;
            this.toastr.show({ body: message, type: 'success' });
          } else {
            this.sidePaneOpen = false;
            const message = `Error updating profile`;
            this.toastr.show({ body: message, type: 'error' });
          }
        },
        error: () => {
          this.sidePaneOpen = false;
          const message = `Error updating profile`;
          this.toastr.show({ body: message, type: 'warning' });
        },
      });
  }

  ngOnDestroy(): void {
    if (this._userProfile) this._userProfile.unsubscribe();
  }
}
