import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeRequestTypeEnum } from '../../../../core/models/change-request-type.enum.model';
import { IBaseApproveUserChangeRequestAndType } from '../../../../core/models/users/create-new-user-change-request.model';
import { IUserChangeRequest } from '../../../../core/models/users/user-change-request-response.model';
import {
  ChangeRequestStatusEnumToLabelKeyPairDeprecated,
  IChangeRequestEnumToLabelAndClassPair,
} from '../../../../core/utils/change-requests-common-utils';

@Component({
  selector: 'irembogov-user-change-request-badge',
  templateUrl: './user-change-request-badge.component.html',
})
export class UserChangeRequestBadgeComponent {
  @Input() changeRequest!: IUserChangeRequest;
  @Input() canApproveRequest = false;
  @Input() canCreateRequest = false;
  @Input() isLoadingProcess = false;
  @Input() isRejectingProcess = false;
  @Input() isApprovingProcess = false;
  @Input() isDeactivatingProcess = false;
  @Input() isActivatingProcess = false;

  @Output() OnReactivateClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() OnDeactivateClick: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output()
  OnApproveRequestClick: EventEmitter<IBaseApproveUserChangeRequestAndType> =
    new EventEmitter<IBaseApproveUserChangeRequestAndType>();
  @Output()
  OnRejectRequestClick: EventEmitter<IBaseApproveUserChangeRequestAndType> =
    new EventEmitter<IBaseApproveUserChangeRequestAndType>();
  @Output() OnInitReactivateRequestClick: EventEmitter<ChangeRequestTypeEnum> =
    new EventEmitter<ChangeRequestTypeEnum>();
  @Output() OnInitDeactivateRequestClick: EventEmitter<ChangeRequestTypeEnum> =
    new EventEmitter<ChangeRequestTypeEnum>();

  getUserStatusLabel(
    status: ChangeRequestTypeEnum | undefined
  ): IChangeRequestEnumToLabelAndClassPair<ChangeRequestTypeEnum> {
    return ChangeRequestStatusEnumToLabelKeyPairDeprecated(status);
  }

  // APPROVED
  checkIfApproved(): boolean {
    if (!this.changeRequest) return false;
    return this.changeRequest.requestStatus === ChangeRequestTypeEnum.APPROVED;
  }

  checkIfReactivated(): boolean {
    if (!this.changeRequest) return false;
    return (
      this.changeRequest.requestStatus === ChangeRequestTypeEnum.REACTIVATED
    );
  }

  initiateChangeRequestDeactivated() {
    this.OnInitDeactivateRequestClick.emit(ChangeRequestTypeEnum.DEACTIVATED);
  }

  // DEACTIVATED
  checkIfDeactivated(): boolean {
    if (!this.changeRequest) return false;
    return (
      this.changeRequest.requestStatus === ChangeRequestTypeEnum.DEACTIVATED
    );
  }

  initiateChangeRequestReactivated() {
    this.OnInitReactivateRequestClick.emit(ChangeRequestTypeEnum.REACTIVATED);
  }

  // PENDING APPROVAL
  checkIfPendingApproval(): boolean {
    if (!this.changeRequest) return false;
    return (
      this.changeRequest.requestStatus ===
      ChangeRequestTypeEnum.PENDING_APPROVAL
    );
  }

  // APPROVE CREATE CHANGE REQUEST
  approveChangeRequest() {
    const approveType: IBaseApproveUserChangeRequestAndType = {
      requestStatus: ChangeRequestTypeEnum.APPROVED,
      requestType: 'CREATE',
    };
    this.OnApproveRequestClick.emit(approveType);
  }

  // REJECT CREATE CHANGE REQUEST
  rejectChangeRequest() {
    const approveType: IBaseApproveUserChangeRequestAndType = {
      requestStatus: ChangeRequestTypeEnum.REJECTED,
      requestType: 'CREATE',
    };
    this.OnRejectRequestClick.emit(approveType);
  }

  // PENDING DEACTIVATION
  checkIfPendingDeactivation(): boolean {
    if (!this.changeRequest) return false;
    return (
      this.changeRequest.requestStatus ===
      ChangeRequestTypeEnum.PENDING_DEACTIVATION
    );
  }

  // APPROVE DEACTIVATION
  deactivateChangeRequest() {
    const approveType: IBaseApproveUserChangeRequestAndType = {
      requestStatus: ChangeRequestTypeEnum.APPROVED,
      requestType: 'DEACTIVATE',
    };
    this.OnApproveRequestClick.emit(approveType);
  }

  // REJECT DEACTIVATION
  rejectDeactivationChangeRequest() {
    const approveType: IBaseApproveUserChangeRequestAndType = {
      requestStatus: ChangeRequestTypeEnum.REJECTED,
      requestType: 'DEACTIVATE',
    };
    this.OnRejectRequestClick.emit(approveType);
  }

  // PENDING REACTIVATION
  checkIfPendingReactivation(): boolean {
    if (!this.changeRequest) return false;
    return (
      this.changeRequest.requestStatus ===
      ChangeRequestTypeEnum.PENDING_REACTIVATION
    );
  }

  // APPROVE REACTIVATION
  reactivateChangeRequest() {
    const approveType: IBaseApproveUserChangeRequestAndType = {
      requestStatus: ChangeRequestTypeEnum.APPROVED,
      requestType: 'REACTIVATE',
    };
    this.OnApproveRequestClick.emit(approveType);
  }

  // REJECT REACTIVATION
  rejectReactivationChangeRequest() {
    const approveType: IBaseApproveUserChangeRequestAndType = {
      requestStatus: ChangeRequestTypeEnum.REJECTED,
      requestType: 'REACTIVATE',
    };
    this.OnRejectRequestClick.emit(approveType);
  }

  // Rejected
  private checkIfRejected(): boolean {
    if (!this.changeRequest) return false;
    return this.changeRequest.requestStatus === ChangeRequestTypeEnum.REJECTED;
  }

  // Rejected
  private checkIfDejectDeactivation(): boolean {
    if (!this.changeRequest) return false;
    return (
      this.changeRequest.requestStatus ===
      ChangeRequestTypeEnum.REJECT_DEACTIVATION
    );
  }

  // Rejected
  private checkIfRejectDeactivation(): boolean {
    if (!this.changeRequest) return false;
    return (
      this.changeRequest.requestStatus ===
      ChangeRequestTypeEnum.REJECT_REACTIVATION
    );
  }

  checkIfOtherStates(): boolean {
    return (
      this.checkIfRejected() ||
      this.checkIfDejectDeactivation() ||
      this.checkIfRejectDeactivation()
    );
  }
}
