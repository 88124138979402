import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UserRolesEnum } from '../../../../core/models/users/user-roles.model';
import { RoleEnumToLabelUtil } from '../../../../core/utils/authUtils';
import { IUserChangeRequest } from '../../../../core/models/users/user-change-request-response.model';

@Component({
  selector: 'irembogov-change-request-basic-info',
  templateUrl: './user-change-request-basic-info.component.html',
  styleUrls: ['./user-change-request-basic-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserChangeRequestBasicInfoComponent {
  @Input() userChangeRequest!: IUserChangeRequest;

  getRoleLabel(role: UserRolesEnum): string {
    return RoleEnumToLabelUtil(role);
  }
}
