<irembogov-main-navigation-bar></irembogov-main-navigation-bar>
<div class="container-fluid bg-white py-3">
  <router-outlet></router-outlet>
</div>

<ng-template #logoutWarningContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Pending incomplete process!
    </h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>
      Are you sure you want to cancel this process? If yes you might lose all
      the information you have provided, consider saving your draft before
      leaving.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('cancel')">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('logout')">
      Logout
    </button>
  </div>
</ng-template>
