/**
 * @deprecated
 */
export enum ChangeRequestTypeEnum {
  CREATED = 'CREATED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING_DEACTIVATION = 'PENDING_DEACTIVATION',
  DEACTIVATED = 'DEACTIVATED',
  REJECT_DEACTIVATION = 'REJECT_DEACTIVATION',
  PENDING_REACTIVATION = 'PENDING_REACTIVATION',
  REACTIVATED = 'REACTIVATED',
  REJECT_REACTIVATION = 'REJECT_REACTIVATION',
  _UNKOWN_ = '_UNKOWN_',
}

export enum EChangeRequestType {
  CREATE = 'CREATE',
  DEACTIVATE = 'DEACTIVATE',
  REACTIVATE = 'REACTIVATE',
  _UNKOWN_ = '_UNKOWN_',
}
