import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainNavBarLinkItems } from '../../core/config/nav-bar-link-items.config';
import IIremboUser, {
  _BLANK_PROFILE,
} from '../../core/models/users/irembo-user-model';
import { INavbarLinkItem } from '../../core/models/navbar-link-item.model';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { RolesEnum } from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-main-navigation-bar',
  templateUrl: './main-navigation-bar.component.html',
})
export class MainNavigationBarComponent implements OnInit, OnDestroy {
  userProfile: IIremboUser = _BLANK_PROFILE;
  _userProfile!: Subscription;
  isLoggedIn!: boolean;

  navBarItems: INavbarLinkItem[] = [];

  constructor(private authService: AuthService, private router: Router) {}

  async ngOnInit() {
    this.isLoggedIn = await this.authService.isLoggedIn();
    this._userProfile = this.authService.userProfile$.subscribe(
      (profile: IIremboUser) => {
        this.userProfile = profile;
      }
    );
    this.authService.checkLoginProfile();
  }

  logoutUser() {
    this.authService.logOutUser();
  }

  getMainNavBarLinkItemsForRole(): INavbarLinkItem[] {
    const navbarItems: INavbarLinkItem[] = [];
    Object.keys(MainNavBarLinkItems).forEach((key: string) => {
      const item: INavbarLinkItem = MainNavBarLinkItems[key];
      if (!item.allowedRoles) {
        navbarItems.push(item);
        return;
      }

      if (!this.userProfile?.primaryRole) return;

      if (item.allowedRoles.indexOf(this.userProfile.primaryRole.key) > -1) {
        navbarItems.push(item);
        return;
      }

      return false;
    });

    return navbarItems;
  }

  profilePageRedirect(_: unknown) {
    this.router.navigate(['/profile']);
  }

  ngOnDestroy(): void {
    if (this._userProfile) this._userProfile.unsubscribe();
  }
}
