import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  IFilterAndPaginationFieldsBase,
  IIrembogovBasicLabelKeyPair,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import IIremboUser, {
  IremboClientUsersData,
} from '../../../../core/models/users/irembo-user-model';
import { UserRolesEnum } from '../../../../core/models/users/user-roles.model';
import { ExtractHttpErrorMessage } from '../../../../core/utils/http-error-message-extractor.util';
import {
  FilterFieldsToQueryParamsBuilder,
  IHttpPagedResponse,
} from '@irembo-andela/irembogov3-common';
import { ClientService } from '../../../../core/services/client.service';
import { UserCanApproveChangeRequestsForClientManagerRequests } from '../../../../core/config/manage-client-manager-route-action.config';

interface IFilterFields extends IFilterAndPaginationFieldsBase {
  role: UserRolesEnum | string | null;
  sort: string | null;
  sortDirection: 'ASC' | 'DESC';
}

type SidePaneViewType = 'CREATE_USER' | 'VIEW_USER';

const IremboGovAdminRequestList = [
  {
    label: 'Service Manager',
    role: 'Service Manager',
    roleName: 'ROLE_SERVICE_MANAGER',
  },
  { label: 'QA reviewer', role: 'QA reviewer', roleName: 'ROLE_QA_REVIEWER' },
];

@Component({
  selector: 'irembogov-list-irembogov-admin-change-request',
  templateUrl: './list-irembogov-admin-change-request.component.html',
})
export class ListIrembogovAdminChangeRequestComponent
  implements OnInit, OnDestroy
{
  userForViewPane!: IremboClientUsersData;
  viewChangeRequestPaneOpen = false;
  iIremboUser!: IIremboUser;
  isSidePaneOpen = false;
  isLoadingUserList = false;
  collectionSize = 0;
  usersData: IremboClientUsersData[] = [];
  changeRequest!: IremboClientUsersData;
  sidePaneViewType: SidePaneViewType;
  IsLoadingRequestsList = false;
  private unSubscriber$ = new Subject<void>();
  _filter: BehaviorSubject<IFilterFields>;

  roleFilterButtons = IremboGovAdminRequestList?.map(i => ({
    label: i.label,
    key: i.role?.toString() || '',
  }));

  sortFieldOptions: IIrembogovBasicLabelKeyPair<Record<string, string>>[] = [
    {
      label: 'Date Created, Descending',
      key: { sort: 'createdDate', sortDirection: 'DESC' },
    },
    {
      label: 'Date Created, Ascending',
      key: { sort: 'createdDate', sortDirection: 'ASC' },
    },
  ];

  constructor(
    private clientService: ClientService,
    private toastService: ToastService
  ) {
    this.sidePaneViewType = 'CREATE_USER';

    this._filter = new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 10,
      sort: 'createdDate',
      sortDirection: 'DESC',
      role: IremboGovAdminRequestList[0].role,
    });
  }

  ngOnInit(): void {
    this.subscribeToQueryObject();
  }

  ngOnDestroy(): void {
    this.unSubscriber$.next();
    this.unSubscriber$.complete();
  }

  subscribeToQueryObject() {
    this._filter
      .pipe(takeUntil(this.unSubscriber$))
      .subscribe((filter: IFilterFields) => {
        this.getRequests(filter);
      });
  }

  updateFilter(keyPair: Partial<IFilterFields>) {
    this._filter.next({
      ...this._filter.getValue(),
      ...keyPair,
    });
  }

  private getRequests(filters: IFilterFields): void {
    this.isLoadingUserList = true;
    const roleType = this.CovertClientManagerToRole(
      filters.role ? filters.role?.toString() : ''
    );

    const filterObj: Record<string, unknown> = { ...filters };
    delete filterObj?.['role'];
    const queryParams: string =
      FilterFieldsToQueryParamsBuilder<Record<string, unknown>>(filterObj);

    this.clientService
      .getChangeRequest(roleType, queryParams)
      .pipe(finalize(() => (this.isLoadingUserList = false)))
      .subscribe({
        next: (response: IHttpPagedResponse<IremboClientUsersData>) => {
          this.usersData = response.data.content;
          const totalPages =
            response?.data?.totalPages === 0 ? 1 : response?.data?.totalPages;
          const numberOfElements =
            response?.data?.numberOfElements === 0
              ? 10
              : response?.data?.numberOfElements;
          this.collectionSize = totalPages * numberOfElements;
        },
        error: err => {
          const errorMessage = ExtractHttpErrorMessage(
            err,
            'Failed to fetch change request.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  CovertClientManagerToRole(role: any) {
    if (role == 'QA reviewer') {
      return 'ROLE_QA_REVIEWER';
    } else {
      return 'ROLE_SERVICE_MANAGER';
    }
  }

  updatedViewChangeRequestPaneState($event: boolean) {
    this.viewChangeRequestPaneOpen = $event;
  }

  checkIfCanApproveRequests() {
    if (!(this.iIremboUser && this.iIremboUser.primaryRole)) return false;
    return (
      UserCanApproveChangeRequestsForClientManagerRequests.indexOf(
        this.iIremboUser.primaryRole.key
      ) > -1
    );
  }

  updateRequestList(): void {
    this.refreshList();
  }

  private refreshList() {
    this.updateFilter({
      page: 0,
      size: 10,
      sort: 'createdDate',
      sortDirection: 'DESC',
    });
  }

  openChangeRequestViewPane(changeRequest: IremboClientUsersData) {
    this.changeRequest = changeRequest;
    this.updatedViewChangeRequestPaneState(true);
  }
}
