/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IremboManagersData } from './../models/users/irembo-user-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, throwError } from 'rxjs';
import { ICreateAccountRequest } from '../models/users/create-user-request.model';
import { UserRolesEnum } from '../models/users/user-roles.model';
import { IDeactivateReactivateUserRequest } from '../models/users/deactivate-reactivate-user-request.model';
import { THeaderParams } from '../models/header-params.type';
import IIremboUser from '../models/users/irembo-user-model';
import { IUnassignRoleRequest } from '../models/users/unassign-role-request.model';
import { IHttpBaseResponse } from '../models/http-response-base.model';
import { ICreateIremboUserRequest } from '../models/users/create-irembo-user-request';
import { IHttpPagedResponse } from '@irembo-andela/irembogov3-common';
import { ICheckIfIremboUserExistsResponse } from '../models/users/http/check-if-irembo-user-exists-response';
import { IAssignRoleRequest } from '../models/users/assign-role-request.model';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getUserById(
    id: string,
    profilePicture: 'true' | 'false' = 'true'
  ): Observable<any> {
    const headerParams: THeaderParams = { userId: id, profilePicture };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/auth/v1/user-by-id`,
      { headers }
    );
  }

  getUserDetailsById(headerObbj: {
    userId: string;
    userType: string;
  }): Observable<any> {
    const headerParams: THeaderParams = { ...headerObbj };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/auth/v1/user/user-by-id`,
      { headers }
    );
  }

  getAnyUserDetailsById(headerObbj: {
    userId: string;
    userType: string;
  }): Observable<any> {
    const headerParams: THeaderParams = { ...headerObbj };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/auth/v1/user/any-user-by-id`,
      { headers }
    );
  }

  getUserDetailsByScopeIdAndRoles(headerObbj: {
    scopeId: string;
    scopeType: string;
    roles: string;
  }): Observable<any> {
    const headerParams: THeaderParams = { ...headerObbj };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/auth/v1/user-role/by-role-and-scope`,
      { headers }
    );
  }

  getChangeRequestForOrgAdmin(id: string): Observable<any> {
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/admin/v1/create-organization-requests/${id}`
    );
  }

  getUserProfilePictureById(id: string): Observable<any> {
    const headerParams: THeaderParams = { userId: id, userType: 'OTHER' };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/auth/v1/user/profile-picture`,
      { headers }
    );
  }

  updateUserProfilePicture(userChanges: FormData): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    return this.http.put<any>(
      `${environment.apiGatewayBaseUrl}/auth/v1/update-profile-picture`,
      userChanges,
      {
        headers,
      }
    );
  }

  getUsersByRoles_(
    role: string,
    page: number,
    size: number = 50,
    sort?: string
  ): Observable<any> {
    const headerParams: THeaderParams = { filter: role };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get<IHttpPagedResponse<IremboManagersData>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/users?page=${page}&size=${size}&sort=${sort}`,
      { headers }
    );
  }

  getUsersByRoles(
    roleName: string,
    sortBy: string = 'createdOn',
    sortDirection: 'ASC' | 'DESC' = 'DESC',
    page: number = 0,
    size = 10
  ): Observable<IHttpPagedResponse<IremboManagersData>> {
    const headerParams: Record<string, string | string[]> = {
      sortBy,
      sortDirection,
      roleNames: roleName,
    };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get<IHttpPagedResponse<IremboManagersData>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/users/roles?page=${page}&size=${size}`,
      { headers }
    );
  }

  getUsersByRolesforIremboGovAdmin(
    roleName: string,
    sortBy: string = 'createdOn',
    sortDirection: 'ASC' | 'DESC' = 'DESC',
    page: number = 0,
    size = 10
  ): Observable<IHttpPagedResponse<IremboManagersData>> {
    const headerParams: Record<string, string | string[]> = {
      sortBy,
      sortDirection,
    };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get<IHttpPagedResponse<IremboManagersData>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/users/roles/${roleName}?page=${page}&size=${size}`,
      { headers }
    );
  }

  getUsersBySearchHeaders(
    filterParams: string
  ): Observable<IHttpPagedResponse<IremboManagersData>> {
    const headerParams: THeaderParams = { filter: filterParams };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get<IHttpPagedResponse<IremboManagersData>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/users`,
      {
        headers,
      }
    );
  }

  getUsersBySearchParams(
    filterParams: string
  ): Observable<IHttpPagedResponse<IremboManagersData>> {
    return this.http.get<IHttpPagedResponse<IremboManagersData>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/users?${filterParams}`
    );
  }

  getClientUsers(
    role: string,
    page: number = 0,
    size: number = 50
  ): Observable<any> {
    const headerParams: THeaderParams = {
      clientId: environment.authClientId,
      filter: role,
    };
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/auth/v1/users?page=${page}&size=${size}`,
      {
        headers,
      }
    );
  }

  updateUser(userChanges: FormData): Observable<any> {
    return this.http.put(
      `${environment.apiGatewayBaseUrl}/auth/v1/update-user`,
      userChanges
    );
  }

  deactivateUser(username: string, roleName: UserRolesEnum): Observable<any> {
    const deactivateUserRequest: IDeactivateReactivateUserRequest = {
      username,
      roleName,
      clientId: environment.authClientId,
    };
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/auth/v1/deactivate/user`,
      deactivateUserRequest
    );
  }

  reactivateUser(username: string, roleName: UserRolesEnum): Observable<any> {
    const reactivateUserRequest: IDeactivateReactivateUserRequest = {
      username,
      roleName,
      clientId: environment.authClientId,
    };
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/auth/v1/reactivate/user`,
      reactivateUserRequest
    );
  }

  createIremboUser(
    createUserRequest: ICreateIremboUserRequest
  ): Observable<any> {
    const headers = new HttpHeaders({});
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/auth/v1/user`,
      createUserRequest,
      { headers }
    );
  }

  checkIfIremboUserExists(
    username: string,
    userType: 'OTHER' | 'CITIZEN' = 'OTHER',
    profilePicture: 'true' | 'false' = 'false'
  ): Observable<any> {
    const headers = new HttpHeaders({
      username,
      profilePicture,
      userType,
    });
    return this.http.get<ICheckIfIremboUserExistsResponse>(
      `${environment.apiGatewayBaseUrl}/auth/v1/user/check-user-exist`,
      { headers }
    );
  }

  createIremboAccount(
    createRequest: ICreateAccountRequest,
    role: UserRolesEnum
  ): Observable<any> {
    const roleEndPoint: string | undefined = this.getRoleCreateEndPoint(role);
    if (!roleEndPoint) {
      return throwError(() => new Error('Invalid role'));
    }
    const headers = new HttpHeaders({});
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/auth/v1/${roleEndPoint}`,
      createRequest,
      { headers }
    );
  }

  private getRoleCreateEndPoint(role: UserRolesEnum): string | undefined {
    switch (role) {
      case UserRolesEnum.ROLE_IREMBOGOV_ADMIN:
        return 'create-irembogov-admin';
      case UserRolesEnum.ROLE_PARTNERSHIP_MANAGER:
        return 'create-partnership-manager';
      case UserRolesEnum.ROLE_INTEGRATION_MANAGER:
        return 'create-integration-manager';
      case UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER:
        return 'create-agency-manager';
      default:
        return undefined;
    }
  }

  assignUserRole(
    request: IAssignRoleRequest
  ): Observable<IHttpBaseResponse<IIremboUser>> {
    const headers = new HttpHeaders({});
    return this.http.patch<IHttpBaseResponse<IIremboUser>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/user/assign-role`,
      request,
      { headers }
    );
  }

  unassignUserRole(
    request: IUnassignRoleRequest
  ): Observable<IHttpBaseResponse<IIremboUser>> {
    const headers = new HttpHeaders({});
    return this.http.patch<IHttpBaseResponse<IIremboUser>>(
      `${environment.apiGatewayBaseUrl}/auth/v1/user/unassign-role`,
      request,
      { headers }
    );
  }
}
