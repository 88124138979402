<ng-container *ngIf="checkIfCanApproveOrgChangeRequests()">
  <ng-container *ngIf="checkIfApprovedOrRejected()">
    <span>
      <button
        class="btn me-2 border border-secondary text-success"
        [disabled]="isApprovingLoading || isApprovingLoading"
        (click)="approveChangeRequest()">
        <i class="fa-regular fa-circle-check"></i>
        Approve
        <div
          *ngIf="isApprovingLoading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"></div>
      </button>
      <button
        class="btn text-danger me-2 border border-secondary"
        [disabled]="isRejectingLoading || isApprovingLoading"
        (click)="rejectChangeRequest()">
        <i class="fa-regular fa-times-circle"></i>
        Reject
        <div
          *ngIf="isRejectingLoading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"></div>
      </button>
    </span>
    <span *ngIf="!checkIfApprovedOrRejected()">
      <irembogov-change-request-status-badge
        [changeRequestStatus]="
          changeRequest.requestStatus
        "></irembogov-change-request-status-badge>
    </span>
  </ng-container>

  <ng-template #rejectClientManagerRequest let-modal>
    <div class="modal-header">
      <p class="modal-title" id="modal-basic-title">
        <i class="fa-regular fa-times-circle text-danger"></i> Reject The
        Creation of {{ changeRequest.firstName }} as {{ roleType }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="">
        <form #radioButtonTemplateForm="ngForm">
          <div class="mb-3">
            <label for="other-reason" class="form-label">Add Note</label>
            <textarea
              class="form-control"
              id="other-reason"
              rows="3"
              name="notes"
              required
              [(ngModel)]="notes"></textarea>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              (click)="modal.close({ action: 'cancel' })">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              [disabled]="!radioButtonTemplateForm.valid"
              (click)="
                modal.close({
                  action: 'reject',
                  reasons: radioButtonTemplateForm.value
                })
              ">
              Reject
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <ng-template #approveClientManagerChangeRequest let-modal>
    <div class="modal-header">
      <p class="modal-title" id="modal-basic-title">
        <i class="fa-regular fa-circle-check text-success"></i> Approve The
        Creation of {{ changeRequest.firstName }} as {{ roleType }}
      </p>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="">
        <form #approveTemplateForm="ngForm">
          <div class="mb-3">
            <label for="note" class="form-label">Add Note</label>
            <textarea
              class="form-control"
              id="note"
              rows="3"
              name="notes"
              required
              placeholder="Enter Note"
              [(ngModel)]="notes"></textarea>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-dark"
              (click)="modal.close({ action: 'cancel' })">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-success"
              [disabled]="!approveTemplateForm.valid"
              (click)="
                modal.close({
                  action: 'approve',
                  notes: approveTemplateForm.value
                })
              ">
              Approve
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</ng-container>
