import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { INavbarActionItem } from '../../models/irembogov-navbar-action-item.model';
import { RoleEnumToLabelUtil } from '../../../utils/utils/irembo-role-to-label.util';
import { UserRolesEnum } from '../../../utils/enums/user-roles.enum';

@Component({
  selector: 'irembogov-navbar-profile-section',
  templateUrl: './navbar-profile-section.component.html',
  styleUrls: ['./navbar-profile-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarProfileSectionComponent implements OnChanges {
  @Input() username: string | undefined;
  @Input() activeRole: string | undefined;
  @Input() roles: string[] | undefined;
  @Input() updatedProfile!: string | undefined;
  @Input() navItems: INavbarActionItem[] = [];
  @Input() dropDownCustomCss: string | undefined;
  @Input() agentNationalId: string | undefined;
  @Input() showUserDetails = true;
  @Input() showDropdownEmail = true;
  @Input() showDropdownRoles = true;
  @Input() showDropdownUserDetails = false;
  @Input() profileLinkText = 'View Profile';
  @Input() showProfileLink = true;

  @Output() logout = new EventEmitter<undefined>();
  @Output() profileClick = new EventEmitter<undefined>();
  @Output() roleChange = new EventEmitter<string>();
  @Output() navItemClick = new EventEmitter<string>();
  userNameFirstLetter!: string | undefined;
  otherRoles: { key: UserRolesEnum; label: string }[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['username'] && changes['username'].currentValue) {
      this.userNameFirstLetter = this.getFirstLetters(
        this.username as string
      )?.toUpperCase();
    }
  }

  private getFirstLetters(input: string): string {
    const chunks = input.split(' ').slice(0, 2);
    const firstLetters = chunks.map(chunk => chunk.charAt(0));
    return firstLetters.join('');
  }
}
