import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  bytesToMegabytes,
  removeTextAfterLastDot,
  truncateString,
} from '../../../core/utils/forms-utils';

@Component({
  selector: 'irembogov-file-upload-input',
  templateUrl: './file-upload-input.component.html',
  styleUrls: ['./file-upload-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadInputComponent),
      multi: true,
    },
  ],
})
export class FileUploadInputComponent implements ControlValueAccessor {
  @Input() labelDesc = 'Upload File';
  @Input() maxFileByteSize = 5000000;
  @Input() placeholder = 'Upload file';
  @Input() inputId = 'fileupload';
  @Input() displayAsButton = false;
  @Input() required = true;
  @Input() disabled = false;
  @Input() displaySelectedFileName = true;
  @Input() errorMessageAsHTML = 'File required <em>(.pdf, .doc, .docx)</em>';
  @Input()
  fileSizeErrorMessageAsHTML = `File exceeds the maximum required size of <em>${bytesToMegabytes(
    this.maxFileByteSize
  )}MBs</em>`;
  @Input() allowedFileTypes!: string[];

  file!: File;
  fileName = '';
  isFileSizeValid?: boolean;

  _onChange(event: any) {
    return;
  }

  _onTouch(event: any) {
    return;
  }

  writeValue(value: any) {
    return;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  uploadFile(event: Event) {
    const fileList: FileList | null = (event.target as HTMLInputElement).files;
    if (!(fileList && fileList.length > 0)) {
      this._onChange(null);
      this._onTouch(null);
      return;
    }

    const file: File = fileList[0];

    if (file.size > this.maxFileByteSize) {
      this.isFileSizeValid = false;
      return;
    }

    this.isFileSizeValid = true;
    const fileNameLength = file.name.split('.').length;
    const fileType = file.name.split('.')[fileNameLength - 1];
    const fileName = removeTextAfterLastDot(file.name);
    this.fileName = `${truncateString(fileName, 35, '---')}.${fileType}`;
    this._onChange(file);
    this._onTouch(file);
  }
}
