import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { RolesEnum } from '@irembo-andela/irembogov3-common';
import IIremboUser from '../../../../core/models/users/irembo-user-model';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';
import { ITabLinks } from '../../../../core/models/tablinks.interface';
import { DefaultManageUsersTabLinks } from '../../../../core/config/manager-users-tablinks.config';

@Component({
  selector: 'irembogov-list-users-for-client-manager',
  templateUrl: './list-users-for-client-manager.component.html',
  styleUrls: ['./list-users-for-client-manager.component.scss'],
})
export class ListUsersForClientManagerComponent implements OnDestroy {
  userProfile!: IIremboUser;
  tabFragment: string | null = null;
  selectedTabTemplate!: TemplateRef<unknown> | null;
  private unsubscribe$: Subject<void>;

  RolesEnum = RolesEnum;
  @ViewChild('clientUsersTab', { static: true })
  clientUsersTabTemplate!: TemplateRef<unknown>;
  @ViewChild('clientchangeRequestsTab', { static: true })
  clientChangeRequestsTabTemplate!: TemplateRef<unknown>;

  constructor(public route: ActivatedRoute, private authService: AuthService) {
    this.unsubscribe$ = new Subject<void>();

    this.authService.userProfile$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile: IIremboUser) => (this.userProfile = profile));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTabsMenuList(): ITabLinks[] {
    if (!(this.userProfile && this.userProfile.primaryRole)) return [];

    return Object.values(DefaultManageUsersTabLinks).filter(
      (tablink: ITabLinks) => {
        return tablink.allowedRoles.includes(this.userProfile.primaryRole.key);
      }
    );
  }
  getTabViewList(fragment: string | null) {
    if (fragment === 'change-requests') {
      return this.clientChangeRequestsTabTemplate;
    } else {
      return this.clientUsersTabTemplate;
    }
  }
}
