import {
  EKeycloakPortalClientID,
  RolesEnum,
} from '@irembo-andela/irembogov3-common';

/**
 * Map holding the client IDs for every role on the platform
 */
export const roleClientMap: Record<string, string> = {
  [RolesEnum.ROLE_IREMBOGOV_ADMIN]: EKeycloakPortalClientID.ADMIN_MGMT_PORTAL,
  [RolesEnum.ROLE_PARTNERSHIP_MANAGER]:
    EKeycloakPortalClientID.ADMIN_MGMT_PORTAL,
  [RolesEnum.ROLE_INTEGRATION_MANAGER]:
    EKeycloakPortalClientID.INTEGRATION_MGMT_PORTAL,
  [RolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER]:
    EKeycloakPortalClientID.ADMIN_MGMT_PORTAL,
};
