<div class="irembo-user-basic-info" *ngIf="userChangeRequest">
  <div
    class="card irembo-user-profile-card border-0 mb-3"
    *ngIf="userChangeRequest">
    <div class="card-body">
      <div class="profile d-flex">
        <div class="profile-img">
          <div class="profile-img-circle"></div>
        </div>
        <div class="profile-info d-flex flex-column ms-3">
          <h5>{{ userChangeRequest.name }}</h5>
          <span class="badge bg-info">
            {{ getRoleLabel(userChangeRequest.role) }}
          </span>
        </div>
      </div>
      <div class="profile-extras">
        <div class="text-muted w-100 my-3">IT Manager at DGIE</div>
        <div class="text-muted d-flex my-3 gap-3">
          <span>
            <i class="fa-regular fa-envelope"></i>
            {{ userChangeRequest.email }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
