import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  forwardRef,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { formatFileSizeBytesUtil } from '../../../utils/utils/base-64-uri-to-file-converter.util';

@Component({
  selector: 'irembogov-generic-file-upload',
  templateUrl: './irembo-generic-file-upload.component.html',
  styleUrls: ['./irembo-generic-file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IremboGenericFileUploadComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: IremboGenericFileUploadComponent,
      multi: true,
    },
  ],
})
export class IremboGenericFileUploadComponent
  implements ControlValueAccessor, Validator
{
  @Input() allowedFormats: string[] = ['png', 'jpg', 'pdf'];
  @Input() noFileActionLabel = 'Click to upload';
  @Input() loadFileContentAsBase64Data = false;
  @Input() noFileSubLabelHtml = `or drag and drop <br /> ${this.allowedFormats
    .join(', ')
    .toUpperCase()}`;
  @Input() required = false;
  @Input() maximumUploadSize = 2048;
  @Input() minmumUploadSize = 5;
  @Input() id: unknown;
  @Input() placeholder = 'Select file to upload';
  @Output() fileUploadEvent = new EventEmitter<File | null>();
  @Output() handleValidationErrors = new EventEmitter<ValidationErrors>();

  formatFileSizeBytesUtil = formatFileSizeBytesUtil;

  disabled = false;
  file: File | null | undefined;
  hasErrors = false;
  addDragClass = false;

  constructor(private cd: ChangeDetectorRef) {}

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function*/
  private _onTouch = (value: unknown) => {};
  private _onChange = (value: unknown) => {};
  private _onValidationChange = () => {};
  /* eslint-enable */

  writeValue(obj: File): void {
    this.file = obj;
    if (obj) {
      this._onChange(this.file);
      this._onTouch(this.file);
      this._onValidationChange();
    }
  }

  registerOnTouched(fn: (_: unknown) => void): void {
    this._onTouch = fn;
  }

  registerOnChange(fn: (_: unknown) => void): void {
    this._onChange = fn;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this._onValidationChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  removeFile() {
    this.file = null;
    this.addDragClass = false;
    this._onChange(null);
    this._onTouch(null);
    this.fileUploadEvent.emit(null);
    this.hasErrors = false;
    this._onValidationChange();
  }

  onClickFileUpload(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  private getFileListFromEvent(e: Event): FileList | null {
    return (e.target as HTMLInputElement).files;
  }

  change(e: unknown) {
    this.hasErrors = false;
    if (e instanceof Event) {
      const fileList = this.getFileListFromEvent(e);
      if (fileList) {
        this.file = fileList[0];
        this.processFile();
      }
    }
    this._onValidationChange();
  }

  private processFile() {
    this._onChange(this.file);
    this._onTouch(this.file);

    if (!this.hasErrors) {
      this.fileUploadEvent.emit(this.file);
    }
  }

  private checkIfExtensionIsAllowed(ext: string) {
    return (
      this.allowedFormats &&
      this.allowedFormats
        .map((format: string) => format.toLowerCase())
        .indexOf(ext.toLowerCase()) > -1
    );
  }

  acceptList() {
    return this.allowedFormats
      .map(format => `.${format.toLowerCase()}`)
      .toString();
  }

  validate(): ValidationErrors | null {
    this.hasErrors = false;
    const validationErrors: ValidationErrors = {};
    if (this.required && !this.file) {
      validationErrors['required'] = true;
      this.hasErrors = true;
      this.cd.detectChanges();
      return validationErrors;
    }

    if (!this.file) {
      return null;
    }

    const ext = this.file?.name?.split('.')?.pop();

    if (!ext) {
      validationErrors['invalidfileformat'] = true;
    }

    if (
      this.allowedFormats?.length &&
      ext &&
      !this.checkIfExtensionIsAllowed(ext)
    ) {
      validationErrors['invalidfileformat'] = true;
    }

    const fileSizeInKB = this.file.size / 1024;
    if (fileSizeInKB < this.minmumUploadSize) {
      validationErrors['minimumUploadSize'] = true;
    }

    if (fileSizeInKB > this.maximumUploadSize) {
      validationErrors['maximumUploadSize'] = true;
    }

    this.handleValidationErrors.emit(validationErrors);

    if (Object.keys(validationErrors).length) {
      this.hasErrors = true;
      this.cd.detectChanges();
      return validationErrors;
    }
    return null;
  }

  getIcon(fileName: unknown): string {
    const ext = (fileName as string).split('.').pop();
    return ext === 'pdf' ? 'fa-regular fa-file-pdf' : 'fa-regular fa-image';
  }

  castToString(value: unknown) {
    return value as string;
  }

  toogledragClass(event: Event, status: boolean) {
    this.addDragClass = status;
  }
}
