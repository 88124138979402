import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@irembo-andela/irembogov3-common';
import { NgModule } from '@angular/core';
import { DashboardComponent } from './components/dashboard-component/dashboard.component';
import { HomeComponent } from './components/home-component/home.component';
import { Error401UnauthorizedComponent } from './components/http-errors/error-401-unauthorized/error-401.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { Error404MissingResourceComponent } from './components/http-errors/error-404-missing-resource/error-404.component';
import { AllowRouteDataRolesGuard } from './core/guards/allow-route-data-roles.guard';
import { adminPortalAllowedRoles } from './core/models/users/user-roles.model';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth-module/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'unauthorised',
    component: Error401UnauthorizedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '404',
    component: Error404MissingResourceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: HomeComponent,
    children: [
      { path: '', component: DashboardComponent },
      {
        path: 'profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users-module/users.module').then(
            m => m.UsersModule
          ),
      },
      {
        path: 'org',
        loadChildren: () =>
          import('./modules/organizations-module/organizations.module').then(
            m => m.OrganizationsModule
          ),
      },
      {
        path: 'agencies',
        loadChildren: () =>
          import('./modules/agencies-module/agencies.module').then(
            m => m.AgenciesModule
          ),
      },
      {
        path: 'agents',
        loadChildren: () =>
          import('./modules/agents-module/agents.module').then(
            m => m.AgentsModule
          ),
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./modules/services-module/services.module').then(
            m => m.ServicesModule
          ),
      },
      {
        path: 'translations',
        loadChildren: () =>
          import('./modules/translations-module/translations.module').then(
            m => m.TranslationsModule
          ),
      },
      { path: '**', redirectTo: '' },
    ],
    canActivate: [AuthGuard, AllowRouteDataRolesGuard],
    data: {
      roles: adminPortalAllowedRoles,
      forceLogout: true,
    },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
