<div class="irembo-sidebar pb-2">
  <div class="py-2 px-3" ngbDropdown *ngIf="institutions !== undefined">
    <div class="p-2" type="button">
      <div
        class="row"
        id="sidebar-dropdown"
        ngbDropdownToggle
        *ngIf="selectedInstitution !== undefined">
        <div class="col-auto d-flex align-items-center">
          <div class="logo" *ngIf="!selectedInstitution.image"></div>
          <img
            [src]="selectedInstitution.image"
            alt="{{ selectedInstitution }}"
            class="logo-image"
            *ngIf="selectedInstitution.image" />
        </div>
        <div class="col-auto">
          <small class=""> Institution </small>
          <p class="org-name m-0">
            {{ selectedInstitution.code }}
            <i class="fa fa-chevron-down ms-2"></i>
          </p>
        </div>
      </div>

      <ul ngbDropdownMenu aria-labelledby="sidebar-dropdown" class="w-100 mt-2">
        <li ngbDropdownItem *ngFor="let item of institutions">
          <a
            class="dropdown-item py-2 d-flex align-items-center"
            (click)="institutionChanged.emit(item)">
            <div class="logo me-2" *ngIf="!item.image"></div>
            <img
              [src]="item.image"
              alt="institution logo"
              class="logo-image me-2"
              *ngIf="item.image" />
            {{ item.name }}
          </a>
        </li>
      </ul>
    </div>
  </div>
  <hr class="mt-2" *ngIf="institutions !== undefined" />
  <div
    class="pt-5"
    *ngIf="institutions === undefined || institutions.length === 0"></div>
  <div class="container">
    <button
      class="btn mb-2"
      *ngFor="let item of sidebarItems; let i = index"
      routerLinkActive="active"
      (click)="actionClick.emit(item)"
      [ngClass]="{
        'text-start': !item.isPrimaryAction,
        'btn-primary cta': item.isPrimaryAction
      }">
      <i class="{{ item.icon }} me-2" *ngIf="item.icon !== undefined"></i>
      {{ item.name }}
    </button>
  </div>
</div>
