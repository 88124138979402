<irembogov-navbar [navbarItems]="getMainNavBarLinkItemsForRole()">
  <irembogov-navbar-profile-section
    profile
    [username]="
      userProfile && userProfile.firstName ? userProfile.firstName : ''
    "
    [activeRole]="
      userProfile && userProfile.primaryRole.label
        ? userProfile.primaryRole.label
        : ''
    "
    [roles]="userProfile.roles"
    [updatedProfile]="userProfile.profileImageUrl"
    (logout)="logoutUser()"
    (profileClick)="
      profilePageRedirect($event)
    "></irembogov-navbar-profile-section>
</irembogov-navbar>
