import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@irembo-andela/irembogov3-common';
import { MainNavBarLinkItems } from '../../core/config/nav-bar-link-items.config';
import { AllowRouteDataRolesGuard } from '../../core/guards/allow-route-data-roles.guard';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ViewUserChangeRequestComponent } from './manage-users/view-user-change-request/view-user-change-request.component';

const routes: Routes = [
  {
    path: '',
    component: ManageUsersComponent,
    canActivate: [AuthGuard, AllowRouteDataRolesGuard],
    data: { roles: MainNavBarLinkItems['users'].allowedRoles },
  },
  {
    path: 'request/:requestId',
    component: ViewUserChangeRequestComponent,
    canActivate: [AuthGuard, AllowRouteDataRolesGuard],
    data: { roles: MainNavBarLinkItems['users'].allowedRoles },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
