/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  IUiAlertContent,
  ToastService,
  TUiAlertTypes,
} from '@irembo-andela/irembogov3-common';
import { ALLOWED_DOC_FILE_TYPES } from './../../../../../core/config/file-upload-file-types.config';
import { environment } from './../../../../../../environments/environment';
import { ClientService } from './../../../../../core/services/client.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  OnInit,
  OnDestroy,
} from '@angular/core';

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { RequestTypeEnum } from 'src/app/core/models/request-type.enum.model';
import { UsersService } from 'src/app/core/services/users.service';
import { removeExtraWhiteSpaces } from '../../../../../core/utils/forms-utils';

@Component({
  selector: 'irembogov-client-manager-invite-service-manager',
  templateUrl: './client-manager-invite-service-manager.component.html',
  styleUrls: ['./client-manager-invite-service-manager.component.scss'],
})
export class ClientManagerInviteServiceManagerComponent
  implements OnInit, OnDestroy
{
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newServiceManagerFormSubmitted: EventEmitter<any> =
    new EventEmitter<any>();
  @ViewChild('errorMessageElement') errorMessageElement!: ElementRef;

  fileInputsRequired = true;
  allowedFileTypes: string[] = ALLOWED_DOC_FILE_TYPES;

  isAssigning = false;
  isLoading = false;
  showFormError = false;
  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };

  addNewServiceManager: FormGroup = new FormGroup({
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(environment.personNameRegex),
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(environment.personNameRegex),
    ]),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(environment.customemailValidationRegex),
      Validators.email,
    ]),
    certificateDocument: new FormControl<File | null>(null, [
      Validators.required,
    ]),
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    private clientService: ClientService,
    private usersService: UsersService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.addNewServiceManager.reset();
    this.initiateEmailSubscription();
  }

  initiateEmailSubscription() {
    this.addNewServiceManager.controls['email'].valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(() => {
          if (
            !this.addNewServiceManager.controls['email'].valid &&
            this.isAssigning
          ) {
            this.addNewServiceManager.controls['firstName'].reset();
            this.addNewServiceManager.controls['lastName'].reset();
            this.isAssigning = false;
          }
          return this.addNewServiceManager.controls['email'].valid;
        }),
        switchMap((value, index) => {
          return this.usersService.checkIfIremboUserExists(value).pipe(
            catchError(() => {
              if (this.isAssigning) {
                this.addNewServiceManager.controls['firstName'].reset();
                this.addNewServiceManager.controls['lastName'].reset();
                this.isAssigning = false;
              }
              return EMPTY;
            })
          );
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: res => {
          this.isAssigning = true;
          this.addNewServiceManager.controls['firstName'].setValue(
            res.data.firstName
          );
          this.addNewServiceManager.controls['lastName'].setValue(
            res.data.lastName
          );
        },
      });
  }

  cancelForm() {
    this.isLoading = false;
    this.closeForm.emit(true);
  }

  updateDocumentCertificateValue(event: Event) {
    const fileList: FileList | null = (event.target as HTMLInputElement).files;
    if (!(fileList && fileList.length > 0)) return;
    const file: File = fileList[0];

    if (this.allowedFileTypes.indexOf(file.type) === -1) {
      this.addNewServiceManager.controls['certificateDocument'].setErrors({
        required: true,
      });
      this.addNewServiceManager.controls['certificateDocument'].markAsTouched();
      this.addNewServiceManager.controls[
        'certificateDocument'
      ].updateValueAndValidity();
      return;
    }
    this.addNewServiceManager.patchValue({ certificateDocument: file });
    this.addNewServiceManager.controls[
      'certificateDocument'
    ].updateValueAndValidity();
  }

  onCreateServiceManager() {
    if (!this.validateForm(this.addNewServiceManager)) {
      return;
    }

    this.updateFormValidationErrorMessageContent(false);
    this.isLoading = true;

    const req: any = {
      firstName: removeExtraWhiteSpaces(
        this.addNewServiceManager.controls['firstName'].value
      ),
      lastName: removeExtraWhiteSpaces(
        this.addNewServiceManager.controls['lastName'].value
      ),
      email: this.addNewServiceManager.controls['email'].value,
      certificateDocument:
        this.addNewServiceManager.controls['certificateDocument'].value,
      requestType: this.isAssigning
        ? RequestTypeEnum.ASSIGN
        : RequestTypeEnum.CREATE,
    };

    this.clientService
      .createServiceManagerRequest(req)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          const newServiceManager: any = {
            firstName: req.firstName,
          } as any;

          this.newServiceManagerFormSubmitted.emit(newServiceManager);
          this.toastService.show({
            body: this.isAssigning
              ? 'Service manager role assigned successfully'
              : `Created new service manager success!`,
            type: 'success',
          });
        },
        error: error => {
          const errorMessage = error.responseMessage
            ? error.error.responseMessage
            : this.isAssigning
            ? 'Failed to assign service manager role'
            : 'Could not initiate new service manager';

          this.updateFormValidationErrorMessageContent(
            true,
            'danger',
            this.isAssigning
              ? 'Failed to assign service manager role'
              : 'Creating new service manager failed!',
            errorMessage
          );
        },
      });
  }

  updateFormValidationErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    title: string = '',
    message: string = ''
  ) {
    this.showFormError = show;
    this.formAlertContent = { type, title, message };
    if (show) {
      this.errorMessageElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  private validateForm(form: FormGroup): boolean {
    if (form.invalid) {
      this.updateFormValidationErrorMessageContent(
        true,
        'danger',
        'Invalid fields!',
        'Please ensure you have filled in all the required fields.'
      );
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
