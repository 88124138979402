<irembogov-full-width-layout-wrapper
  title="Profile Page"
  class="irembogov-user-profile-page">
  <div class="container">
    <div
      class="card irembo-user-profile-card border-0 mb-3"
      *ngIf="userProfile">
      <div class="card-body">
        <div class="profile-info d-flex">
          <div class="p-2 mr-auto" style="margin-right: auto">
            <div class="profile-img">
              <img
                *ngIf="userProfile.profileImageUrl"
                [src]="userProfile.profileImageUrl"
                alt=""
                class="rounded-circle float-start img-fluid font-weight-bold fs-1" />
              <div
                *ngIf="!userProfile.profileImageUrl"
                class="rounded-circle float-start img-thumbnail img-fluid font-weight-bold fs-1 pb-1 text-center letter-holder">
                <strong>{{ userNameFirstLetter }}</strong>
              </div>
            </div>
            <div class="profile-info mt-4" style="margin-left: 6rem !important">
              <h5 class="font-weight-bold">
                {{ userProfile.firstName }} {{ userProfile.lastName }}
              </h5>
              <div class="badge text-primary" style="background-color: #cce7fd">
                {{
                  userProfile.primaryRole ? userProfile.primaryRole.label : ''
                }}
              </div>
            </div>
          </div>
          <div class="profile-edit-button ml-auto">
            <button
              class="btn btn-sm text-danger mt-4"
              (click)="toggleSidePane()"
              style="background-color: #ffe7ad">
              <i class="fa-regular fa-pen-to-square text-danger"></i>
              Update profile picture
            </button>
          </div>
        </div>
        <div class="profile-extras" style="margin-left: 1rem">
          <div class="text-muted d-flex gap-2">
            <span>
              <i class="fa-regular fa-envelope"></i>
              {{ userProfile.email }}
            </span>
            <span>
              <i class="fa-solid fa-phone-flip fa-flip-horizontal"></i>
              {{ userProfile.phone }}
            </span>
          </div>
          <div class="">
            <span class="badge bg-success" *ngIf="userProfile.emailVerified">
              Active
            </span>
            <span class="badge bg-danger" *ngIf="!userProfile.emailVerified">
              Inactive
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <irembogov-offcanvas
    title="Update Profile Picture"
    description="Edit your profile info"
    [paneOpen]="sidePaneOpen"
    (OnPaneToggle)="updateToggleState($event)">
    <ng-template>
      <div class="profile-update bg-white">
        <p class="p-3">Profile picture (optional)</p>
        <div class="m-5">
          <form [formGroup]="editUserForm" class="form-inline">
            <div
              class="card ml-3 mb-3 current-profile d-flex align-items-center"
              [ngStyle]="{ backgroundImage: 'url(' + profileAvatar + ')' }">
              <div class="form-row">
                <div class="d-flex justify-content-around align-items-center">
                  <irembogov-file-upload-input
                    [displayAsButton]="true"
                    labelDesc="Choose Image"
                    [allowedFileTypes]="allowFileTypes"
                    errorMessageAsHTML="Selected type is not allowed"
                    class="file-upload-input"
                    formControlName="profilePicture"
                    [displaySelectedFileName]="false"
                    (change)="
                      updatePreview($event)
                    "></irembogov-file-upload-input>
                </div>
              </div>
            </div>
            <div class="modal-footer mt-3 pb-3">
              <button type="submit" class="btn" (click)="cancelSidePane(false)">
                Cancel
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!editUserForm.valid"
                (click)="onProfileUpdate()">
                <div
                  *ngIf="isUpdating"
                  class="spinner-border spinner-border-sm mr-2"
                  role="status"></div>
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </ng-template>
  </irembogov-offcanvas>
</irembogov-full-width-layout-wrapper>
