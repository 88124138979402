/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { UserRolesEnum } from '../models/users/user-roles.model';
import {
  IApproveUserChangeRequestRequestModel,
  ICreateNewUserChangeRequestRequestModel,
  IUpdateUserChangeRequestRequestModel,
} from '../models/users/create-new-user-change-request.model';
import { UserChangeRequestActiveStatusEnum } from '../models/users/user-change-request-response.model';
import { ChangeRequestTypeEnum } from '../models/change-request-type.enum.model';
import { THeaderParams } from '../models/header-params.type';

@Injectable({
  providedIn: 'root',
})
export class UserChangeRequestsService {
  constructor(private http: HttpClient) {}

  getChangeRequestById(requestId: string): Observable<any> {
    const headerParams: THeaderParams = {};
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request/${requestId}`,
      { headers }
    );
  }

  getChangeRequests(
    page: number = 0,
    size: number = 50,
    sort: string[] = [],
    statusValue: UserChangeRequestActiveStatusEnum | null = null
  ): Observable<any> {
    const headerParams: THeaderParams = {};
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    const pageSortQuery: string =
      sort.length > 0 ? `&sort=${sort.toString()}` : '';
    const statusValueQuery: string = statusValue
      ? `&statusValue=${statusValue}`
      : '';
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request?page=${page}&size=${size}${pageSortQuery}${statusValueQuery}`,
      { headers }
    );
  }

  getChangeRequestsV2(
    page: number = 0,
    size: number = 50,
    sort: string[] = [],
    requestStatus: ChangeRequestTypeEnum[] = []
  ): Observable<any> {
    const headerParams: THeaderParams = {};
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    const pageSortQuery: string =
      sort.length > 0 ? `&sort=${sort.toString()}` : '';
    const requestStatusQuery: string =
      requestStatus.length > 0
        ? `&requestStatus=${requestStatus.join(',')}`
        : '';
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request/request/status?page=${page}&size=${size}${pageSortQuery}${requestStatusQuery}`,
      { headers }
    );
  }

  getChangeRequestsByRole(
    role: UserRolesEnum,
    page: number = 0,
    size: number = 50,
    sort: string[] = []
  ): Observable<any> {
    const headerParams: THeaderParams = {};
    const headers: HttpHeaders = new HttpHeaders(headerParams);
    const pageSort: string = sort.length > 0 ? `&sort=${sort.toString()}` : '';
    return this.http.get(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request?userRole=${role}?page=${page}&size=${size}${pageSort}`,
      { headers }
    );
  }

  createNewuserChangeRequest(
    createRequest: ICreateNewUserChangeRequestRequestModel
  ): Observable<any> {
    const headers = new HttpHeaders({});
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request/qa-reviewer-request`,
      createRequest,
      { headers }
    );
  }

  createNewUserChangeRequestWithFormData(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({});
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request/initiate`,
      formData,
      { headers }
    );
  }

  approveUserChangeRequest(
    createRequest: IApproveUserChangeRequestRequestModel
  ): Observable<any> {
    const headers = new HttpHeaders({});
    return this.http.post(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request/approve`,
      createRequest,
      { headers }
    );
  }

  reactivateUserChangeRequest(
    createRequest: IUpdateUserChangeRequestRequestModel
  ): Observable<any> {
    const headers = new HttpHeaders({});
    return this.http.patch(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request/reactivate`,
      createRequest,
      { headers }
    );
  }

  deactivateUserChangeRequest(
    createRequest: IUpdateUserChangeRequestRequestModel
  ): Observable<any> {
    const headers = new HttpHeaders({});
    return this.http.patch(
      `${environment.apiGatewayBaseUrl}/admin/v1/user-management-request/deactivate`,
      createRequest,
      { headers }
    );
  }
}
