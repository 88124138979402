export const CreateFormDataFromRequest = (req: any): FormData => {
  const formData: FormData = new FormData();
  Object.keys(req).forEach(key => {
    const data: string | Blob | undefined = req[key as keyof typeof req];
    if (data === undefined) return;
    formData.append(key, data);
  });
  return formData;
};

/**
 * Truncates string based on the string length limit provided, adds a placeholder if truncated
 * @param str
 * @param maxLength
 * @param placeholder
 * @returns truncated string
 */
export const truncateString = (
  str: string,
  maxLength: number,
  placeholder?: string
) => {
  if (str.length > maxLength) {
    return `${str.slice(0, maxLength)} ${placeholder ? placeholder : '...'}`;
  } else {
    return str;
  }
};

/**
 * Removes the text that is trailing after a dot
 * @param str
 * @returns
 */
export const removeTextAfterLastDot = (str: string): string => {
  const lastDotIndex = str.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return str.substring(0, lastDotIndex);
  } else {
    return str;
  }
};

/**
 * Converts bytes into megabytes
 * @param bytes
 * @returns
 */
export const bytesToMegabytes = (bytes: number): number => {
  return Math.round(bytes / (1024 * 1024));
};

/**
 * Replaces multiple whitespaces in words with one whitespace
 *
 * @param source
 * @returns string with words separated by one space
 */
export const removeExtraWhiteSpaces = (source: string) =>
  source.trim().split(/\s+/).join(' ');
