import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import {
  ExtractHttpErrorResponseCode,
  ExtractHttpErrorResponseCodeAndMessage,
  ExtractHttpErrorResponseMessage,
  IHttpErrorResponseCodeAndMessage,
} from '../../../../utils/utils/http-error-response-extractor.util';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IAccountActivationRequest,
  IAuthEngineHttpBaseResponse,
  ICheckAccountActivationOtpResponseData,
} from '../../../interfaces';
import { EUserType } from '../../../enums/user-type.enum';
import { IremboPasswordRegExpErrorMsg } from '../../../utils/auth.utils';

type TActivationStatus =
  | 'otp'
  | 'activated'
  | 'pending'
  | 'success'
  | 'failure'
  | 'expired'
  | 'tokenSent';

@Component({
  selector: 'irembogov-non-citizen-account-activation',
  templateUrl: './non-citizen-account-activation.component.html',
})
export class NonCitizenAccountActivationComponent implements OnInit {
  defaultFailureTitle = 'Account activation failed';
  defaultFailureMessage = 'Error encountered while activating account';
  defaultFailureResendDescription = 'Do you want another link?';
  defaultSuccessTitle = 'Account activated successfully';
  defaultSuccessMessage =
    'Your account has been successfully activated. Click below to login';
  defaultActivatedErrorTitle = 'Account already activated';
  defaultActivatedErrorMessage =
    'The activation link you are trying to access is from an account that is already activated. <br /> Click the button below to login';
  defaultLinkExpiredErrorMessage =
    'The activation link you are trying to access has expired.';
  defaultResendSuccessTitle = 'Email Sent';
  defaultResendSuccessMessage =
    'A new activation token has been sent to your email';

  @Input() authorizationServiceBaseUrl!: string;
  @Input() authorizationClientId!: string;
  @Input() showBackToLogin = true;
  @Input() showLogo = true;
  @Input() showSuccessLogo = true;
  @Input() showExpiredLogo = true;
  @Input() showActivatedLogo = true;
  @Input() enforceDefaultExpiredErrorMessage = false;
  @Input() resendLinkText = 'Click to Resend';
  @Input() resendLinkType: 'link' | 'button' = 'link';
  @Input() showPasswordStrengthMeter = false;

  @Input() accountActivationTitle = 'Account activation';
  @Input() accountActivationDescription =
    'Create a password to activate your account';
  @Input() accountActivationButtonActionLabel = 'Activate account';
  @Input() accountActivationRegexErrorMessage: string =
    IremboPasswordRegExpErrorMsg;
  @Input() accountActivationPasswordMatchErrorMessage =
    'Passwords must match before proceeding';

  @Input() successTitle = this.defaultSuccessTitle;
  @Input() successMessage = this.defaultSuccessMessage;
  @Input() successButtonActionLabel = 'Log in';

  @Input() activatedTitle = this.defaultActivatedErrorTitle;
  @Input() activatedMessage = this.defaultActivatedErrorMessage;
  @Input() activatedButtonActionLabel = 'Login';

  @Input() resendSuccessTitle = this.defaultResendSuccessTitle;
  @Input() resendSuccessMessage = this.defaultResendSuccessMessage;

  @Output() OnGoToResendOtp: EventEmitter<boolean> = new EventEmitter();
  @Output() OnContinue: EventEmitter<boolean> = new EventEmitter();
  @Output() OnGoToLogin: EventEmitter<boolean> = new EventEmitter();

  oneTimePasswordToken: string;
  username: string;
  accountActivationRequestStatus?: TActivationStatus;
  activationMethod: 'code' | 'link' = 'code';
  isSubmitting = false;
  isVerifyingToken = true;
  failureTitle = this.defaultFailureTitle;
  failureMessage = this.defaultFailureMessage;
  failureResendDescription = this.defaultFailureResendDescription;
  activatedErrorMessage = '';

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.oneTimePasswordToken =
      this.activatedRoute.snapshot.queryParamMap.get('token') ?? '';
    this.username =
      this.activatedRoute.snapshot.queryParamMap.get('username') ?? '';
  }

  ngOnInit() {
    if (this.oneTimePasswordToken && this.username) {
      this.checkActivationOtp();
    } else {
      this.isVerifyingToken = false;
      this.accountActivationRequestStatus = 'otp';
      this.activationMethod = 'code';
    }
  }

  checkActivationOtp(): void {
    this.authService
      .checkActivationOtp(
        this.authorizationServiceBaseUrl,
        this.oneTimePasswordToken,
        EUserType.OTHER,
        this.username
      )
      .pipe(finalize(() => (this.isVerifyingToken = false)))
      .subscribe({
        next: (
          response: IAuthEngineHttpBaseResponse<ICheckAccountActivationOtpResponseData>
        ) => {
          if (response.data) {
            this.accountActivationRequestStatus = 'pending';
            return;
          }
          this.accountActivationRequestStatus = 'failure';
        },
        error: (error: HttpErrorResponse) => {
          const errorCode: string | null = ExtractHttpErrorResponseCode(error);
          if (errorCode && errorCode === 'ACCOUNT_ALREADY_ACTIVATED') {
            const message =
              'The activation link you are trying to access is from an account that is already activated.';
            this.updateActivationErrorMessage(message);
            this.accountActivationRequestStatus = 'activated';
            return;
          }

          if (
            errorCode === 'otp_8007' ||
            errorCode === 'otp_8005' ||
            errorCode === 'OTP_EXPIRED'
          ) {
            this.updateFailureMessage(
              'Link Expired',
              this.enforceDefaultExpiredErrorMessage
                ? this.defaultLinkExpiredErrorMessage
                : ExtractHttpErrorResponseMessage(
                    error,
                    this.defaultLinkExpiredErrorMessage
                  )
            );
            this.accountActivationRequestStatus = 'expired';
            return;
          }

          const errorMessage = 'Could not activate account. Contact admin';
          const title: string = ExtractHttpErrorResponseMessage(
            error,
            'Activation Failed'
          );

          this.updateFailureMessage(title, errorMessage);

          this.accountActivationRequestStatus = 'failure';
        },
      });
  }

  onActivationFormSubmit(password: string) {
    const activateAccountRequest: IAccountActivationRequest = {
      password,
      clientId: this.authorizationClientId,
      oneTimePasswordToken: this.oneTimePasswordToken,
      userType: EUserType.OTHER,
      username: this.username,
    };

    this.updateActivationErrorMessage('');

    this.isSubmitting = true;
    this.authService
      .activateAccount(this.authorizationServiceBaseUrl, activateAccountRequest)
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe({
        next: (response: { message: string }) => {
          this.updateFailureMessage(
            response.message,
            this.defaultSuccessMessage
          );
          this.accountActivationRequestStatus = 'success';
        },
        error: (error: HttpErrorResponse) => {
          const codeAndMessage: IHttpErrorResponseCodeAndMessage =
            ExtractHttpErrorResponseCodeAndMessage(
              error,
              'Could not activate account'
            );

          this.updateActivationErrorMessage(codeAndMessage.message);

          if (codeAndMessage.code === 'OTP_EXPIRED') {
            this.updateFailureMessage(
              'Link expired',
              'The activation link you are trying to access has expired.'
            );
            this.accountActivationRequestStatus = 'expired';
          }
        },
      });
  }

  updateFailureMessage(
    title: string = this.defaultFailureTitle,
    message: string = this.defaultFailureMessage,
    resendDescription: string = this.defaultFailureResendDescription
  ) {
    this.failureTitle = title;
    this.failureMessage = message;
    this.failureResendDescription = resendDescription;
  }

  updateSuccessMessage(
    title: string = this.defaultSuccessTitle,
    description: string = this.defaultSuccessMessage
  ) {
    this.successTitle = title;
    this.successMessage = description;
  }

  updateActivationErrorMessage(
    message: string = this.defaultActivatedErrorMessage
  ) {
    this.activatedErrorMessage = message;
  }

  resendWithExpiredOtp() {
    this.isVerifyingToken = true;
    this.authService
      .resendActivationOtpUsingExpiredToken(
        this.authorizationServiceBaseUrl,
        this.authorizationClientId,
        this.oneTimePasswordToken,
        this.username
      )
      .pipe(finalize(() => (this.isVerifyingToken = false)))
      .subscribe({
        next: () => {
          this.updateSuccessMessage(
            this.resendSuccessTitle,
            this.resendSuccessMessage
          );
          this.accountActivationRequestStatus = 'tokenSent';
        },
        error: (error: HttpErrorResponse) => {
          this.updateFailureMessage(
            'Token not sent',
            error.error?.message
              ? error.error.message
              : 'We could not send a new token',
            'Do you want to try again?'
          );
          this.accountActivationRequestStatus = 'expired';
        },
      });
  }

  onContinue() {
    this.OnContinue.emit(true);
  }

  onGoToLogin() {
    this.OnGoToLogin.emit(true);
  }
}
