import {
  ICreateIremboUserRequest,
  IIremboRolesDefinition,
} from '../../../../../core/models/users/create-irembo-user-request';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  forwardRef,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { UsersService } from '../../../../../core/services/users.service';
import { environment } from '../../../../../../environments/environment';
import { IUserRolesEnumValuePair } from '../../../../../core/models/users/user-roles.model';
import {
  IUiAlertContent,
  TUiAlertTypes,
  ERecipientTypeEnum,
  RolesEnum,
} from '@irembo-andela/irembogov3-common';
import { AuthService } from '../../../../../core/services/auth.service';
import {
  finalize,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
} from 'rxjs';
import { ExtractHttpErrorMessage } from '../../../../../core/utils/http-error-message-extractor.util';
import { DEFAULT_IREMBO_USER_ROLES } from '../../../../../core/config/users-additional-roles.config';
import { roleClientMap } from '../../../../../core/utils/role.utils';
import { IAssignRoleRequest } from '../../../../../core/models/users/assign-role-request.model';
import { removeExtraWhiteSpaces } from '../../../../../core/utils/forms-utils';

@Component({
  selector: 'irembogov-create-user',
  templateUrl: './create-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CreateUserComponent),
      multi: true,
    },
  ],
})
export class CreateUserComponent implements OnDestroy, OnInit {
  userRole!: IUserRolesEnumValuePair;
  _userRole!: Subscription;

  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newUserFormSubmitted: EventEmitter<string> =
    new EventEmitter<string>();

  isLoading = false;
  isVerifyingEmail = false;
  showFormErrorMessage = false;
  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };
  userExists!: boolean;

  createUserForm: FormGroup = new FormGroup({
    id: new FormControl<string>(''),
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(environment.personNameRegex),
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(environment.personNameRegex),
    ]),
    username: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(environment.customemailValidationRegex),
    ]),
    role: new FormControl<string[]>([], [Validators.required]),
  });

  userCreateList: Record<string, unknown>[] = [];
  createAllowedRoles: Record<string, unknown>[] =
    DEFAULT_IREMBO_USER_ROLES as unknown as Record<string, unknown>[];

  constructor(
    private usersService: UsersService,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.userCreateList = this.createAllowedRoles;
    this._userRole = this.authService.userRole$.subscribe(
      (role: IUserRolesEnumValuePair) => {
        this.userRole = role;
      }
    );

    this.createUserForm.controls['username'].valueChanges
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe({
        next: (value: string) => {
          this.checkIfUserExists(value);
        },
      });
  }

  ngOnInit(): void {
    this.createUserForm.get('role')?.valueChanges.subscribe(selectedRoles => {
      this.handleRoleChange(selectedRoles);
    });
  }

  handleRoleChange(selectedRoles: string[]) {
    const integrationSelected = selectedRoles.includes(
      RolesEnum.ROLE_INTEGRATION_MANAGER
    );
    if (integrationSelected) {
      this.userCreateList.forEach(role => {
        if (role['key'] !== RolesEnum.ROLE_INTEGRATION_MANAGER) {
          role['disabled'] = true;
          role['selected'] = false;
          role['checked'] = false;
        }
      });

      this.createUserForm
        .get('role')
        ?.setValue([RolesEnum.ROLE_INTEGRATION_MANAGER], { emitEvent: false });
    } else {
      this.userCreateList.forEach(role => {
        role['disabled'] = false;
      });
    }
  }

  cancelForm() {
    this.isLoading = false;
    this.resetForm();
    this.closeForm.emit(true);
  }

  resetForm(): void {
    this.createUserForm.reset();
    this.changeDetectorRef.detectChanges();
  }

  checkIfUserExists(value: string) {
    this.isVerifyingEmail = true;
    this.changeDetectorRef.detectChanges();
    return this.usersService
      .checkIfIremboUserExists(value)
      .pipe(
        finalize(() => {
          this.isVerifyingEmail = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (response: any) => {
          if (response.status) {
            this.userExists = true;
            const roleNames = response?.data?.userRoles?.map(
              (userRole: { name: string }) => userRole.name
            );
            // filter out roles that existing user
            // already has to prevent re-assignment of role
            this.userCreateList = this.createAllowedRoles.filter(role => {
              return roleNames.indexOf(role?.['key']) === -1;
            });

            this.createUserForm.controls['id'].setValue(response.data.id);
            return;
          }

          this.createUserForm.controls['id'].setValue(null);
          this.updateFormDataOutput();
        },
        error: () => {
          this.userCreateList = this.createAllowedRoles;
          this.userExists = false;
          this.createUserForm.controls['id'].setValue(null);
          this.updateFormDataOutput();
        },
      });
  }

  updateFormDataOutput() {
    if (
      this.createUserForm.controls['id'].value &&
      this.createUserForm.controls['id'].valid
    ) {
      this.createUserForm.controls['firstName'].removeValidators([
        Validators.required,
        Validators.pattern(environment.personNameRegex),
      ]);
      this.createUserForm.controls['lastName'].removeValidators([
        Validators.required,
        Validators.pattern(environment.personNameRegex),
      ]);
      this.createUserForm.controls['role'].setValidators([Validators.required]);
    } else {
      this.createUserForm.controls['username'].setValidators([
        Validators.required,
      ]);
      this.createUserForm.controls['firstName'].setValidators([
        Validators.required,
        Validators.pattern(environment.personNameRegex),
      ]);
      this.createUserForm.controls['lastName'].setValidators([
        Validators.required,
        Validators.pattern(environment.personNameRegex),
      ]);

      this.createUserForm.controls['role'].removeValidators([
        Validators.required,
      ]);
    }
    this.createUserForm.controls['username'].setValidators([
      Validators.required,
    ]);
    this.createUserForm.controls['firstName'].updateValueAndValidity();
    this.createUserForm.controls['lastName'].updateValueAndValidity();
    this.createUserForm.controls['role'].updateValueAndValidity();

    this.createUserForm.markAllAsTouched();
    this.createUserForm.updateValueAndValidity();
  }

  async onSubmit() {
    this.updateFormDataOutput();

    if (!this.createUserForm.valid) {
      this.updateFormErrorMessageContent(
        true,
        'danger',
        'Invalid fields!',
        'Please ensure you have filled in all the required fields.'
      );
      return;
    }

    this.updateFormErrorMessageContent(false);

    this.isLoading = true;
    // map selected roles to object structure needed for API request
    const selectedRoles = (
      this.createUserForm.controls['role'].value as string[]
    ).map(role => ({ role }));

    let roleClientId = environment.authClientId ?? '';
    if (
      this.createUserForm.controls['role'].value ===
      RolesEnum.ROLE_INTEGRATION_MANAGER
    ) {
      roleClientId = roleClientMap[this.createUserForm.controls['role'].value];
    }

    if (!this.userExists) {
      this.createUserWithRoles(roleClientId, selectedRoles);
    } else {
      await this.assignRolesToUser(selectedRoles);
    }
  }

  createUserWithRoles(
    roleClientId: string,
    selectedRoles: IIremboRolesDefinition[]
  ) {
    // request obj for creating user
    const req: ICreateIremboUserRequest = {
      clientId: roleClientId,
      firstName: removeExtraWhiteSpaces(
        this.createUserForm.controls['firstName'].value
      ),
      lastName: removeExtraWhiteSpaces(
        this.createUserForm.controls['lastName'].value
      ),
      username: this.createUserForm.controls['username'].value,
      roles: selectedRoles,
      usernameType: ERecipientTypeEnum.EMAIL_ADDRESS,
      userType: 'OTHER',
    };
    this.usersService
      .createIremboUser(req)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (response: { message: string }) => {
          this.handleSuccess('User created', response.message);
        },
        error: (error: HttpErrorResponse) => {
          this.handleHttpError(error);
        },
      });
  }

  async assignRolesToUser(selectedRoles: IIremboRolesDefinition[]) {
    this.isLoading = true;
    try {
      // send request to assign role for each role selected
      for (const selectedRole of selectedRoles) {
        // get respective client ID for user being created
        const roleClientId = roleClientMap[selectedRole.role];
        const req: IAssignRoleRequest = {
          userId: this.createUserForm.controls['id'].value,
          roleName: selectedRole.role,
          clientId: roleClientId,
          userType: 'OTHER',
        };

        await firstValueFrom(this.usersService.assignUserRole(req));
      }
      this.handleSuccess('User roles assigned');
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
    } catch (error) {
      this.isLoading = false;
      this.changeDetectorRef.detectChanges();
      this.handleHttpError(error as HttpErrorResponse);
    }
  }

  updateFormErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    title?: string,
    message?: string
  ) {
    this.showFormErrorMessage = show;
    this.formAlertContent = {
      type,
      title: title ?? '',
      message: message ?? '',
    };
  }

  ngOnDestroy(): void {
    if (this._userRole) this._userRole.unsubscribe();
  }

  handleSuccess(defaultMessage: string, responseMessage?: string) {
    const message = responseMessage ?? defaultMessage;
    this.newUserFormSubmitted.emit(message);
  }

  handleHttpError(error: HttpErrorResponse) {
    const errorMessage: string = ExtractHttpErrorMessage(
      error,
      'Could not create new Irembo user.'
    );
    this.updateFormErrorMessageContent(
      true,
      'danger',
      'Create new Irembo user failed!',
      errorMessage
    );
  }
}
