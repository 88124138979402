import { Component, EventEmitter, Input, Output } from '@angular/core';
import { finalize } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ERecipientTypeEnum } from '../../../enums/recipient-type.enum';

@Component({
  selector: 'irembogov-non-citizen-resend-activation-otp',
  templateUrl: './non-citizen-resend-activation-otp.component.html',
})
export class NonCitizenResendActivationOtpComponent {
  @Input() authorizationServiceBaseUrl!: string;
  @Input() authorizationClientId!: string;

  @Output() OnContinue: EventEmitter<boolean> = new EventEmitter();
  @Output() OnGoToLogin: EventEmitter<boolean> = new EventEmitter();

  resendRequestStatus?: 'pending' | 'success' | 'failure' = 'pending';
  isSubmitting = false;
  sendFailMessage!: string;
  recipient!: string;
  successTitle!: string;
  successQuestion!: string;

  constructor(private authService: AuthService) {}

  resendToEmailClicked(email: string) {
    this.isSubmitting = true;
    this.recipient = email;
    this.successTitle = 'Check your email';
    this.successQuestion = 'Did not receive the email?';
    this.authService
      .resendActivationOtp(
        this.authorizationServiceBaseUrl,
        this.authorizationClientId,
        email,
        ERecipientTypeEnum.EMAIL_ADDRESS
      )
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe({
        next: () => {
          this.resendRequestStatus = 'success';
        },
        error: (error: HttpErrorResponse) => {
          this.sendFailMessage =
            error.error && error.error.message
              ? error.error.message
              : 'We could not send a new token';
          this.resendRequestStatus = 'failure';
        },
      });
  }

  resendToPhoneClicked(phone: string) {
    this.isSubmitting = true;
    this.recipient = phone;
    this.successTitle = 'Check your phone';
    this.successQuestion = 'Did not receive the message?';
    this.authService
      .resendActivationOtp(
        this.authorizationServiceBaseUrl,
        this.authorizationClientId,
        phone,
        ERecipientTypeEnum.PHONE_NUMBER
      )
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe({
        next: () => {
          this.resendRequestStatus = 'success';
        },
        error: () => {
          this.resendRequestStatus = 'failure';
        },
      });
  }

  onContinue() {
    this.OnContinue.emit(true);
  }

  onGoToLogin() {
    this.OnGoToLogin.emit(true);
  }

  resetToRetry() {
    this.sendFailMessage = '';
    this.isSubmitting = false;
    this.resendRequestStatus = 'pending';
  }
}
