<irembogov-full-width-layout-wrapper title="Users">
  <ul ngbNav #nav="ngbNav" [activeId]="route.fragment | async" class="nav-tabs">
    <li
      *ngFor="let tabLink of getTabRendersByRole()"
      [ngbNavItem]="tabLink.fragment">
      <a routerLink="." [fragment]="tabLink.fragment" ngbNavLink>{{
        tabLink.title
      }}</a>
      <ng-template ngbNavContent>
        <ng-container
          *ngTemplateOutlet="getTabTemplate(tabLink.fragment)"></ng-container>
      </ng-template>
    </li>
  </ul>
  <router-outlet [ngbNavOutlet]="nav" class="mt-2 p-3"></router-outlet>
</irembogov-full-width-layout-wrapper>

<!--IRemboGov Users-->
<ng-template #usersTabTemplate>
  <irembogov-users-table-for-irembogov-admin></irembogov-users-table-for-irembogov-admin>
</ng-template>

<!-- Change Requests-->
<ng-template #changeRequestsTabTemplate>
  <irembogov-list-irembogov-admin-change-request></irembogov-list-irembogov-admin-change-request>
</ng-template>
