export const ExtractHttpErrorMessage = (
  error: any,
  defaultMessage: string
): string => {
  return error && error.error && error.error.message
    ? error.error.message
    : defaultMessage;
};

export const ExtractHttpErrorResponseCode = (error: any): string | null => {
  return error && error.error && error.error.responseCode
    ? error.error.responseCode
    : null;
};
