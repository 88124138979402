import {
  IChangeRequestEnumToLabelAndClassPair,
  ChangeRequestStatusEnumToLabelKeyPair,
} from '../../../core/utils/change-requests-common-utils';
import { Component, Input } from '@angular/core';
import { EChangeRequestStatus } from '../../../core/models/change-request-status.enum';

@Component({
  selector: 'irembogov-change-request-status-badge',
  templateUrl: './change-request-status-badge.component.html',
  styleUrls: ['./change-request-status-badge.component.scss'],
})
export class ChangeRequestStatusBadgeComponent {
  @Input() changeRequestStatus!: EChangeRequestStatus;
  @Input() isApprovingLoading = false;
  @Input() isRejectingLoading = false;

  getOrganizationStatusLabel(
    status: EChangeRequestStatus | undefined
  ): IChangeRequestEnumToLabelAndClassPair<EChangeRequestStatus> {
    return ChangeRequestStatusEnumToLabelKeyPair(status);
  }

  checkIfApproved(): boolean {
    return this.changeRequestStatus === EChangeRequestStatus.APPROVED;
  }

  checkIfPending(): boolean {
    return this.changeRequestStatus === EChangeRequestStatus.CREATED;
  }

  checkIfRejected(): boolean {
    return this.changeRequestStatus === EChangeRequestStatus.REJECTED;
  }
}
