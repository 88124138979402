import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgOtpInputConfig } from 'ng-otp-input';

@Component({
  selector: 'irembogov-otp-entry',
  templateUrl: './otp-entry.component.html',
  styleUrls: ['./otp-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OtpEntryComponent {
  @Input() title = 'Account Activation';
  @Input() description =
    'Kindly enter the 4 digit OTP sent to your phone to begin.';
  @Input() submitLabel = 'Submit';
  @Input() showResendButton = true;
  @Input() resendLinkQuestion = 'Did not recieve any otp?';
  @Input() resendLinkLabel = 'Resend Otp';
  @Input() showCancelButton = true;
  @Input() cancelLinkLabel = 'Back To Login';
  @Input() mainErrorMessage = '';
  @Input() otpLength = 4;
  @Input() otpPlaceholder = '-';
  @Input() allowOtpNumbersOnly = true;
  @Input()
  otpInputValidationMessage = `Otp must be ${this.otpLength} digits long`;
  @Input() isLoading = false;

  @Output() OnResendClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() OnCancelClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() OnOtpSubmit: EventEmitter<string> = new EventEmitter<string>();

  otpInputConfig: NgOtpInputConfig = {
    length: this.otpLength,
    allowNumbersOnly: this.allowOtpNumbersOnly,
    placeholder: this.otpPlaceholder,
  };

  otp: FormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(this.otpLength),
  ]);

  onOtpSubmit() {
    if (this.otp.invalid) {
      this.mainErrorMessage = this.otpInputValidationMessage;
      return;
    }
    this.mainErrorMessage = '';
    this.OnOtpSubmit.emit(this.otp.value);
  }
}
