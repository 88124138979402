<div class="password-meter mb-3">
  <div
    class="meter-section rounded me-2"
    [ngClass]="strength >= 1 ? 'weak' : ''"></div>
  <div
    class="meter-section rounded me-2"
    [ngClass]="strength >= 2 ? 'medium' : ''"></div>
  <div
    class="meter-section rounded me-2"
    [ngClass]="strength >= 3 ? 'strong' : ''"></div>
  <div
    class="meter-section rounded"
    [ngClass]="strength >= 4 ? 'very-strong' : ''"></div>
</div>
