import { UserRolesEnum } from '../models/users/user-roles.model';

export const UsersYouCanCreate = (role: UserRolesEnum): UserRolesEnum[] => {
  switch (role) {
    case UserRolesEnum.ROLE_SUPER_ADMIN:
      return [
        UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
        UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
        UserRolesEnum.ROLE_INTEGRATION_MANAGER,
        UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
      ];
    case UserRolesEnum.ROLE_PARTNERSHIP_MANAGER:
      return [
        UserRolesEnum.ROLE_SERVICE_MANAGER,
        UserRolesEnum.ROLE_QA_REVIEWER,
        UserRolesEnum.ROLE_GOVERNMENT_EXEC,
      ];
    default:
      return [];
  }
};

export const UsersYouCanActivateDeactivate = (
  role: UserRolesEnum
): UserRolesEnum[] => {
  switch (role) {
    case UserRolesEnum.ROLE_SUPER_ADMIN:
      return [UserRolesEnum.ROLE_IREMBOGOV_ADMIN];
    case UserRolesEnum.ROLE_IREMBOGOV_ADMIN:
      return [
        UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
        UserRolesEnum.ROLE_INTEGRATION_MANAGER,
        UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
      ];
    default:
      return [];
  }
};

export const UsersYouCanApproveChangeRequests = (
  role: UserRolesEnum
): UserRolesEnum[] => {
  if (role === UserRolesEnum.ROLE_IREMBOGOV_ADMIN) {
    return [
      UserRolesEnum.ROLE_SERVICE_MANAGER,
      UserRolesEnum.ROLE_QA_REVIEWER,
      UserRolesEnum.ROLE_GOVERNMENT_EXEC,
    ];
  }
  return [];
};

export const UsersYouCanCreateChangeRequests = (
  role: UserRolesEnum
): UserRolesEnum[] => {
  if (role === UserRolesEnum.ROLE_PARTNERSHIP_MANAGER) {
    return [
      UserRolesEnum.ROLE_SERVICE_MANAGER,
      UserRolesEnum.ROLE_QA_REVIEWER,
      UserRolesEnum.ROLE_GOVERNMENT_EXEC,
    ];
  }
  return [];
};
