<div class="forgot-pass">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12 text-center mb-3 px-5">
            <img class="img" src="assets/images/logo.png" alt="" />
          </div>
          <div class="col-12 text-center mb-3 check text-center">
            <img
              class="icon-img"
              src="assets/images/irembo_img-key.svg"
              alt="" />
          </div>
          <div class="col-12">
            <h2 class="text-center">{{ title }}</h2>
            <p class="text-center mt-4 desc">
              {{ description }}
            </p>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <div class="mt-2 forgot-pass-form">
              <form [formGroup]="forgotPasswordForm" (submit)="onFormSubmit()">
                <div class="mx-1 py-3 text-center">
                  <small class="text-danger" *ngIf="errorMessage">
                    {{ errorMessage }}
                  </small>
                </div>
                <div class="form-group">
                  <label for="login-pass" class="form-label">Email</label>
                  <div class="input-group mb-3">
                    <input
                      #email
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Enter your email"
                      autocomplete
                      formControlName="email" />
                    <small
                      *ngIf="
                        this.forgotPasswordForm.controls['email'].dirty &&
                        this.forgotPasswordForm.controls['email'].invalid
                      "
                      class="small text-danger w-100"
                      role="alert">
                      Invalid email address
                    </small>
                  </div>
                </div>

                <button
                  class="btn btn-primary mt-1 mx-auto"
                  type="submit"
                  [disabled]="isLoading">
                  <div
                    *ngIf="isLoading"
                    class="spinner-border spinner-border-sm mr-2"
                    role="status"></div>
                  <b> {{ buttonActionLabel }} </b>
                </button>
                <div class="mt-3 text-center" *ngIf="resendEmailUrl">
                  <p class="">
                    Didn't recieve email?
                    <a [href]="resendEmailUrl" class="no-dec">
                      Click to resend
                    </a>
                  </p>
                </div>
              </form>
              <div class="text-center mt-3">
                <button
                  class="back-btn btn btn-default w-auto"
                  (click)="goBack.emit()">
                  <i aria-hidden="true" class="fa fa-arrow-left pe-2"></i>
                  Back to login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
