import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class HttpResponseErrorsInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        next: (event: HttpEvent<unknown>) => {
          if (event instanceof HttpResponse) {
            if (event.status == 401) {
              this.gotToUnauthorizedPage();
            }
          }
          return event;
        },
        error: error => {
          if (error.status == 401) {
            this.gotToUnauthorizedPage();
          }
        },
      })
    );
  }

  private gotToUnauthorizedPage(): void {
    this.router.navigate(['/unauthorised']);
  }

  private gotToMissingResourcePage(): void {
    this.router.navigate(['/404']);
  }
}
