import { UserRolesEnum } from '../models/users/user-roles.model';
import { ITabLinks } from '../models/tablinks.interface';

/**
 *  Interface for listed Tab Links on manage users component
 */
export interface IDefaultManageUsersTabLinks {
  users: ITabLinks;
  changeRequests: ITabLinks;
}

/**
 * Configuration list of tabs and their fragments for the manage users component
 */
export const DefaultManageUsersTabLinks: IDefaultManageUsersTabLinks = {
  users: {
    title: 'Users',
    fragment: 'users',
    allowedRoles: [
      UserRolesEnum.ROLE_SUPER_ADMIN,
      UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
      UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
    ],
  },
  changeRequests: {
    title: 'Requests',
    fragment: 'change-requests',
    allowedRoles: [
      UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
      UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
    ],
  },
};
