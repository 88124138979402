import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { IIrembogovBasicLabelKeyPair } from '../../models/irembogov-basic-label-keypair.model';
import { IrembogovSortDirectionsEnum } from '../../models/irembogov-sort-directions.enum';

@Component({
  selector: 'irembogov-sort-options-dropdown',
  templateUrl: './irembogov-sort-options-dropdown.component.html',
  styleUrls: ['./irembogov-sort-options-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IrembogovSortOptionsDropdownComponent<T> {
  @Input() sortFieldOptions: IIrembogovBasicLabelKeyPair<T>[] = [];
  @Input() selectedSortField?: IIrembogovBasicLabelKeyPair<T>;
  @Input() sortDirection: IrembogovSortDirectionsEnum =
    IrembogovSortDirectionsEnum.ASC;
  @Output() OnSelectSortField: EventEmitter<IIrembogovBasicLabelKeyPair<T>> =
    new EventEmitter<IIrembogovBasicLabelKeyPair<T>>();
  @Output() OnChangeSortDirection: EventEmitter<IrembogovSortDirectionsEnum> =
    new EventEmitter<IrembogovSortDirectionsEnum>();

  IrembogovSortDirectionsEnum = IrembogovSortDirectionsEnum;

  selectSortField(sortField: IIrembogovBasicLabelKeyPair<T>): void {
    this.selectedSortField = sortField;
    this.OnSelectSortField.emit(this.selectedSortField);
  }

  toggleSortDirection() {
    if (this.sortDirection === IrembogovSortDirectionsEnum.ASC) {
      this.sortDirection = IrembogovSortDirectionsEnum.DESC;
    } else {
      this.sortDirection = IrembogovSortDirectionsEnum.ASC;
    }
    this.OnChangeSortDirection.emit(this.sortDirection);
  }
}
