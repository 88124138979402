import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IIrembogovBasicLabelKeyPair } from '../../models/irembogov-basic-label-keypair.model';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
  selector: 'irembogov-custom-table-wrapper',
  templateUrl: './irembogov-table-wrapper.component.html',
  styleUrls: ['./irembogov-table-wrapper.component.scss'],
})
export class IrembogovTableWrapperComponent implements OnInit, OnDestroy {
  @Input() isSortVisible = false;
  @Input() isSeachVisible = false;
  @Input() sortFieldOptions?: IIrembogovBasicLabelKeyPair<
    string | Record<string, string>
  >[];
  @Input() tableTitle?: string;
  @Input() loading?: boolean = false;
  @Input() paginationVisible = false;
  @Input() paginationInsideTable = false;
  @Input() totalItems = 0;
  @Input() pageSizes = [10, 50, 100];
  @Input() itemsPerPage = 0;
  @Input() currentPage = 0;
  @Input() wapperTopPaddingClass = 'pt-4';
  @Input() wapperTopMarginClass = 'mt-4';
  @Input() showDropdown = true;
  @Input() showDirectionText = true;
  @Input() paginationDisplaySize: 'sm' | 'lg' | string | null = null;
  @Input() minimalPagination = false;
  @Input() maxSize = 7;
  @Input() searchPlaceholder = 'Search';
  @Input() roundedTable?: boolean = false;
  @Input() shimmerTableCount = 10;

  @Output() paginationChange = new EventEmitter();
  @Output() sortChange = new EventEmitter();
  @Output() searchChange = new EventEmitter();

  searchFieldControl: FormControl = new FormControl('');
  shimmerTableSize = Array.from(Array(10).keys());
  selectedSortField?: IIrembogovBasicLabelKeyPair<
    string | Record<string, string>
  >;
  private unsubscribe$: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.searchFieldControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe({
        next: (value: string) => {
          this.searchChange.emit(value);
        },
      });
    this.shimmerTableSize = Array.from(Array(this.shimmerTableCount).keys());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onPageChange(value: unknown) {
    this.paginationChange.emit(value);
  }

  onSortChange(
    sortField: IIrembogovBasicLabelKeyPair<string | Record<string, string>>
  ): void {
    this.selectedSortField = sortField;
    this.sortChange.emit(sortField.key);
  }
}
