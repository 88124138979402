import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'irembogov-offcanvas',
  templateUrl: './irembo-offcanvas.component.html',
  styleUrls: ['./irembo-offcanvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class IremboOffcanvasComponent implements OnChanges, OnInit {
  @Input() title = '';
  @Input() description = '';
  @Input() paneOpen = false;
  @Input() wrapClass = 'off-canvas-wrap';
  @Input() roundedWrapContent = false;
  @Input() backdrop: boolean | 'static' = 'static';
  @Input() position: 'start' | 'end' = 'end';
  @Input() useLargePane = false;
  @Input() bgColor: 'default' | 'white' = 'default';
  @Output() OnPaneToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ContentChild(TemplateRef) contentNgTemplate!: TemplateRef<
    Record<string, string>
  >;

  @ViewChild('content', { static: true }) content!: ElementRef;

  offCanvasConfig: NgbOffcanvasOptions = {
    position: this.position,
    backdrop: this.backdrop,
    ariaLabelledBy: 'irembo-offcanvas-title',
    backdropClass: 'irembo-rightflyout-wrapper-bg',
    panelClass: `irembo-sidepane`,
  };

  constructor(private offcanvasService: NgbOffcanvas) {}

  ngOnInit(): void {
    if (this.useLargePane) {
      this.offCanvasConfig.panelClass = `irembo-sidepane ${
        this.bgColor === 'white' ? 'bg-white' : ''
      } side-pane-custom-width`;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['paneOpen']) {
      changes['paneOpen'].currentValue ? this.open() : this.closePane();
    }
  }

  open() {
    this.offcanvasService.open(this.content, this.offCanvasConfig).result.then(
      () => {
        this.paneOpen = false;
        this.OnPaneToggle.emit(this.paneOpen);
      },
      () => {
        this.paneOpen = false;
        this.OnPaneToggle.emit(this.paneOpen);
      }
    );
  }

  private closePane() {
    this.paneOpen = false;
    this.OnPaneToggle.emit(this.paneOpen);
    this.offcanvasService.dismiss();
  }
}
