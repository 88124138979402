import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { INavbarActionItem } from '../../models/irembogov-navbar-action-item.model';
import { INavbarItem } from '../../models/irembogov-navbar-item.model';

@Component({
  selector: 'irembogov-navbar',
  templateUrl: './irembogov-navbar.component.html',
  styleUrls: ['./irembogov-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IrembogovNavbarComponent {
  @Input() navbarItems: INavbarItem[] = [];
  @Input() notifications?: any[] = [];
  @Input() navbarActions: INavbarActionItem[] = [];
  @Input() navbarWide = true;
  @Input() showNotification = true;
  @Input() showActiveRole = false;
  @Input() activeRole = '';
  @Output() actionClick: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router) {}

  getQueryParams(queryParams: string | Record<string, unknown> | undefined) {
    if (queryParams === undefined || typeof queryParams === 'string') {
      return null;
    }
    return queryParams;
  }

  getNavItemExactRouteCheck(item: INavbarItem): boolean {
    if (item.exactRouteAsActive) {
      return item.exactRouteAsActive;
    }

    return true;
  }
}
