import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'irembogov-auth-action-successful',
  templateUrl: './auth-action-successful.component.html',
  styleUrls: ['./auth-action-successful.component.scss'],
})
export class AuthActionSuccessfulComponent {
  @Input() title = '';
  @Input() description = '';
  @Input() buttonActionLabel = 'Log in';
  @Input() continue = '';
  @Input() showLogo = true;
  @Input() showResendButton = false;

  constructor(private router: Router) {}

  nav(str?: string) {
    this.router.navigate([str || '/']).catch(err => {
      this.description = err;
    });
  }
}
