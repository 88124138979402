import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'irembogov-table-inline-user-info',
  templateUrl: './table-inline-user-info.component.html',
  styleUrls: ['./table-inline-user-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableInlineUserInfoComponent {
  @Input() name? = '';
  @Input() nameAsUpperCase = false;
  @Input() imgurl? = '';
  @Input() email? = '';
  @Input() phone? = '';
  @Input() date? = '';
  @Input() showThumbnail = true;
  @Input() namePointerCursor = true;
  @Input() nameActiveLink = true;
  @Input() emailPointerCursor = true;
  @Input() emailActiveLink = false;
  @Input() showIcons = false;
  @Input() wrapClass = 'inline-user-info-wrapper';

  @Output() OnClickUserName: EventEmitter<boolean> = new EventEmitter();
  @Output() OnClickEmail: EventEmitter<boolean> = new EventEmitter();

  isImgUrlBroken = false;

  onImgUrlBroken() {
    this.isImgUrlBroken = true;
  }
}
