import { createUserRolesEnumValuePair } from '../../utils/authUtils';
import { IUserRolesEnumValuePair, UserRolesEnum } from './user-roles.model';
import { ApproveChangeRequestStatusEnum } from '../approve-change-request-status.enum.model';
import { EChangeRequestStatus } from '../change-request-status.enum';
import { RequestTypeEnum } from '../request-type.enum.model';
import { RolesEnum } from '@irembo-andela/irembogov3-common';

export default interface IIremboUser extends IIremboBaseUser {
  id?: string;
  roles: Array<string>;
  enabled: boolean;
  emailVerified?: boolean;
  dateCreated?: number;
  addedBy?: IIremboBaseUser;
  username?: string;
  phone?: string;
  jobPosition?: string;
  profileImageUrl?: string;
  lastActiveDate?: number;
  primaryRole: IUserRolesEnumValuePair;
  selectedInView?: boolean;
  profilePicture?: {
    contentType: 'string';
    file: ['string'];
  };
}

export interface IIremboBaseUser {
  firstName: string;
  lastName: string;
  email: string;
}

export const _BLANK_PROFILE: IIremboUser = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  phone: '',
  roles: [],
  enabled: false,
  primaryRole: createUserRolesEnumValuePair(UserRolesEnum._UNKNOWN_ROLE_),
};

export interface IIremboBaseUserProfilePicture {
  contentType: string;
  file: string[];
}

export interface IIremboUserManagers {
  status?: boolean;
  message?: string;
  responseCode?: string;
  timestamp?: string;
  data?: Array<IremboManagersData>;
}

export interface IremboManagersData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  jobPosition: string;
  profileImageUrl: string;
  status: string;
  createdOn: string;
  updatedOn: string;
  createdBy: string;
  updatedBy: string;
  roles: Array<string>;
  selectedInView?: boolean;
  enabled: boolean;
  primaryRole: IUserRolesEnumValuePair;
  phoneNumber: string;
  profilePictureLink: string;
}

export interface IremboClientUsersData extends IremboManagersData {
  id: string;
  version: number;
  createdDate: string;
  createdById: string;
  lastModifiedDate: string;
  lastModifiedById: string;
  deleted: boolean;
  requestStatus: EChangeRequestStatus;
  fullName: string;
  role: UserRolesEnum;
  email: string;
  organizationId: string;
  processedById: string;
  certificateDocument: string;
  isRejecting: boolean;
  isApproving: boolean;
}

export interface IremboAdminsUsersData extends IremboManagersData {
  userId: string;
  roleId: string;
  userAccount: {
    id: string;
    email: string;
    enabled: boolean;
    emailVerified: boolean;
    firstName: string;
    lastName: string;
    username: string;
    jobPosition: string;
    profilePictureLink: string;
    createdBy: string;
    createdOn: string;
    updatedBy: string;
    updatedOn: string;
    phoneNumber: string;
    userRoles: string;
  };
  role: {
    id: string;
    clientRole: boolean;
    name: UserRolesEnum;
    client: {
      id: string;
      clientId: string;
    };
    usersInRole: Array<any>;
  };
  scopeType: string;
  scopeId: string;
}

export interface IremboClientUsersPostData {
  firstName: string;
  lastName: string;
  email: string;
  requestType: RequestTypeEnum;
  certifiedDocument?: string;
}

export interface ApproveUserRequest {
  id: string;
  actionRequest: ApproveChangeRequestStatusEnum;
  comment: string;
}
