<irembogov-button-group
  [itemList]="roleFilterButtons"
  [selectedItem]="(_filter | async)?.role?.toString()"
  (itemChange)="updateFilter({ role: $event, page: 0, size: 10 })">
</irembogov-button-group>
<irembogov-custom-table-wrapper
  [isSeachVisible]="false"
  [isSortVisible]="true"
  [sortFieldOptions]="sortFieldOptions"
  [loading]="isLoadingUserList"
  [paginationVisible]="true"
  [totalItems]="collectionSize"
  [itemsPerPage]="(_filter | async)?.size || 10"
  [currentPage]="(_filter | async)?.page || 0"
  (paginationChange)="
    updateFilter({ page: $event.pageNumber - 1, size: $event.pageSize })
  "
  (sortChange)="
    updateFilter({ sort: $event.sort, sortDirection: $event.sortDirection })
  ">
  <table body aria-label="Irembo Agency Table" class="table table-hover">
    <thead>
      <tr>
        <th scope="col">First Name</th>
        <th scope="col">Email</th>
        <th scope="col">Date Added</th>
        <th scope="col">Status</th>
        <th scope="col" *ngIf="!checkIfCanApproveRequests()">&nbsp;</th>
      </tr>
    </thead>
    <tbody class="rounded bg-white">
      <tr *ngFor="let changeRequest of usersData; let index = index">
        <td>
          <strong class="d-block"
            >{{ changeRequest.firstName }} {{ changeRequest.lastName }}</strong
          >
        </td>
        <td>
          {{ changeRequest.email }}
        </td>
        <td>{{ (changeRequest.createdDate | date) || 'N/A' }}</td>
        <td class="text-nowrap status">
          <span
            [ngClass]="
              changeRequest.requestStatus === 'REJECTED'
                ? 'badge bg-danger'
                : changeRequest.requestStatus === 'CREATED'
                ? 'badge bg-warning'
                : 'badge bg-success'
            ">
            {{
              changeRequest.requestStatus === 'CREATED'
                ? 'CREATED'
                : changeRequest.requestStatus === 'REJECTED'
                ? 'REJECTED'
                : 'APPROVED'
            }}
          </span>
        </td>

        <td *ngIf="!checkIfCanApproveRequests()">
          <irembogov-client-manager-change-request-form
            [roleType]="(_filter | async)?.role?.toString()"
            (OnCompleteProcessOnChangeRequest)="updateRequestList()"
            [changeRequest]="changeRequest">
          </irembogov-client-manager-change-request-form>
        </td>
      </tr>
    </tbody>
  </table>
</irembogov-custom-table-wrapper>
