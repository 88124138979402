import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import {
  FilterFieldsToQueryParamsBuilder,
  IHttpPagedResponse,
  IFilterAndPaginationFieldsBase,
  IIrembogovBasicLabelKeyPair,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { IremboClientUsersData } from '../../../../core/models/users/irembo-user-model';
import { ExtractHttpErrorMessage } from '../../../../core/utils/http-error-message-extractor.util';
import { ClientService } from '../../../../core/services/client.service';
import { UserRolesEnum } from '../../../../core/models/users/user-roles.model';
import { RoleEnumToLabelUtil } from '../../../../core/utils/authUtils';

interface IFilterFields extends IFilterAndPaginationFieldsBase {
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
  role: UserRolesEnum | string | null;
}

type SidePaneViewType = 'CREATE_USER' | 'VIEW_USER';

const ClientManagerList = [
  {
    label: 'Service Manager',
    role: 'Service Manager',
    roleName: 'ROLE_SERVICE_MANAGER',
  },
  { label: 'QA reviewer', role: 'QA reviewer', roleName: 'ROLE_QA_REVIEWER' },
];

@Component({
  selector: 'irembogov-client-manager-change-request-list',
  templateUrl: './client-manager-change-request-list.component.html',
  styleUrls: ['./client-manager-change-request-list.component.scss'],
})
export class ClientManagerChangeRequestListComponent
  implements OnInit, OnDestroy
{
  userForViewPane!: IremboClientUsersData;
  userProfile!: unknown;
  isSidePaneOpen = false;
  isLoadingUserList = false;
  sortOptions: IIrembogovBasicLabelKeyPair<Record<string, string>>[] = [
    {
      label: 'Date Created, Descending',
      key: { sortBy: 'dateCreated', sortDirection: 'DESC' },
    },
    {
      label: 'Date Created, Ascending',
      key: { sortBy: 'dateCreated', sortDirection: 'ASC' },
    },
  ];
  collectionSize = 0;
  usersList: IremboClientUsersData[] = [];
  sidePaneViewType: SidePaneViewType;
  private unSubscriber$ = new Subject<void>();
  _filterObject: BehaviorSubject<IFilterFields>;

  roleFilterButtons = ClientManagerList?.map(i => ({
    label: i.label,
    key: i.role?.toString() || '',
  }));

  constructor(
    private clientService: ClientService,
    private toastService: ToastService
  ) {
    this.sidePaneViewType = 'CREATE_USER';

    this._filterObject = new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 10,
      sortBy: 'dateCreated',
      sortDirection: 'DESC',
      role: ClientManagerList[0].role,
    });
  }

  ngOnInit(): void {
    this.subscribeToQueryObject();
  }

  ngOnDestroy(): void {
    this.unSubscriber$.next();
    this.unSubscriber$.complete();
  }

  subscribeToQueryObject() {
    this._filterObject
      .pipe(takeUntil(this.unSubscriber$))
      .subscribe((filter: IFilterFields) => {
        this.getClientManagerChangeRequest(filter);
      });
  }

  updateFilter(keyPair: Partial<IFilterFields>) {
    this._filterObject.next({
      ...this._filterObject.getValue(),
      ...keyPair,
    });
  }

  private getClientManagerChangeRequest(filters: IFilterFields): void {
    this.isLoadingUserList = true;
    const roleType = this.CovertClientManagerListToRole(
      filters.role ? filters.role?.toString() : ''
    );

    const filterObj: Record<string, unknown> = { ...filters };
    delete filterObj?.['role'];
    const queryParams: string =
      FilterFieldsToQueryParamsBuilder<Record<string, unknown>>(filterObj);
    this.clientService
      .getChangeRequest(roleType, queryParams)
      .pipe(finalize(() => (this.isLoadingUserList = false)))
      .subscribe({
        next: (response: IHttpPagedResponse<IremboClientUsersData>) => {
          this.usersList = response.data.content;
          this.collectionSize =
            response?.data?.totalPages * response?.data?.numberOfElements;
          console.log('collectionSize', this.collectionSize);
        },
        error: err => {
          const errorMessage = ExtractHttpErrorMessage(
            err,
            'Failed to fetch change request.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  CovertClientManagerListToRole(role: any) {
    if (role == 'QA reviewer') {
      return 'ROLE_QA_REVIEWER';
    } else {
      return 'ROLE_SERVICE_MANAGER';
    }
  }

  updateSidePaneToggleState(
    openState: boolean,
    sidePaneViewType: SidePaneViewType
  ) {
    this.isSidePaneOpen = openState;
    this.sidePaneViewType = sidePaneViewType;
  }

  setUserForViewPane(user: IremboClientUsersData) {
    this.userForViewPane = { ...user };
    this.updateSidePaneToggleState(true, 'VIEW_USER');
  }

  getRoleLabel(role: UserRolesEnum): string {
    return RoleEnumToLabelUtil(role);
  }
}
