import { BehaviorSubject } from 'rxjs';

export const FilterFieldsToQueryParamsBuilder = <
  T extends Record<keyof T, unknown>
>(
  filterFields: T
): string => {
  let queryItems: Array<string | null> = [];
  Object.keys(filterFields).forEach(key => {
    const value: unknown | unknown[] = filterFields[key as keyof T];
    if (Array.isArray(value)) {
      queryItems = queryItems.concat(
        value.map((subValue: unknown) => getParamKeyValue(key, subValue))
      );
    } else {
      queryItems.push(getParamKeyValue(key, value));
    }
  });
  return queryItems.filter(item => (item ? true : false)).join('&');
};

const getParamKeyValue = (key: unknown, value: unknown): string | null => {
  if (
    value !== undefined &&
    value !== null &&
    value !== '' &&
    value !== '_UNKNOWN_'
  ) {
    return `${key}=${value}`;
  }
  return null;
};

export function updateApiFilter<T>(
  _filterObject: BehaviorSubject<T>,
  keyPair: Partial<T>
) {
  _filterObject.next({
    ..._filterObject.getValue(),
    ...keyPair,
  });
}

export function setSelectedAPISortOption(
  sortOptions: {
    label: string;
    value: string;
  }[],
  selectedSortOptionValue: string
) {
  return (
    sortOptions.find(
      sortOption => sortOption?.value === selectedSortOptionValue
    )?.label || ''
  );
}
