import { Component } from '@angular/core';
import { IremboPaginationComponent } from '../irembo-pagination/irembo-pagination.component';

@Component({
  selector: 'irembogov-custom-pagination',
  templateUrl: './irembo-custom-pagination.component.html',
  styleUrls: ['./irembo-custom-pagination.component.scss'],
})
export class IremboCustomPaginationComponent extends IremboPaginationComponent {
  nextPage() {
    this.pageChange(this.page + 1);
  }

  previousPage() {
    this.pageChange(this.page - 1);
  }
}
