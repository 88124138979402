import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import { IHttpPagedResponse } from '@irembo-andela/irembogov3-common';
import { IremboClientUsersData } from '../../../../core/models/users/irembo-user-model';
import {
  IFilterAndPaginationFieldsBase,
  IIrembogovBasicLabelKeyPair,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { ExtractHttpErrorMessage } from '../../../../core/utils/http-error-message-extractor.util';
import { ClientService } from '../../../../core/services/client.service';

interface IFilterFields extends IFilterAndPaginationFieldsBase {
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
  role: string;
}
type SidePaneViewType = 'CREATE_USER' | 'VIEW_USER';

const ClientManagerList = [
  {
    label: 'Service Manager',
    role: 'Service Manager',
    roleName: 'ROLE_SERVICE_MANAGER',
  },
  { label: 'QA reviewer', role: 'QA reviewer', roleName: 'ROLE_QA_REVIEWER' },
];

@Component({
  selector: 'irembogov-client-manager-users-list',
  templateUrl: './client-manager-users-list.component.html',
  styleUrls: ['./client-manager-users-list.component.scss'],
})
export class ClientManagerUsersListComponent implements OnInit, OnDestroy {
  userForViewPane!: IremboClientUsersData;
  userProfile!: unknown;
  isSidePaneOpen = false;
  isLoadingUserList = false;
  roleTab = 'QA reviewer';
  sortOptions: IIrembogovBasicLabelKeyPair<Record<string, string>>[] = [
    {
      label: 'Date Created, Descending',
      key: { sortBy: 'createdOn', sortDirection: 'DESC' },
    },
    {
      label: 'Date Created, Ascending',
      key: { sortBy: 'createdOn', sortDirection: 'ASC' },
    },
  ];
  page = 0;
  pageSize = 10;
  collectionSize = 0;
  usersList?: IHttpPagedResponse<IremboClientUsersData>;
  roleFilterButtons = ClientManagerList?.map(i => ({
    label: i.label,
    key: i.role?.toString() || '',
  }));

  sidePaneViewType: SidePaneViewType;

  private unSubscriber$ = new Subject<void>();
  _filterObject: BehaviorSubject<IFilterFields>;

  constructor(
    private clientService: ClientService,
    private toastService: ToastService
  ) {
    this.sidePaneViewType = 'CREATE_USER';

    this._filterObject = new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 10,
      sortBy: 'createdOn',
      sortDirection: 'ASC',
      role: ClientManagerList[0].role,
    });
  }

  ngOnInit(): void {
    this.subscribeToQueryObject();
  }

  ngOnDestroy(): void {
    this.unSubscriber$.next();
    this.unSubscriber$.complete();
  }

  subscribeToQueryObject() {
    this._filterObject
      .pipe(takeUntil(this.unSubscriber$))
      .subscribe((filter: IFilterFields) => {
        this.page = filter.page ? filter.page : 0;
        this.pageSize = filter.size ? filter.size : 10;
        this.getClientManagerUsers(filter);
      });
  }

  updateFilter(keyPair: Partial<IFilterFields>) {
    this._filterObject.next({
      ...this._filterObject.getValue(),
      ...keyPair,
    });
  }

  private refreshList() {
    this.updateFilter({
      page: 0,
      size: 10,
      sortBy: 'createdOn',
      sortDirection: 'ASC',
      role: ClientManagerList[0].roleName,
    });
  }

  private getClientManagerUsers(filters: IFilterFields): void {
    this.isLoadingUserList = true;
    const roleType = this.CovertClientManagerListToRole(
      filters.role ? filters.role?.toString() : ''
    );
    this.roleTab = roleType;

    this.clientService
      .getUsers(
        roleType,
        filters.sortBy,
        filters.sortDirection,
        filters.page || 0,
        filters.size || 10
      )
      .pipe(finalize(() => (this.isLoadingUserList = false)))
      .subscribe({
        next: (response: IHttpPagedResponse<IremboClientUsersData>) => {
          this.usersList = response;
          this.collectionSize =
            this.pageSize * response.data.totalPages > 0
              ? this.pageSize * response.data.totalPages
              : 1;
        },
        error: err => {
          const errorMessage = ExtractHttpErrorMessage(
            err,
            'Failed to fetch users.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  CovertClientManagerListToRole(role: any) {
    if (role == 'QA reviewer') {
      return 'ROLE_QA_REVIEWER';
    } else {
      return 'ROLE_SERVICE_MANAGER';
    }
  }

  onNewUserAdded(invitedServiceManager: IremboClientUsersData) {
    this.isSidePaneOpen = !this.isSidePaneOpen;
    const message = `User ${invitedServiceManager.firstName}  added`;
    this.toastService.show({ body: message, type: 'success' });
    this.updateFilter({ role: this.roleTab });
  }

  updateSidePaneToggleState(
    openState: boolean,
    sidePaneViewType: SidePaneViewType
  ) {
    this.isSidePaneOpen = openState;
    this.sidePaneViewType = sidePaneViewType;
  }
  close(openState: boolean) {
    this.isSidePaneOpen = !openState;
  }

  onNewUserInvited(message: string) {
    this.updateSidePaneToggleState(false, 'CREATE_USER');
    this.toastService.show({ body: message, type: 'success' });
    this.refreshList();
  }

  setUserForViewPane(user: IremboClientUsersData) {
    this.userForViewPane = { ...user };
    this.updateSidePaneToggleState(true, 'VIEW_USER');
  }

  userInfoViewClosed() {
    this.updateSidePaneToggleState(false, 'CREATE_USER');
    this.refreshList();
  }
}
