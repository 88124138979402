import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { IIrembogovBasicLabelKeyPair } from '../../models/irembogov-basic-label-keypair.model';

@Component({
  selector: 'irembogov-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonGroupComponent<T> {
  @Input()
  itemList: IIrembogovBasicLabelKeyPair<T>[] = [];

  @Input()
  selectedItem?: T;

  @Input()
  fullWidth = false;

  @Input()
  iconAboveLabel = false;

  @Input()
  uiStyle: 'rounded-tabs' | 'flat-tabs' = 'rounded-tabs';

  @Input()
  theme: 'default-theme' | 'irembo-blue-theme' = 'default-theme';

  @Output() itemChange: EventEmitter<T> = new EventEmitter<T>();

  selectItem(value: T) {
    this.itemChange.emit(value);
  }
}
