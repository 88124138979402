import {
  IUserRolesEnumValuePair,
  UserRolesEnum,
} from '../models/users/user-roles.model';

export const DEFAULT_ADDITIONAL_IREMBO_USER_ROLES: IUserRolesEnumValuePair[] = [
  {
    label: 'Irembo Admin',
    key: UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
  },
  {
    label: 'Client Manager',
    key: UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
  },
  {
    label: 'Integrations Manager',
    key: UserRolesEnum.ROLE_INTEGRATION_MANAGER,
  },
  {
    label: 'Service Manager',
    key: UserRolesEnum.ROLE_SERVICE_MANAGER,
  },
];

export const DEFAULT_IREMBO_USER_ROLES: IUserRolesEnumValuePair[] = [
  {
    label: 'Irembo Admin',
    key: UserRolesEnum.ROLE_IREMBOGOV_ADMIN,
  },
  {
    label: 'Client Manager',
    key: UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
  },
  {
    label: 'Integrations Manager',
    key: UserRolesEnum.ROLE_INTEGRATION_MANAGER,
  },
  {
    label: 'Service Distribution Manager',
    key: UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
  },
];
