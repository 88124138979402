import { KeycloakProfile } from 'keycloak-js';
import IIremboUser from '../models/users/irembo-user-model';
import {
  IUserRolesEnumValuePair,
  UserRolesEnum,
  adminPortalAllowedRoles,
} from '../models/users/user-roles.model';
import {
  RoleLabelToEnumUtil,
  RolesEnum,
} from '@irembo-andela/irembogov3-common';

export const FindUserPrimaryRole = (roles: string[]): UserRolesEnum => {
  let foundUserRole: UserRolesEnum = UserRolesEnum._UNKNOWN_ROLE_;

  adminPortalAllowedRoles.some((r: string) => {
    if (roles.includes(r)) {
      foundUserRole = UserRolesEnum[r as keyof typeof UserRolesEnum];
      return true;
    }
    return false;
  });
  return foundUserRole;
};

export const ConvertKeycloakToIremboUserProfile = (
  keycloakProfile: KeycloakProfile,
  roles: string[]
): IIremboUser => {
  const primaryRole: UserRolesEnum = FindUserPrimaryRole(roles);
  const otherRoles: string[] = [];
  if (primaryRole !== UserRolesEnum._UNKNOWN_ROLE_) {
    Object.keys(RolesEnum).forEach((role: string) => {
      if (roles.includes(role) && role !== primaryRole.valueOf()) {
        otherRoles.push(role as RolesEnum);
      }
    });
  }

  return {
    firstName: keycloakProfile.firstName || '',
    lastName: keycloakProfile.lastName || '',
    id: keycloakProfile.id,
    email: keycloakProfile.email || '',
    primaryRole: { key: primaryRole, label: RoleEnumToLabelUtil(primaryRole) },
    roles: roles ? [...otherRoles] : [],
    enabled: keycloakProfile.enabled || false,
    emailVerified: keycloakProfile.emailVerified || false,
    dateCreated: keycloakProfile.createdTimestamp || undefined,
    username: keycloakProfile.username || undefined,
  };
};

export const RoleEnumToLabelUtil = (role: UserRolesEnum): string => {
  switch (role) {
    case UserRolesEnum.ROLE_SUPER_ADMIN:
      return 'Super Admin';
    case UserRolesEnum.ROLE_IREMBOGOV_ADMIN:
      return 'IremboGov Admin';
    case UserRolesEnum.ROLE_PARTNERSHIP_MANAGER:
      return 'Client Manager';
    case UserRolesEnum.ROLE_INTEGRATION_MANAGER:
      return 'Integration Manager';
    case UserRolesEnum.ROLE_SERVICE_MANAGER:
      return 'Service Manager';
    case UserRolesEnum.ROLE_QA_REVIEWER:
      return 'QA Reviewer';
    case UserRolesEnum.ROLE_ORGANIZATION_ADMIN:
      return 'Organization Admin';
    case UserRolesEnum.ROLE_OFFICER_ADMIN:
      return 'Officer Admin';
    case UserRolesEnum.ROLE_ORGANIZATION_SUPPORT:
      return 'Organization Support';
    case UserRolesEnum.ROLE_ORGANIZATION_EXECUTIVE:
      return 'Organization Executive';
    case UserRolesEnum.ROLE_OFFICER:
      return 'Officer';
    case UserRolesEnum.ROLE_OFFICER_MANAGER:
      return 'Officer Manager';
    case UserRolesEnum.ROLE_INTEGRATION_OPS:
      return 'Integration Ops';
    case UserRolesEnum.ROLE_GOVERNMENT_EXEC:
      return 'Government Executive';
    case UserRolesEnum.ROLE_CITIZEN:
      return 'Citizen';
    case UserRolesEnum.ROLE_AGENT:
      return 'Agent';
    case UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER:
      return 'Service Distribution Manager';
    default:
      return '';
  }
};

export const createUserRolesEnumValuePair = (
  roleEnum: UserRolesEnum
): IUserRolesEnumValuePair => {
  if (!roleEnum) {
    roleEnum = UserRolesEnum._UNKNOWN_ROLE_;
  }

  const label: string | null = RoleEnumToLabelUtil(roleEnum);
  return {
    key: roleEnum,
    label,
  };
};
