<ng-container
  *ngIf="
    userProfile?.primaryRole?.key === RolesEnum.ROLE_IREMBOGOV_ADMIN.toString()
  ">
  <irembogov-list-users-for-irembogov-admin></irembogov-list-users-for-irembogov-admin>
</ng-container>

<ng-container
  *ngIf="
    userProfile?.primaryRole?.key === RolesEnum.ROLE_SUPER_ADMIN.toString()
  ">
  <irembogov-list-users-for-super-admin></irembogov-list-users-for-super-admin>
</ng-container>

<ng-container
  *ngIf="
    userProfile?.primaryRole?.key ===
    RolesEnum.ROLE_PARTNERSHIP_MANAGER.toString()
  ">
  <irembogov-list-users-for-client-manager></irembogov-list-users-for-client-manager>
</ng-container>
