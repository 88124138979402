<ng-template #confirmModal let-modal>
  <div class="modal-dialog mb-0">
    <div class="container-fluid text-center">
      <div class="col-12 check-icon text-center mb-3 text-center">
        <i
          aria-hidden="true"
          class="fa-solid fa-arrow-right-from-bracket fa-2x"></i>
      </div>
      <h1 class="mb-3" id="confirm-modal-title">{{ title }}</h1>
      <p class="irembo-dark-grey">{{ message }}</p>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button
      type="button"
      class="btn btn-primary"
      aria-label="close"
      (click)="modal.close()">
      {{ denyBtnText }}
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="logout.emit()">
      {{ confirmBtnText }}
    </button>
  </div>
</ng-template>
