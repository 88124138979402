/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  IUiAlertContent,
  ToastService,
  TUiAlertTypes,
} from '@irembo-andela/irembogov3-common';
import { environment } from './../../../../../../environments/environment';
import { ClientService } from './../../../../../core/services/client.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { HttpErrorResponse } from '@angular/common/http';

import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  OnInit,
} from '@angular/core';

import { finalize } from 'rxjs';

@Component({
  selector: 'irembogov-client-manager-inivite-qa-reviewer',
  templateUrl: './client-manager-inivite-qa-reviewer.component.html',
})
export class ClientManagerIniviteQaReviewerComponent implements OnInit {
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() newQAFormSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('errorMsgDiv') errorMsgDiv!: ElementRef;

  formData!: FormData;
  fileInputsRequired = true;

  isLoading = false;
  showFormError = false;
  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };

  addNewQaReviewer: FormGroup = new FormGroup({
    firstName: new FormControl<string>('', [Validators.required]),
    lastName: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(environment.customemailValidationRegex),
      Validators.email,
    ]),
  });

  constructor(
    private clientService: ClientService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.formData = new FormData();
    this.addNewQaReviewer.reset();
  }

  cancelForm() {
    this.isLoading = false;
    this.closeForm.emit(true);
  }

  onCreateNewQAReviewer() {
    if (!this.checkIfFormValid(this.addNewQaReviewer)) {
      return;
    }

    this.updateFormErrorMessageContent(false);
    this.isLoading = true;

    const req: any = {
      firstName: this.addNewQaReviewer.controls['firstName'].value,
      lastName: this.addNewQaReviewer.controls['lastName'].value,
      email: this.addNewQaReviewer.controls['email'].value,
    };

    this.clientService
      .initiateRequestToCreateQAReviewer(req)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.formData = new FormData();
        })
      )
      .subscribe({
        next: () => {
          const newQaReviewer: any = {
            firstName: req.firstName,
          } as any;

          this.newQAFormSubmitted.emit(newQaReviewer);
          this.toastService.show({
            body: `Created new QA manager success!`,
            type: 'success',
          });
        },
        error: (error: HttpErrorResponse) => {
          const errorMessage =
            error.error && error.error.message
              ? error.error.message
              : 'Could not initiate new QA manager';

          this.updateFormErrorMessageContent(
            true,
            'danger',
            'Creating new QA manager failed!',
            errorMessage
          );
        },
      });
  }

  updateFormErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    title: string = '',
    message: string = ''
  ) {
    this.showFormError = show;
    this.formAlertContent = { type, title, message };
    if (show) {
      this.errorMsgDiv.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  private checkIfFormValid(form: FormGroup): boolean {
    if (form.invalid) {
      this.updateFormErrorMessageContent(
        true,
        'danger',
        'Invalid fields!',
        'Please ensure you have filled in all the required fields.'
      );
      return false;
    }
    return true;
  }
}
