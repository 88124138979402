import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { IdleTimerService } from '@irembo-andela/irembogov3-common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'irembogov-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnDestroy {
  idleCountDown = 0;
  idleState!: string;
  _idleState!: Subscription;

  modalOpen = false;

  @ViewChild('logoutWarningContent') logoutWarningModal!: ElementRef;

  constructor(
    private idleTimerService: IdleTimerService,
    private modalService: NgbModal,
    private authService: AuthService
  ) {
    this.authService.showLogOutWarningModal$.subscribe((show: boolean) => {
      if (show) {
        this.openModal(this.logoutWarningModal);
      }
    });

    this.initIdleTimer();

    this._idleState = this.idleTimerService
      .idleTimeOutCountDown()
      .subscribe((countDown: number) => {
        this.idleCountDown = countDown;
      });

    this.idleTimerService.idleState$.subscribe(state => {
      if (state === 'timedOut') {
        this.authService.logOutUser();
      }
    });
  }

  openModal(content: unknown): void {
    if (this.modalOpen) {
      return;
    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        result => {
          this.modalOpen = false;

          if (result === 'logout') {
            this.authService.updateIncompleteProcessState(false);
            this.authService.logOutUser();
            return;
          }
          this.initIdleTimer();
        },
        () => {
          this.modalOpen = false;
          this.initIdleTimer();
        }
      );
  }

  initIdleTimer() {
    this.idleTimerService.initIdleTimer(
      environment.idleTimeOutDuration,
      environment.idleTimeOutCountDown
    );
  }

  ngOnDestroy(): void {
    if (this._idleState) this._idleState.unsubscribe();
  }
}
