<div class="create-agency">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="mt-2 invite-user-form">
              <div #errorMsgDiv>
                <irembogov-irembo-section-message
                  *ngIf="showFormError"
                  [type]="formAlertContent.type"
                  [title]="formAlertContent.title"
                  [message]="
                    formAlertContent.message
                  "></irembogov-irembo-section-message>
              </div>
              <div class="">
                <form [formGroup]="addNewQaReviewer">
                  <div class="form-group">
                    <label for="email" class="form-label required"
                      >User Email</label
                    >
                    <div class="input-group mb-3">
                      <input
                        formControlName="email"
                        type="text"
                        class="form-control"
                        id="email"
                        placeholder="User Email"
                        #email />
                      <div
                        class="form-error w-100 small text-danger"
                        *ngIf="
                          addNewQaReviewer.controls['email'].touched &&
                          !addNewQaReviewer.controls['email'].valid
                        ">
                        User email is required.
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="firstName" class="form-label required">
                      First Name
                    </label>
                    <div class="input-group mb-3">
                      <input
                        formControlName="firstName"
                        type="text"
                        class="form-control"
                        id="firstName"
                        placeholder="User First Name"
                        #firstName />
                      <div
                        class="form-error w-100 small text-danger"
                        *ngIf="
                          addNewQaReviewer.controls['firstName'].touched &&
                          !addNewQaReviewer.controls['firstName'].valid
                        ">
                        first name is required.
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="lastName" class="form-label required">
                      Last Name
                    </label>
                    <div class="input-group mb-3">
                      <input
                        formControlName="lastName"
                        type="text"
                        class="form-control"
                        id="lastName"
                        placeholder="User Last Name"
                        #lastName />
                      <div
                        class="form-error w-100 small text-danger"
                        *ngIf="
                          addNewQaReviewer.controls['lastName'].touched &&
                          !addNewQaReviewer.controls['lastName'].valid
                        ">
                        Last Name is required.
                      </div>
                    </div>
                  </div>

                  <div class="text-end pt-4">
                    <button
                      type="button"
                      [disabled]="isLoading"
                      class="btn btn-transparent text-muted mx-3"
                      (click)="cancelForm()">
                      Cancel
                    </button>
                    <button
                      [disabled]="isLoading"
                      class="btn btn-primary mt-1 mx-auto"
                      type="button"
                      (click)="onCreateNewQAReviewer()">
                      Create
                      <div
                        *ngIf="isLoading"
                        class="spinner-border spinner-border-sm mr-2"
                        role="status"></div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
