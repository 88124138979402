import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleTimerService {
  private idleState: 'idle' | 'countdown' | 'timedOut' | 'pinging' = 'pinging';
  private _idleState: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.idleState
  );
  idleState$: Observable<string> = this._idleState.asObservable();
  private lastPing!: Date | null;

  constructor(private idle: Idle, private keepalive: Keepalive) {}

  initIdleTimer(idleTimeOutDuration: number, idleTimeOutCountDown: number) {
    this.idle.setIdle(idleTimeOutDuration);
    this.idle.setTimeout(idleTimeOutCountDown);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'pinging';
      this._idleState.next(this.idleState);
      this.reset();
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = 'timedOut';
      this._idleState.next(this.idleState);
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = 'idle';
      this._idleState.next(this.idleState);
    });

    // sets the ping interval checkng user activity to 15 seconds
    this.keepalive.interval(15);

    this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

    this.reset();
  }

  idleTimeOutCountDown() {
    return this.idle.onTimeoutWarning.pipe(
      tap(() => {
        this.idleState = 'countdown';
        this._idleState.next(this.idleState);
      })
    );
  }

  reset() {
    this.idle.watch();
    this.idleState = 'pinging';
    this._idleState.next(this.idleState);
  }
}
