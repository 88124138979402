import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import {
  IAuthEngineHttpBaseResponse,
  ICheckUserExistsRequest,
} from '../../interfaces';
import { EUserType } from '../../enums/user-type.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'irembogov-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordResetComponent {
  constructor(private authService: AuthService) {}
  @Input() title: string | undefined;
  @Input() errorMessage: string | undefined;
  @Input() errorTitle: string | undefined;
  @Input() authorizationBaseUrl!: string;
  @Input() authorizationClientId!: string;
  @Input() showErrorTitle = false;
  @Input() description: string | undefined;
  @Input() buttonActionLabel: string | undefined;
  @Input() isLoading = false;
  @Input() resendEmailUrl = '';
  @Input() showBackToLogin = true;
  @Input() accountNotFoundErrorTitle =
    'Sorry, the email you entered does not match any account.';
  @Input() accountNotFoundErrorMessage =
    'Sorry, the email you entered does not match any account. Please check your login details or contact your Account Admin.';
  @Input() accountNotActiveErrorTitle = 'Sorry, your account is inactive.';
  @Input() accountNotActiveErrorMessage =
    'Sorry, your account is inactive. Please contact your Account Admin.';
  @Output() resend: EventEmitter<undefined> = new EventEmitter<undefined>();
  @Output() goBack: EventEmitter<undefined> = new EventEmitter<undefined>();
  @Output() formSubmit: EventEmitter<string> = new EventEmitter<string>();

  resetPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  hasError = false;
  errorTheme: 'danger' | 'warning' = 'danger';

  onFormSubmit() {
    if (this.resetPasswordForm.invalid) {
      this.errorMessage = 'Please fill in your email address';
      return;
    }
    this.checkIfUserExists();
  }

  checkIfUserExists() {
    const email = this.resetPasswordForm.controls['email'].value;
    const req: ICheckUserExistsRequest = {
      username: email || '',
      userType: EUserType.OTHER,
      clientId: this.authorizationClientId,
    };
    this.isLoading = true;
    this.authService.checkUserExists(this.authorizationBaseUrl, req).subscribe({
      next: (res: IAuthEngineHttpBaseResponse<any>) => {
        switch (res.responseCode) {
          case 'ACCOUNT_NOT_FOUND':
            this.setError(
              this.accountNotFoundErrorTitle,
              this.accountNotFoundErrorMessage,
              'danger'
            );
            break;
          case 'ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED':
            this.setError(
              this.accountNotActiveErrorTitle,
              this.accountNotActiveErrorMessage,
              'warning'
            );
            break;
          case 'ACCOUNT_CHECK_FOUND':
            this.hasError = false;
            this.errorMessage = '';
            this.formSubmit.emit(email);
            break;
          default:
            this.setError(
              'An Error Occured',
              'Sorry, an error occurred. Please try again',
              'danger'
            );
        }
        this.isLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.isLoading = false;
        if (err.error.responseCode === 'ACCOUNT_NOT_FOUND') {
          this.setError(
            this.accountNotFoundErrorTitle,
            this.accountNotFoundErrorMessage,
            'danger'
          );
        } else {
          this.setError(
            'An Error Occured',
            'Sorry, an error occurred. Please try again',
            'danger'
          );
        }
      },
    });
  }

  setError(title: string, message: string, theme: 'danger' | 'warning') {
    this.hasError = true;
    this.errorTheme = theme;
    this.errorMessage = message;
    this.errorTitle = title;
  }
}
