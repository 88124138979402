<ng-container *ngIf="checkIfApproved()">
  <span *ngIf="canCreateRequest">
    <button
      class="btn btn-sm btn-outline-danger me-2"
      [disabled]="isLoadingProcess"
      (click)="initiateChangeRequestDeactivated()">
      <i class="fa-regular fa-circle-check"></i>
      Deactivate
      <div
        *ngIf="isDeactivatingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
  </span>

  <span
    *ngIf="!canCreateRequest"
    [class]="'badge ' + getUserStatusLabel(changeRequest.requestStatus).class">
    <i [class]="getUserStatusLabel(changeRequest.requestStatus).icon"></i>
    {{ getUserStatusLabel(changeRequest.requestStatus).label }}
  </span>
</ng-container>

<!--REACTIVATED-->
<ng-container *ngIf="checkIfReactivated()">
  <span *ngIf="canCreateRequest">
    <button
      class="btn btn-sm btn-outline-danger me-2"
      [disabled]="isLoadingProcess"
      (click)="initiateChangeRequestDeactivated()">
      <i class="fa-regular fa-circle-check"></i>
      Deactivate
      <div
        *ngIf="isDeactivatingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
  </span>

  <span
    *ngIf="!canCreateRequest"
    [class]="'badge ' + getUserStatusLabel(changeRequest.requestStatus).class">
    <i
      [class]="
        'badge ' + getUserStatusLabel(changeRequest.requestStatus).icon
      "></i>
    {{ getUserStatusLabel(changeRequest.requestStatus).label }}
  </span>
</ng-container>

<!--DEACTIVATED-->
<ng-container *ngIf="checkIfDeactivated()">
  <span *ngIf="canCreateRequest">
    <button
      class="btn btn-sm btn-outline-success me-2"
      [disabled]="isLoadingProcess"
      (click)="initiateChangeRequestReactivated()">
      <i class="fa-regular fa-circle-check"></i>
      Activate
      <div
        *ngIf="isActivatingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
  </span>

  <span
    *ngIf="!canCreateRequest"
    [class]="'badge ' + getUserStatusLabel(changeRequest.requestStatus).class">
    <i
      [class]="
        'badge ' + getUserStatusLabel(changeRequest.requestStatus).icon
      "></i>
    {{ getUserStatusLabel(changeRequest.requestStatus).label }}
  </span>
</ng-container>

<!--PENDING APPROVAL-->
<ng-container *ngIf="checkIfPendingApproval()">
  <span *ngIf="canApproveRequest">
    <button
      class="btn btn-sm btn-outline-success me-2"
      [disabled]="isLoadingProcess"
      (click)="approveChangeRequest()">
      <i class="fa-regular fa-circle-check"></i>
      Approve
      <div
        *ngIf="isApprovingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
    <button
      class="btn btn-sm btn-outline-danger me-2"
      [disabled]="isLoadingProcess"
      (click)="rejectChangeRequest()">
      <i class="fa-regular fa-circle-check"></i>
      Reject
      <div
        *ngIf="isRejectingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
  </span>

  <span
    *ngIf="!canApproveRequest"
    [class]="'badge ' + getUserStatusLabel(changeRequest.requestStatus).class">
    <i [class]="getUserStatusLabel(changeRequest.requestStatus).icon"></i>
    {{ getUserStatusLabel(changeRequest.requestStatus).label }}
  </span>
</ng-container>

<!--PENDING DEACTIVATION-->
<ng-container *ngIf="checkIfPendingDeactivation()">
  <span *ngIf="canApproveRequest">
    <button
      class="btn btn-sm btn-outline-warning me-2"
      [disabled]="isLoadingProcess"
      (click)="deactivateChangeRequest()">
      <i class="fa-regular fa-circle-check"></i>
      Deactivate
      <div
        *ngIf="isDeactivatingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
    <button
      class="btn btn-sm btn-outline-danger me-2"
      [disabled]="isLoadingProcess"
      (click)="rejectDeactivationChangeRequest()">
      <i class="fa-regular fa-circle-check"></i>
      Reject Deactivation
      <div
        *ngIf="isRejectingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
  </span>

  <span
    *ngIf="!canApproveRequest"
    [class]="'badge ' + getUserStatusLabel(changeRequest.requestStatus).class">
    <i [class]="getUserStatusLabel(changeRequest.requestStatus).icon"></i>
    {{ getUserStatusLabel(changeRequest.requestStatus).label }}
  </span>
</ng-container>

<!--PENDING REACTIVATION-->
<ng-container *ngIf="checkIfPendingReactivation()">
  <span *ngIf="canApproveRequest">
    <button
      class="btn btn-sm btn-outline-success me-2"
      [disabled]="isLoadingProcess"
      (click)="reactivateChangeRequest()">
      <i class="fa-regular fa-circle-check"></i>
      Reactivate
      <div
        *ngIf="isActivatingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
    <button
      class="btn btn-sm btn-outline-danger me-2"
      [disabled]="isLoadingProcess"
      (click)="rejectReactivationChangeRequest()">
      <i class="fa-regular fa-circle-check"></i>
      Reject Reactivation
      <div
        *ngIf="isRejectingProcess"
        class="spinner-border spinner-border-sm mr-2"
        role="status"></div>
    </button>
  </span>

  <span
    *ngIf="!canApproveRequest"
    [class]="'badge ' + getUserStatusLabel(changeRequest.requestStatus).class">
    <i [class]="getUserStatusLabel(changeRequest.requestStatus).icon"></i>
    {{ getUserStatusLabel(changeRequest.requestStatus).label }}
  </span>
</ng-container>

<ng-container *ngIf="checkIfOtherStates()">
  <span
    [class]="'badge ' + getUserStatusLabel(changeRequest.requestStatus).class">
    <i
      [class]="
        'badge ' + getUserStatusLabel(changeRequest.requestStatus).icon
      "></i>
    {{ getUserStatusLabel(changeRequest.requestStatus).label }}
  </span>
</ng-container>
