import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppComponent } from './app.component';
import { NxWelcomeComponent } from './nx-welcome.component';
import { AppRoutingModule } from './app-routing.module';
import {
  ClientDetailsInterceptor,
  SatHeaderInterceptor,
  initializeKeycloak,
  MockedKeycloakService,
} from '@irembo-andela/irembogov3-common';
import { environment } from '../environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './components/dashboard-component/dashboard.component';
import { HomeComponent } from './components/home-component/home.component';
import { MainNavigationBarComponent } from './components/main-navigation-bar/main-navigation-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RouterModule } from '@angular/router';
import { Error401UnauthorizedComponent } from './components/http-errors/error-401-unauthorized/error-401.component';
import { UiModule } from '@irembo-andela/irembogov3-common';
import { SharedModule } from './modules/shared-module/shared.module';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersModule } from './modules/users-module/users.module';
import { HttpResponseErrorsInterceptor } from './core/interceptors/http-response-errors.interceptor';
import { Error404MissingResourceComponent } from './components/http-errors/error-404-missing-resource/error-404.component';
import { AddHeaderInterceptor } from './core/interceptors/add-header-interceptor.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AppComponent,
    NxWelcomeComponent,
    DashboardComponent,
    HomeComponent,
    Error401UnauthorizedComponent,
    Error404MissingResourceComponent,
    MainNavigationBarComponent,
    UserProfileComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    SharedModule,
    UsersModule,
    NgbModule,
    RouterModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot(),
    TranslateModule.forRoot(),
  ],
  providers: [
    {
      provide: KeycloakService,
      useClass: environment.enablekeycloakMockService
        ? MockedKeycloakService
        : KeycloakService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (keycloakService: KeycloakService) => {
        return initializeKeycloak(keycloakService, {
          url: environment.authServiceUrl,
          realm: environment.authRealm,
          clientId: environment.authClientId,
          redirectUri: environment.authSuccessRedirectUrl,
        });
      },
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientDetailsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SatHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
