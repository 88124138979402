<div class="irembogov-list-users">
  <irembogov-button-group
    [itemList]="roleFilterButtons"
    [selectedItem]="(_filterObject | async)?.role?.toString()"
    (itemChange)="updateFilter({ role: $event })">
  </irembogov-button-group>
  <irembogov-custom-table-wrapper
    [isSeachVisible]="false"
    [isSortVisible]="true"
    [sortFieldOptions]="sortOptions"
    [loading]="isLoadingUserList"
    [paginationVisible]="true"
    [totalItems]="collectionSize"
    [itemsPerPage]="(_filterObject | async)?.size || 10"
    [currentPage]="(_filterObject | async)?.page || 0"
    (paginationChange)="
      updateFilter({ page: $event?.pageNumber - 1, size: $event.pageSize })
    "
    (sortChange)="
      updateFilter({
        sortBy: $event.sortBy,
        sortDirection: $event.sortDirection
      })
    ">
    <table
      body
      class="table table-hover"
      aria-label="Irembo Users Table"
      class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Role</th>
          <th scope="col">Status</th>
          <th scope="col">Date added</th>
          <th scope="col">&nbsp;</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody class="text-centre rounded bg-white">
        <tr *ngFor="let user of usersList; let index = index">
          <td>
            <irembogov-table-inline-user-info
              (OnOnClickUserName)="setUserForViewPane(user)"
              [name]="user.firstName + ' ' + user.lastName"
              [emailActiveLink]="true"
              [namePointerCursor]="false"
              [nameActiveLink]="false"
              [emailPointerCursor]="true"
              email="{{ user.email }}">
            </irembogov-table-inline-user-info>
          </td>
          <td>
            <span class="badge bg-info">
              {{ getRoleLabel(user.role) }}
            </span>
          </td>
          <td>{{ user.requestStatus }}</td>

          <td>{{ (user.createdDate | date) || 'N/A' }}</td>
          <td></td>
          <td>
            <button
              class="btn btn-sm btn-outline-gray border"
              (click)="setUserForViewPane(user)">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </irembogov-custom-table-wrapper>
</div>
