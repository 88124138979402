import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ExtractHttpErrorResponseCode,
  ExtractHttpErrorResponseMessage,
} from '../../../../utils/utils/http-error-response-extractor.util';
import { finalize } from 'rxjs';
import { ERecipientTypeEnum } from '../../../enums/recipient-type.enum';
import { EUserType } from '../../../enums/user-type.enum';
import {
  IAuthEngineHttpBaseResponse,
  ICheckPasswordResetOtpTokenResponseData,
  IResetPasswordRequest,
  IRequestPasswordResetTokenRequest,
} from '../../../interfaces';
import { AuthService } from '../../../services/auth.service';
import { IremboPasswordRegExpErrorMsg } from '../../../utils/auth.utils';

@Component({
  selector: 'irembogov-non-citizen-admin-portal-reset-password',
  templateUrl: './non-citizen-reset-password.component.html',
})
export class NonCitizenResetPasswordComponent implements OnInit {
  @Input() authorizationServiceBaseUrl!: string;
  @Input() authorizationClientId!: string;
  @Input() showBackToLogin = true;
  @Input() showPasswordStrengthMeter = false;
  @Input() showErrorTitle = false;
  @Input() showTermsCheckbox = false;
  @Input() resetPasswordTitle = 'Forgot password?';
  @Input() resetPasswordDescription =
    'No worries we will send you reset instructions';
  @Input() resetPasswordButtonLabel = 'Reset password';
  @Input() resendLinkLabel = 'Click to resend';
  @Input() resendLinkType: 'link' | 'button' = 'link';
  @Input() resetPasswordRegexErrorMessage: string =
    IremboPasswordRegExpErrorMsg;
  @Input() resetPasswordMatchErrorMessage =
    'Passwords must match before proceeding';
  @Input() accountNotFoundErrorTitle =
    'Sorry, the email you entered does not match any account.';
  @Input() accountNotFoundErrorMessage =
    'Sorry, the email you entered does not match any account. Please check your login details or contact your Account Admin.';
  @Input() accountNotActiveErrorTitle = 'Sorry, your account is inactive.';
  @Input() accountNotActiveErrorMessage =
    'Sorry, your account is inactive. Please contact your Account Admin.';

  @Input() createPasswordTitle = 'Set new password';
  @Input() createPasswordDescription =
    'Password must be at least 8 characters and contain a combination of letters, characters and numbers';
  @Input() createPasswordBtnActionLabel = 'Reset password';

  @Output() OnGoToLogin: EventEmitter<boolean> = new EventEmitter();
  @Input() resetPasswordSuccessTitle = 'Password reset';
  @Input() resetPasswordSuccessDescription =
    'Your password has been successfully reset. Click below to login automatically.';
  @Input() resetPasswordSuccessButtonActionLabel = 'Login';

  resetRequestStatus?:
    | 'tokenRequest'
    | 'tokenRequestSent'
    | 'passwordReset'
    | 'success'
    | 'failure';
  isSubmitting = false;
  isVerifyingToken = true;
  oneTimePasswordToken: string;
  username: string;
  noReceiveQuestion = "Didn't receive the email?";
  failureTitle = '';
  failureMessage = '';
  recipient?: string;
  showResendLink = false;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.oneTimePasswordToken =
      this.activatedRoute.snapshot.queryParamMap.get('token') ?? '';
    this.username =
      this.activatedRoute.snapshot.queryParamMap.get('username') ?? '';
  }

  ngOnInit(): void {
    if (this.oneTimePasswordToken && this.username) {
      this.checkActivationOtp();
    } else {
      this.isVerifyingToken = false;
      this.resetRequestStatus = 'tokenRequest';
    }
  }

  checkActivationOtp(): void {
    this.authService
      .checkPasswordResetOtp(
        this.authorizationServiceBaseUrl,
        this.oneTimePasswordToken,
        EUserType.OTHER,
        this.username
      )
      .pipe(finalize(() => (this.isVerifyingToken = false)))
      .subscribe({
        next: (
          response: IAuthEngineHttpBaseResponse<ICheckPasswordResetOtpTokenResponseData>
        ) => {
          if (response.data) {
            this.resetRequestStatus = 'passwordReset';
            return;
          }
          this.resetRequestStatus = 'failure';
        },
        error: (error: HttpErrorResponse) => {
          this.failureTitle = 'Link expired';
          this.failureMessage =
            'The password reset link you are trying to access has expired.';
          this.showResendLink = true;

          if (ExtractHttpErrorResponseCode(error) === 'OTP_EXPIRED') {
            this.failureTitle = 'Link expired';
            this.showResendLink = true;
          }

          this.resetRequestStatus = 'failure';
        },
      });
  }

  onGoToLogin(): void {
    this.OnGoToLogin.emit(true);
  }

  onEmailFormSubmit($event: string) {
    this.recipient = $event;
    const requestPasswordToken: IRequestPasswordResetTokenRequest = {
      username: $event,
      usernameType: ERecipientTypeEnum.EMAIL_ADDRESS,
      clientId: this.authorizationClientId,
      userType: EUserType.OTHER,
    };

    this.isSubmitting = true;
    this.isVerifyingToken = true;

    this.authService
      .requestPasswordResetToken(
        this.authorizationServiceBaseUrl,
        requestPasswordToken
      )
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.isVerifyingToken = false;
        })
      )
      .subscribe({
        next: () => {
          this.resetRequestStatus = 'tokenRequestSent';
        },
        error: (error: HttpErrorResponse) => {
          this.failureTitle = 'Password reset request failed';
          this.failureMessage = ExtractHttpErrorResponseMessage(
            error,
            'Could not request for a password reset link'
          );
          this.resetRequestStatus = 'failure';
        },
      });
  }

  onPasswordFormSubmit($event: string) {
    const requestPasswordRequest: IResetPasswordRequest = {
      oneTimePasswordToken: this.oneTimePasswordToken || '',
      newPassword: $event,
      clientId: this.authorizationClientId,
      userType: EUserType.OTHER,
      username: this.username,
    };

    this.isSubmitting = true;

    this.authService
      .resetPassword(this.authorizationServiceBaseUrl, requestPasswordRequest)
      .pipe(finalize(() => (this.isSubmitting = false)))
      .subscribe({
        next: () => {
          this.resetRequestStatus = 'success';
        },
        error: error => {
          this.failureTitle = 'Reset password failed';
          this.failureMessage = ExtractHttpErrorResponseMessage(
            error,
            'Could not reset password'
          );
          this.resetRequestStatus = 'failure';
        },
      });
  }

  resetToRequestNewToken() {
    this.resetRequestStatus = 'tokenRequest';
    this.isVerifyingToken = false;
  }
}
