<irembogov-full-width-layout-wrapper
  [title]="inSingleView ? 'User Change Request' : ''">
  <ng-container *ngIf="isLoading">
    <div
      class="w-99 d-flex align-items-center justify-content-center text-center h-100">
      <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </ng-container>

  <irembogov-irembo-section-message
    *ngIf="showGetChangeRequestErrorMessage"
    [type]="formAlertContent.type"
    [title]="formAlertContent.title"
    [message]="formAlertContent.message"></irembogov-irembo-section-message>

  <div *ngIf="!isLoading && changeRequest">
    <irembogov-change-request-basic-info
      [userChangeRequest]="changeRequest"></irembogov-change-request-basic-info>

    <div class="card bg-transparent border-0">
      <div class="card-body">
        <irembogov-user-change-request-badge
          [changeRequest]="changeRequest"
          [isLoadingProcess]="changeRequest.isProcessing"
          [isRejectingProcess]="changeRequest.isRejecting"
          [isApprovingProcess]="changeRequest.isApproving"
          [isDeactivatingProcess]="changeRequest.isDeactivating"
          [isActivatingProcess]="changeRequest.isActivating"
          [canApproveRequest]="checkIfCanApproveRequests(changeRequest)"
          (OnApproveRequestClick)="
            approveOrRejectChangeRequest($event, changeRequest)
          "
          (OnRejectRequestClick)="
            approveOrRejectChangeRequest($event, changeRequest)
          "></irembogov-user-change-request-badge>
      </div>
    </div>
  </div>
</irembogov-full-width-layout-wrapper>

<ng-template #deactivateWarningContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Please confirm!</h4>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p>Are you sure you would like to delete this user?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('cancel')">
      No
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('deactivate')">
      Yes
    </button>
  </div>
</ng-template>
