import { Component, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  IremboPasswordRegExp,
  IremboPasswordRegExpErrorMsg,
} from '../../utils/auth.utils';

@Component({
  selector: 'irembogov-activation-activate-account',
  templateUrl: './activation-activate-account.component.html',
  styleUrls: ['./activation-activate-account.component.scss'],
})
export class ActivationActivateAccountComponent {
  @Input() isLoading = false;
  @Input() resendEmailUrl?: string; // <-- Using the resendClicked event would be better. This would be taken out soon.
  @Input() loginUrl?: string;
  @Input() responseErrorMessage?: string;
  @Input() title?: string;
  @Input() description?: string;
  @Input() buttonActionLabel?: string;
  @Input() passwordRegExp: RegExp = IremboPasswordRegExp;
  @Input() regExpErrorMsg: string = IremboPasswordRegExpErrorMsg;
  @Input() passwordMatchErrorMsg = 'Passwords must match before proceeding';
  @Input() showResendButton = true;
  @Input() showLogo = true;
  @Input() showTermsCheckbox = true;
  @Input() showBackToLogin = true;
  @Input() showPasswordStrengthMeter = false;

  @Output() formSubmit: EventEmitter<string> = new EventEmitter<string>();
  @Output() goBack: EventEmitter<string> = new EventEmitter<string>();
  @Output() resendClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    if (this.buttonActionLabel == null || this.buttonActionLabel == undefined)
      this.buttonActionLabel = 'Confirm Password';
  }
  showPassword = false;
  showConfirmPassword = false;
  errorMessage = '';

  passwordForm: FormGroup = new FormGroup({
    password: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.passwordRegExp),
    ]),
    re_password: new FormControl<string>('', [Validators.required]),
  });

  agreeToTerms = false;

  onFormSubmit() {
    this.errorMessage = '';

    if (!this.passwordForm.valid) {
      // TODO: set translated error message
      this.errorMessage = 'Password Required';
      return;
    }

    if (
      this.passwordForm.value.password !== this.passwordForm.value.re_password
    ) {
      // TODO: set translated error message
      this.errorMessage = this.passwordMatchErrorMsg;
      return;
    }

    if (!this.validatePassword(this.passwordForm.value.password)) {
      // TODO: set translated error message
      this.errorMessage = this.regExpErrorMsg;
      return;
    }

    this.isLoading = true;
    this.formSubmit.emit(this.passwordForm.value.password);
  }

  validatePassword(password: string): boolean {
    return this.passwordRegExp.test(password);
  }

  public onSaveAgreeTermsChanged(value: boolean): void {
    this.agreeToTerms = value;
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
}
