import { Component, Input } from '@angular/core';

@Component({
  selector: 'irembogov-full-width-layout-wrapper',
  templateUrl: './full-width-layout-wrapper.component.html',
  styleUrls: ['./full-width-layout-wrapper.component.scss'],
})
export class FullWidthLayoutWrapperComponent {
  @Input() title = '';
}
