<div
  class="user-filters d-flex gap-2 button_group_container {{ uiStyle }} {{
    theme
  }}"
  [ngClass]="{ 'full-width': fullWidth }">
  <button
    type="button"
    *ngFor="let item of itemList"
    class="btn btn-sm {{
      selectedItem === item.key
        ? 'shadow-md button_group_item_active'
        : 'button_group_item_inactive'
    }}"
    [ngClass]="{
      'd-flex': iconAboveLabel,
      'flex-column': iconAboveLabel,
      'align-items-center': iconAboveLabel,
      'py-3': iconAboveLabel
    }"
    [ngStyle]="{ width: fullWidth ? 100 / itemList.length + '%' : 'initial' }"
    (click)="selectItem(item.key)">
    <i
      class="{{ item.icon }}"
      [ngClass]="{ 'mb-1': iconAboveLabel, 'me-2': !iconAboveLabel }"
      *ngIf="item.icon"></i>
    {{ item.label }}
  </button>
</div>
