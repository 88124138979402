import { UserRolesEnum } from '../models/users/user-roles.model';

export const UserCanApproveChangeRequestsForClientManagerRequests: UserRolesEnum[] =
  [UserRolesEnum.ROLE_IREMBOGOV_ADMIN];

export const CanApproveChangeRequestsForClientManagerRequests = (
  myRole: UserRolesEnum
): boolean => {
  return (
    UserCanApproveChangeRequestsForClientManagerRequests.indexOf(myRole) > -1
  );
};

export const CanCreateAgencyChangeRequestsForTheseUsers = (
  myRole: UserRolesEnum
): UserRolesEnum[] => {
  if (myRole === UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER) {
    return [
      UserRolesEnum.ROLE_AGENCY_ADMIN,
      UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
    ];
  }
  return [];
};

export const CanApproveClientManagerRequestsForTheseUsers = (
  myRole: UserRolesEnum
): UserRolesEnum[] => {
  if (myRole === UserRolesEnum.ROLE_IREMBOGOV_ADMIN) {
    return [
      UserRolesEnum.ROLE_AGENCY_ADMIN,
      UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
    ];
  }
  return [];
};
