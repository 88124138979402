<div *ngIf="getTotalPages() > 1" class="pagination-container">
  <div class="pagination-btns ms-2 p-3">
    <ng-container>
      <button
        (click)="previousPage()"
        [disabled]="page === 1"
        ngbPaginationPrevious
        class="previous-btn">
        {{ 'Previous' | translate }}
      </button>
      <button
        (click)="nextPage()"
        [disabled]="page === getTotalPages()"
        ngbPaginationNext
        class="next-btn">
        {{ 'Next' | translate }}
      </button>
    </ng-container>
  </div>
  <div class="pagination-page-info me-3 p-3">
    {{ 'Page' | translate }} {{ page }} of {{ getTotalPages() }}
  </div>
</div>
