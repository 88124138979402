export const ALLOWED_DOC_FILE_TYPES: string[] = [
  '.doc',
  '.docx',
  'application/msword',
  'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];

export const ALLOWED_IMAGE_FILE_TYPES: string[] = [
  'image/png',
  'image/x-png',
  'image/gif',
  'image/jpeg',
];
