<div class="irembo-user-basic-info" *ngIf="iremboUser">
  <div class="card irembo-user-profile-card border-0 mb-3" *ngIf="iremboUser">
    <div class="card-body">
      <div class="profile d-flex align-items-center">
        <div class="profile-info d-flex flex-column">
          <h5>{{ iremboUser.firstName }} {{ iremboUser.lastName }}</h5>
        </div>
      </div>
      <div class="profile-extras">
        <div class="text-muted d-flex my-3 gap-3">
          <span>
            <i class="fa-regular fa-envelope"></i>
            {{ iremboUser.email }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="roles-card px-3 py-3" *ngIf="iremboUser.userRoles">
    <p>Role(s)</p>

    <div class="card border-0 mb-2" *ngFor="let role of iremboUser.userRoles">
      <div class="card-body" *ngIf="role?.name?.startsWith('ROLE')">
        <div class="profile d-flex align-items-center justify-content-between">
          <small
            class="profile-role-title"
            style="text-transform: capitalize"
            >{{ role.name | roleToLabel }}</small
          >

          <div class="profile-info d-flex flex-column ms-3">
            <small class="profile-status">Active</small>
          </div>

          <div class="d-flex flex-row">
            <button
              *ngIf="
                userProfile?.primaryRole?.key ===
                  UserRolesEnum.ROLE_SUPER_ADMIN ||
                userProfile?.primaryRole?.key ===
                  UserRolesEnum.ROLE_PARTNERSHIP_MANAGER
              "
              class="btn btn-small profile-edit-btn profile-unassign text-danger"
              (click)="openModal(iremboUser.id!, role.name)">
              <div
                class="spinner-border sm-spinner mx-2"
                role="status"
                *ngIf="unsigningRole === role.name">
                <span class="visually-hidden">Loading...</span>
              </div>
              <i
                class="fa-solid fa-ban px-2"
                *ngIf="unsigningRole !== role.name"></i>
              Unassign Role
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #unassignRoleWarningContent let-modal>
  <div class="modal-header">
    <button
      type="button"
      class="btn"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <i class="fa-regular fa-circle-xmark color-danger"></i>
    </button>
    <h4 class="modal-title warning-content" id="modal-basic-title">
      <b>{{ currentRoleWarningContent }}</b>
    </h4>
  </div>
  <div class="py-3">
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-outline-dark mx-1"
        (click)="modal.close('cancel')">
        No
      </button>
      <button
        type="button"
        class="btn btn-danger mx-1"
        (click)="modal.close('accept')">
        Yes
      </button>
    </div>
  </div>
</ng-template>
