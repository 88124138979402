<div
  class="d-flex irembogov-table-inline-user-info align-items-center {{
    wrapClass
  }}">
  <ng-container *ngIf="showThumbnail">
    <div
      class="img-thumb shadow-sm me-1 flex-shrink-0"
      *ngIf="
        !isImgUrlBroken &&
        imgurl !== undefined &&
        imgurl !== null &&
        imgurl.length > 0
      "
      style="background-size: cover; background-image: url({{ imgurl }});">
      <img [src]="imgurl" [alt]="name" (error)="onImgUrlBroken()" />
    </div>
    <div
      class="img-thumb shadow-sm me-1 d-flex align-items-center"
      *ngIf="
        isImgUrlBroken ||
        imgurl === undefined ||
        imgurl === null ||
        imgurl.length <= 0
      "
      style="
        background-size: cover;
        background-color: #f7fbfe;
        align-items: center;
        align-content: center;
        margin-left: 2px;
      ">
      <h5 class="col-12 pt-0 mb-0 text-center">{{ name?.charAt(0) }}</h5>
    </div>
  </ng-container>

  <div
    class="user-info"
    style="
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 170px;
    ">
    <ng-template #nameTipContent>{{ name | titlecase }}</ng-template>
    <div
      *ngIf="name"
      class="overflow-text w-100 {{
        namePointerCursor ? 'pointer-link' : ''
      }} {{ nameActiveLink ? 'active' : '' }}"
      (click)="OnClickUserName.emit()"
      [ngbTooltip]="nameTipContent">
      <strong>
        <ng-container *ngIf="nameAsUpperCase">
          {{ name | uppercase }}
        </ng-container>
        <ng-container *ngIf="!nameAsUpperCase">
          {{ name | titlecase }}
        </ng-container>
      </strong>
    </div>
    <ng-container *ngIf="email">
      <ng-template #emailTipContent>{{ email }}</ng-template>
      <div
        class="d-flex w-100 text-muted {{
          emailPointerCursor ? 'pointer-link' : ''
        }} {{ emailActiveLink ? 'active' : '' }}
      "
        (click)="OnClickEmail.emit()"
        style="align-items: center"
        [ngbTooltip]="emailTipContent">
        <i
          class="fa-regular fa-envelope"
          *ngIf="showIcons"
          style="margin-right: 5px"></i>
        <small class="overflow-text w-100"> {{ email }}</small>
      </div>
    </ng-container>
    <div class="d-block">
      <small class="text-muted w-100" *ngIf="phone">
        <i class="fa-solid fa-phone" *ngIf="showIcons"></i>
        {{ phone }}
      </small>
      <small class="text-muted w-100 text-truncate" *ngIf="date">
        <i class="fa-regular fa-calendar" *ngIf="showIcons"></i>
        {{ date | date : 'dd/MM/yyyy' }}
      </small>
    </div>
  </div>
</div>
