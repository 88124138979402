import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UsersService } from '../../../../core/services/users.service';
import IIremboUser, {
  IremboClientUsersPostData,
  _BLANK_PROFILE,
} from '../../../../core/models/users/irembo-user-model';
import { IUnassignRoleRequest } from '../../../../core/models/users/unassign-role-request.model';
import { Subject, Subscription, finalize, takeUntil } from 'rxjs';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { environment } from '../../../../../environments/environment';
import { IIremboUserResponse } from '../../../../core/models/users/user-change-request-response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleToLabelPipe } from '../../../../pipes/role-to-label.pipe';
import { AuthService } from '../../../../core/services/auth.service';
import { UserRolesEnum } from '../../../../core/models/users/user-roles.model';
import { ClientService } from '../../../../core/services/client.service';
import { RequestTypeEnum } from '../../../../core/models/request-type.enum.model';

@Component({
  selector: 'irembogov-user-basic-info',
  templateUrl: './user-basic-info.component.html',
  styleUrls: ['./user-basic-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserBasicInfoComponent implements OnInit, OnDestroy {
  @Input() userData: IIremboUser = _BLANK_PROFILE;
  @Output() userUpdated = new EventEmitter();

  @ViewChild('unassignRoleWarningContent')
  unassignRoleWarningModal!: ElementRef;

  unsigningRole?: string;
  iremboUser?: IIremboUserResponse;
  userProfile!: IIremboUser;
  _userProfile!: Subscription;

  modalOpen = false;

  roleToLabelPipe = new RoleToLabelPipe();

  currentRoleWarningContent = '';

  UserRolesEnum = UserRolesEnum;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private usersService: UsersService,
    private clientService: ClientService,
    private toast: ToastService,
    private authService: AuthService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.iremboUser = this.userData as unknown as IIremboUserResponse;

    this._userProfile = this.authService.userProfile$.subscribe(
      (profile: IIremboUser) => {
        this.userProfile = profile;
      }
    );
  }

  openModal(userId: string, role: string): void {
    if (this.modalOpen) {
      return;
    }
    const roleFmt = this.roleToLabelPipe.transform(role);
    this.currentRoleWarningContent = `Are you sure you would like to initiate a request to unassign the role of ${roleFmt} from this user?`;
    this.modalService
      .open(this.unassignRoleWarningModal, {
        ariaLabelledBy: 'modal-basic-title',
      })
      .result.then(
        result => {
          this.modalOpen = false;

          if (result === 'accept') {
            this.unassignRole(userId, role);
            return;
          }
        },
        () => {
          this.modalOpen = false;
        }
      );
  }

  unassignRole(userId: string, role: string): void {
    if (role === UserRolesEnum.ROLE_SERVICE_MANAGER) {
      const serviceManagerRequest: IremboClientUsersPostData = {
        firstName: this.iremboUser!.firstName,
        lastName: this.iremboUser!.lastName,
        email: this.iremboUser!.email,
        requestType: RequestTypeEnum.UNASSIGN,
      };
      this.clientService
        .createServiceManagerRequest(serviceManagerRequest)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: value => {
            this.userData = value.data;
            this.toast.show({
              body: `Request to revoke ${this.roleToLabelPipe.transform(
                role
              )} access from ${
                serviceManagerRequest.firstName
              } has been successfully sent to the Admin`,
              type: 'success',
            });
            this.userUpdated.emit();
          },
          error: err => {
            this.toast.show({ body: err.error.responseMessage, type: 'error' });
          },
        });
    } else {
      this.unsigningRole = role;
      const request: IUnassignRoleRequest = {
        userId,
        roleName: role,
        userType: 'OTHER',
        clientId: environment.authClientId,
      };

      this.usersService
        .unassignUserRole(request)
        .pipe(finalize(() => (this.unsigningRole = undefined)))
        .subscribe({
          next: value => {
            this.userData = value.data;
            this.toast.show({ body: value.message, type: 'success' });
            this.userUpdated.emit();
          },
          error: err => {
            this.toast.show({ body: err.error.message, type: 'error' });
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
