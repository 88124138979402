import { UserRolesEnum } from '../models/users/user-roles.model';
import { RoleEnumToLabelUtil } from '../utils/authUtils';

/**
 *  Interface for filter buttons on manage users table
 */
export interface IManageUsersListsRoleFilter {
  label: string;
  role: UserRolesEnum | null;
}

/**
 * Configuration for filter buttons on manage users table
 */
export const ManageUsersListsRoleFilters: IManageUsersListsRoleFilter[] = [
  { label: 'All', role: null },
  { label: 'Admin', role: UserRolesEnum.ROLE_IREMBOGOV_ADMIN },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_PARTNERSHIP_MANAGER),
    role: UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_INTEGRATION_MANAGER),
    role: UserRolesEnum.ROLE_INTEGRATION_MANAGER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_QA_REVIEWER),
    role: UserRolesEnum.ROLE_QA_REVIEWER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_SERVICE_MANAGER),
    role: UserRolesEnum.ROLE_SERVICE_MANAGER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER),
    role: UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
  },
];

export const SuperAdminManageList: IManageUsersListsRoleFilter[] = [
  { label: 'Admin', role: UserRolesEnum.ROLE_IREMBOGOV_ADMIN },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_PARTNERSHIP_MANAGER),
    role: UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER),
    role: UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_INTEGRATION_MANAGER),
    role: UserRolesEnum.ROLE_INTEGRATION_MANAGER,
  },
];

export const IremboGovAdminManageList: IManageUsersListsRoleFilter[] = [
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_SERVICE_MANAGER),
    role: UserRolesEnum.ROLE_SERVICE_MANAGER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_QA_REVIEWER),
    role: UserRolesEnum.ROLE_QA_REVIEWER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_GOVERNMENT_EXEC),
    role: UserRolesEnum.ROLE_GOVERNMENT_EXEC,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_INTEGRATION_MANAGER),
    role: UserRolesEnum.ROLE_INTEGRATION_MANAGER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER),
    role: UserRolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
  },
  {
    label: RoleEnumToLabelUtil(UserRolesEnum.ROLE_PARTNERSHIP_MANAGER),
    role: UserRolesEnum.ROLE_PARTNERSHIP_MANAGER,
  },
];
