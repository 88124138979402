import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject, Observable } from 'rxjs';
import IIremboUser, { _BLANK_PROFILE } from '../models/users/irembo-user-model';
import { IUserRolesEnumValuePair } from '../models/users/user-roles.model';
import { ConvertKeycloakToIremboUserProfile } from '../utils/authUtils';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from './users.service';
import { RolesEnum } from '@irembo-andela/irembogov3-common';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loggedIn = false;
  private pendingProcessForLogout = false;
  private _showLogOutWarningModal: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private userProfile!: IIremboUser;
  private _userProfile: BehaviorSubject<IIremboUser> =
    new BehaviorSubject<IIremboUser>(this.userProfile);

  private userRole!: IUserRolesEnumValuePair;
  private _userRole: BehaviorSubject<IUserRolesEnumValuePair> =
    new BehaviorSubject<IUserRolesEnumValuePair>(this.userRole);

  public userProfile$: Observable<IIremboUser> =
    this._userProfile.asObservable();
  public userRole$: Observable<IUserRolesEnumValuePair> =
    this._userRole.asObservable();
  public showLogOutWarningModal$: Observable<boolean> =
    this._showLogOutWarningModal.asObservable();

  constructor(
    private http: HttpClient,
    private keyCloakService: KeycloakService,
    private router: Router,
    private offCanvasService: NgbOffcanvas,
    private usersService: UsersService
  ) {}

  async checkLoginProfile() {
    this.loggedIn = await this.isLoggedIn();
    this.userProfile = await this.loadUserProfile();
    await this.loadUserProfilePicture(this.userProfile.id || '');
    this.userRole = this.userProfile.primaryRole;
    this._userRole.next(this.userRole);
    this._userProfile.next(this.userProfile);
    return this.userProfile;
  }

  isLoggedIn(): Promise<boolean> {
    return this.keyCloakService.isLoggedIn();
  }

  private async loadUserProfile(): Promise<IIremboUser> {
    if (!this.loggedIn) {
      this.userProfile = _BLANK_PROFILE;
      this._userProfile.next(this.userProfile);
      return this.userProfile;
    }
    try {
      const keycloakProfile: KeycloakProfile =
        await this.keyCloakService.loadUserProfile();
      const keycloakRolesList: string[] = this.keyCloakService.getUserRoles();

      return ConvertKeycloakToIremboUserProfile(
        keycloakProfile,
        keycloakRolesList
      );
    } catch (e: any) {
      // TODO: Better error handling for error codes
      console.log(e, 'Can not get user profile from keycloak service');
    }
    return _BLANK_PROFILE;
  }

  private async loadUserProfilePicture(userId: string) {
    this.usersService
      .getUserProfilePictureById(userId)
      .pipe()
      .subscribe({
        next: response => {
          if (response.status) {
            this.userProfile.profilePicture = response.data;
            this.userProfile.profileImageUrl =
              'data:image/jpg;base64,' + response.data.file;
          }
        },
      });
  }

  updateIncompleteProcessState(state: boolean): void {
    this.pendingProcessForLogout = state;
  }

  redirectToLogin() {
    return this.keyCloakService.login({
      redirectUri:
        window.location.origin + this.router.routerState.snapshot.url,
    });
  }

  logOutUser(): void {
    const hasOpenOffCanvas: boolean = this.offCanvasService.hasOpenOffcanvas();
    if (this.pendingProcessForLogout || hasOpenOffCanvas) {
      this._showLogOutWarningModal.next(true);
      return;
    }
    this._showLogOutWarningModal.next(false);
    this.keyCloakService.logout();
  }
}
