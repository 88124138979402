import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';

@Injectable()
export class MockedKeycloakService extends KeycloakService {
  override init() {
    return Promise.resolve(true);
  }

  override loadUserProfile(
    forceReload?: boolean | undefined
  ): Promise<KeycloakProfile> {
    return Promise.resolve({
      userName: 'name',
      id: 'weqwqwe31',
      firstName: 'Sam',
      lastName: 'Ojobe',
      email: 'bioa.@sds.cc',
      enabled: true,
      emailVerified: true,
    });
  }

  override getUserRoles(allRoles?: boolean | undefined): string[] {
    return ['ROLE_PARTNERSHIP_MANAGER'];
  }

  override isLoggedIn(): Promise<boolean> {
    return Promise.resolve(true);
  }

  override getKeycloakInstance() {
    return {
      loadUserInfo: () => {
        let callback: (arg0: any /** { userName: string; } **/) => void;
        Promise.resolve()
          .then(() => {
            callback({
              userName: 'name',
              id: 'weqwqwe31',
              firstName: 'Sam',
              lastName: 'Ojobe',
              email: 'bioa.@sds.cc',
              enabled: true,
              emailVerified: true,
            });
          })
          .catch(err => {
            console.log(err);
          });
        return {
          success: (fn: any) => (callback = fn),
        };
      },
    } as any;
  }

  override logout(redirectUri?: string): Promise<void> {
    return redirectUri ? Promise.resolve() : Promise.reject();
  }
}
