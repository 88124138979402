<div ngbDropdown class="d-inline-block irembogov-sort-options-dropdwon">
  <div class="rounded px-3 py-1 shadow shadow-sm bg-white">
    <button
      type="button"
      class="btn bg-transparent border-0 px-0 mx-0"
      id="sortDirectionControl">
      <i
        class="fa-solid {{
          sortDirection === IrembogovSortDirectionsEnum.ASC.toString()
            ? 'fa-sort-alpha-down'
            : 'fa-sort-alpha-up'
        }}"
        (click)="toggleSortDirection()"></i>
    </button>
    <button
      type="button"
      class="btn bg-transparent border-0"
      id="sortSelectControl"
      ngbDropdownToggle>
      <small class="text-muted"> Sort by: </small>
      {{ selectedSortField?.label }}
    </button>
    <div ngbDropdownMenu aria-labelledby="sortSelectControl">
      <button
        ngbDropdownItem
        *ngFor="let sortField of sortFieldOptions"
        (click)="selectSortField(sortField)"
        [ngClass]="
          selectedSortField && sortField.key === selectedSortField.key
            ? 'bg-secondary'
            : ''
        ">
        {{ sortField.label }}
      </button>
    </div>
  </div>
</div>
