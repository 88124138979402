import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roleToLabel' })
export class RoleToLabelPipe implements PipeTransform {
  transform(role: string): string {
    switch (role) {
      case 'ROLE_SUPER_ADMIN':
        return 'Super Admin';
      case 'ROLE_IREMBOGOV_ADMIN':
        return 'IremboGov Admin';
      case 'ROLE_PARTNERSHIP_MANAGER':
        return 'Client Manager';
      case 'ROLE_INTEGRATION_MANAGER':
        return 'Integration Manager';
      case 'ROLE_SERVICE_MANAGER':
        return 'Service Manager';
      case 'ROLE_QA_REVIEWER':
        return 'QA Reviewer';
      case 'ROLE_ORGANIZATION_ADMIN':
        return 'Organization Admin';
      case 'ROLE_OFFICER_ADMIN':
        return 'Officer Admin';
      case 'ROLE_ORGANIZATION_SUPPORT':
        return 'Organization Support';
      case 'ROLE_ORGANIZATION_EXECUTIVE':
        return 'Organization Executive';
      case 'ROLE_OFFICER':
        return 'Officer';
      case 'ROLE_OFFICER_MANAGER':
        return 'Officer Manager';
      case 'ROLE_INTEGRATION_OPS':
        return 'Integration Ops';
      case 'ROLE_GOVERNMENT_EXEC':
        return 'Government Executive';
      case 'ROLE_CITIZEN':
        return 'Citizen';
      case 'ROLE_AGENT':
        return 'Agent';
      case 'ROLE_SERVICE_DISTRIBUTION_MANAGER':
        return 'Service Distribution Manager';
      default:
        return '';
    }
  }
}
