<div class="wrapper">
  <div class="container">
    <div id="welcome">
      <h1>
        <span>Welcome to the Administration Management Portal!</span>
      </h1>
      <hr />
    </div>
  </div>
</div>
