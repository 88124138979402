<h2 class="my-2 titletext font-weight-bold">Users</h2>
<div class="irembogov-list-users">
  <irembogov-button-group
    [itemList]="roleFilterButtons"
    [selectedItem]="(_filterObject | async)?.role?.toString()"
    (itemChange)="
      updateFilter({ role: $event, page: 0, size: 10 })
    "></irembogov-button-group>
  <irembogov-custom-table-wrapper
    [isSeachVisible]="false"
    [isSortVisible]="true"
    [sortFieldOptions]="sortOptions"
    [loading]="isLoadingUserList"
    [paginationVisible]="true"
    [totalItems]="collectionSize"
    [itemsPerPage]="(_filterObject | async)?.size || 10"
    [currentPage]="(_filterObject | async)?.page || 0"
    (paginationChange)="
      updateFilter({ page: $event.pageNumber - 1, size: $event.pageSize })
    "
    (sortChange)="
      updateFilter({
        sortBy: $event.sortBy,
        sortDirection: $event.sortDirection
      })
    ">
    <div
      rightHeader
      class="d-flex justify-content-between"
      style="border-radius: 5px">
      <button
        class="btn btn-primary btn-sm"
        type="button"
        (click)="updateSidePaneToggleState(true, 'CREATE_USER')">
        + Invite New User
      </button>
    </div>
    <table
      body
      class="table table-hover"
      aria-label="Irembo Users Table"
      class="table table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Email</th>
          <th scope="col">Date Created</th>
          <th scope="col"></th>
          <th scope="col">&nbsp;</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody class="text-centre rounded bg-white">
        <tr *ngFor="let user of usersList?.data?.content; let index = index">
          <td>
            <irembogov-table-inline-user-info
              (OnOnClickUserName)="setUserForViewPane(user)"
              [name]="user.firstName + ' ' + user.lastName"
              [imgurl]="user.profileImageUrl"
              [emailActiveLink]="true"
              [namePointerCursor]="false"
              [nameActiveLink]="false"
              [emailPointerCursor]="true">
            </irembogov-table-inline-user-info>
          </td>
          <td>{{ user.email }}</td>
          <td>{{ (user.createdOn | date) || 'N/A' }}</td>
          <td></td>
          <td></td>
          <td>
            <button
              class="btn btn-sm btn-outline-gray border"
              (click)="setUserForViewPane(user)">
              <i class="fa-regular fa-eye"></i> View
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </irembogov-custom-table-wrapper>
  <irembogov-offcanvas
    title="Invite new IremboGov user"
    description="Invite new user to join the platform"
    [paneOpen]="isSidePaneOpen && sidePaneViewType === 'CREATE_USER'"
    (OnPaneToggle)="updateSidePaneToggleState($event, 'CREATE_USER')">
    <ng-template>
      <irembogov-create-user
        (closeForm)="updateSidePaneToggleState(false, 'CREATE_USER')"
        (newUserFormSubmitted)="
          onNewUserInvited($event)
        "></irembogov-create-user>
    </ng-template>
  </irembogov-offcanvas>

  <irembogov-offcanvas
    title="User Info"
    [paneOpen]="isSidePaneOpen && sidePaneViewType === 'VIEW_USER'"
    (OnPaneToggle)="updateSidePaneToggleState(false, 'VIEW_USER')"
    #viewUserPane>
    <ng-template>
      <irembogov-user-basic-info
        [userData]="userForViewPane"
        (userUpdated)="userInfoViewClosed()"></irembogov-user-basic-info>
    </ng-template>
  </irembogov-offcanvas>
</div>
