import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate,
} from '@angular/router';
import IIremboUser from '../models/users/irembo-user-model';
import { UserRolesEnum } from '../models/users/user-roles.model';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AllowRouteDataRolesGuard implements CanActivate {
  userProfile!: IIremboUser;
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(
    _: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const allowedRoles: UserRolesEnum[] = _.data['roles'];
    const forceLogout: boolean | undefined = _.data['forceLogout'];

    if (await this.authService.isLoggedIn()) {
      this.userProfile = await this.authService.checkLoginProfile();
      return this.checkRoles(allowedRoles, forceLogout);
    }

    this.authService.redirectToLogin();
    return false;
  }

  async checkRoles(
    allowedRoles: UserRolesEnum[],
    forceLogout: boolean | undefined
  ): Promise<boolean> {
    if (allowedRoles.indexOf(this.userProfile.primaryRole.key) === -1) {
      return await this.checkForceLogout(forceLogout);
    }

    return true;
  }

  async checkForceLogout(forceLogout: boolean | undefined): Promise<boolean> {
    if (forceLogout) {
      this.authService.logOutUser();
    } else {
      this.router.navigate(['/']);
    }
    return false;
  }
}
