<label
  [for]="inputId"
  [ngClass]="displayAsButton ? 'button-upload form-label' : 'form-label'">
  <i class="fas fa-file-upload" *ngIf="displayAsButton"></i>{{ labelDesc }}
</label>
<div
  [ngClass]="displayAsButton ? 'mb-4 input-group' : 'input-group'"
  class="input-group">
  <input
    type="file"
    [hidden]="displayAsButton"
    [ngClass]="required ? 'form-control required' : 'form-control'"
    [(ngModel)]="file"
    [id]="inputId"
    [accept]="allowedFileTypes ? allowedFileTypes.join(',') : ''"
    [placeholder]="placeholder"
    (change)="uploadFile($event)" />
  <small
    [ngClass]="displayAsButton ? 'mt-4 text-muted' : 'text-muted'"
    *ngIf="fileName && displaySelectedFileName">
    {{ fileName }}
  </small>
  <div
    [innerHTML]="fileSizeErrorMessageAsHTML"
    *ngIf="isFileSizeValid === false"
    class="form-error w-100 small text-danger"></div>
</div>
