import { HttpErrorResponse } from '@angular/common/http';
import { Subscription, finalize } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  Component,
  OnDestroy,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { AuthService } from '../../../../../core/services/auth.service';
import { EChangeRequestStatus } from '../../../../../core/models/change-request-status.enum';
import { ExtractHttpErrorMessage } from '../../../../../core/utils/http-error-message-extractor.util';
import { ChangeRequestActionEventsEnum } from '../../../../../core/models/change-request-action-events.enum.model';
import { ClientService } from '../../../../../core/services/client.service';
import { ApproveUserRequest } from '../../../../../core/models/users/irembo-user-model';
import { ApproveChangeRequestStatusEnum } from '../../../../../core/models/approve-change-request-status.enum.model';
import { UserCanApproveChangeRequestsForClientManagerRequests } from '../../../../../core/config/manage-client-manager-route-action.config';
import { RequestTypeEnum } from 'src/app/core/models/request-type.enum.model';

@Component({
  selector: 'irembogov-client-manager-change-request-form',
  templateUrl: './client-manager-change-request-form.component.html',
})
export class ClientManagerChangeRequestFormComponent implements OnDestroy {
  @Input() changeRequest!: any;
  @Input() roleType!: any;
  @Input() isApprovingLoading = false;
  @Input() isRejectingLoading = false;
  userProfile!: any;
  _userProfile!: Subscription;
  notes = '';

  @ViewChild('rejectClientManagerRequest') orgRejectWarningContent!: ElementRef;
  @ViewChild('approveClientManagerChangeRequest')
  approveClientManagerChangeRequest!: ElementRef;
  @Output() OnCompleteProcessOnChangeRequest: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(
    private modalService: NgbModal,
    private clientService: ClientService,
    private authService: AuthService,
    private toastService: ToastService
  ) {
    this._userProfile = this.authService.userProfile$.subscribe(
      (profile: any) => {
        this.userProfile = profile;
      }
    );
  }

  checkIfApprovedOrRejected(): boolean {
    return this.changeRequest.requestStatus === EChangeRequestStatus.CREATED;
  }

  async approveChangeRequest() {
    this.isApprovingLoading = false;
    const confirmApproval: boolean = await this.getApprovalOrRejectionComments(
      this.approveClientManagerChangeRequest
    );

    if (!confirmApproval) {
      this.isApprovingLoading = false;
      return;
    }
    this.changeRequestTakeAction(ChangeRequestActionEventsEnum.APPROVE);
  }

  async rejectChangeRequest() {
    this.isRejectingLoading = false;

    const confirmReject: boolean = await this.getApprovalOrRejectionComments(
      this.orgRejectWarningContent
    );
    if (!confirmReject) {
      this.isRejectingLoading = false;
      return;
    }

    this.changeRequestTakeAction(ChangeRequestActionEventsEnum.REJECT);
  }

  getApprovalOrRejectionComments(content: any): Promise<boolean> {
    return this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(result => {
        if (result.action === 'approve' && result.notes.notes) {
          this.notes = result.notes.notes;
          return true;
        } else if (result.action === 'reject' && result.reasons) {
          this.notes = result.reasons.notes;
          return true;
        }
        return false;
      })
      .catch(() => false);
  }

  checkIfCanApproveOrgChangeRequests() {
    if (!(this.userProfile && this.userProfile.primaryRole)) return false;
    return (
      UserCanApproveChangeRequestsForClientManagerRequests.indexOf(
        this.userProfile.primaryRole.key
      ) > -1
    );
  }

  private changeRequestTakeAction(actionEvent: ChangeRequestActionEventsEnum) {
    if (!this.checkIfCanApproveOrgChangeRequests()) return;

    let approval!: ApproveChangeRequestStatusEnum;

    if (actionEvent === ChangeRequestActionEventsEnum.APPROVE) {
      this.isApprovingLoading = true;
      approval = ApproveChangeRequestStatusEnum.APPROVE;
    }
    if (actionEvent === ChangeRequestActionEventsEnum.REJECT) {
      this.isRejectingLoading = true;
      approval = ApproveChangeRequestStatusEnum.REJECT;
    }

    const roleType = this.CovertClientManagerListToRole(
      this.roleType ? this.roleType?.toString() : ''
    );
    const req: ApproveUserRequest = {
      id: this.changeRequest.id,
      actionRequest: approval,
      comment: this.notes,
    };

    this.clientService
      .approveOrRejectClientManagerRequest(req, roleType)
      .pipe(
        finalize(() => {
          this.isApprovingLoading = false;
          this.isRejectingLoading = false;
        })
      )
      .subscribe({
        next: () => {
          const message = this.getSuccessMessage(actionEvent);
          this.toastService.show({ body: message, type: 'success' });
          this.OnCompleteProcessOnChangeRequest.emit(true);
          this.notes = '';
        },
        error: (error: HttpErrorResponse) => {
          const errorMesage = ExtractHttpErrorMessage(
            error,
            `Failed to update ${this.roleType} Status`
          );
          this.toastService.show({ body: errorMesage, type: 'error' });
          this.notes = '';
        },
      });
  }

  getSuccessMessage(actionEvent: string): string {
    const action = actionEvent == 'APPROVE' ? 'approved' : 'rejected';
    if (this.changeRequest.requestType === RequestTypeEnum.UNASSIGN) {
      return `Request to unassign the role of ${this.roleType}
      from ${this.changeRequest.firstName} has been successfully ${action}`;
    } else {
      return `${this.changeRequest.firstName} as ${this.roleType} has been successfully ${action}`;
    }
  }

  CovertClientManagerListToRole(role: any) {
    if (role == 'Government Executives') {
      return 'ROLE_GOVERNMENT_EXEC';
    } else {
      if (role == 'Service Manager') {
        return 'ROLE_SERVICE_MANAGER';
      } else {
        return 'ROLE_QA_REVIEWER';
      }
    }
  }

  ngOnDestroy(): void {
    if (this._userProfile) this._userProfile.unsubscribe();
  }
}
