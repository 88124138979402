<div class="invite-user rounded bg-white p-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="mt-2 invite-user-form">
              <irembogov-irembo-section-message
                *ngIf="showFormErrorMessage"
                [type]="formAlertContent.type"
                [title]="formAlertContent.title"
                [message]="
                  formAlertContent.message
                "></irembogov-irembo-section-message>
              <form
                [formGroup]="createUserForm"
                (submit)="onSubmit()"
                #createForm="ngForm">
                <div class="form-group">
                  <label for="username" class="form-label required"
                    >User Email</label
                  >
                  <div class="input-group mb-3">
                    <input
                      formControlName="username"
                      type="email"
                      class="form-control"
                      [class.border-primary]="userExists"
                      id="username"
                      placeholder="Email Address" />
                    <div
                      class="form-error w-100 small text-danger"
                      *ngIf="
                        createUserForm.controls['username'].touched &&
                        !createUserForm.controls['username'].valid
                      ">
                      The email provided is invalid, please provide a correct
                      email to proceed
                    </div>
                    <div
                      class="w-100 small text-primary"
                      *ngIf="userExists && !isVerifyingEmail">
                      A user with the same email already exists
                    </div>
                    <div
                      class="w-100 small text-muted"
                      *ngIf="isVerifyingEmail">
                      Verifying this email...
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="!this.createUserForm.controls['id'].value">
                  <div class="form-group">
                    <label for="firstName" class="form-label required"
                      >First Name</label
                    >
                    <div class="input-group mb-3">
                      <input
                        formControlName="firstName"
                        type="text"
                        class="form-control"
                        id="firstName"
                        placeholder="First Name" />
                      <div
                        class="form-error w-100 small text-danger"
                        *ngIf="
                          createUserForm.controls['firstName'].touched &&
                          createUserForm.controls['firstName'].errors &&
                          createUserForm.controls['firstName'].errors[
                            'required'
                          ]
                        ">
                        First name is required.
                      </div>
                      <div
                        class="form-error w-100 small text-danger"
                        *ngIf="
                          createUserForm.controls['firstName'].touched &&
                          createUserForm.controls['firstName'].errors &&
                          createUserForm.controls['firstName'].errors['pattern']
                        ">
                        The name provided is invalid
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="lastName" class="form-label required"
                      >Last Name</label
                    >
                    <div class="input-group mb-3">
                      <input
                        formControlName="lastName"
                        type="text"
                        class="form-control"
                        id="lastName"
                        placeholder="Last Name" />
                      <div
                        class="form-error w-100 small text-danger"
                        *ngIf="
                          createUserForm.controls['lastName'].touched &&
                          createUserForm.controls['lastName'].errors &&
                          createUserForm.controls['lastName'].errors['required']
                        ">
                        Last name is required.
                      </div>
                      <div
                        class="form-error w-100 small text-danger"
                        *ngIf="
                          createUserForm.controls['lastName'].touched &&
                          createUserForm.controls['lastName'].errors &&
                          createUserForm.controls['lastName'].errors['pattern']
                        ">
                        The name provided is invalid
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="form-group">
                  <label for="role" class="form-label required">Role(s)</label>
                  <div class="input-group mb-3">
                    <irembogov-select
                      class="w-100"
                      bindLabel="label"
                      bindValue="key"
                      labelForId="role"
                      [items]="userCreateList"
                      [multiple]="true"
                      formControlName="role"></irembogov-select>
                    <div
                      class="form-error w-100 small text-danger"
                      *ngIf="
                        createForm.submitted &&
                        !createUserForm.controls['role'].valid
                      ">
                      Please select a role.
                    </div>
                  </div>
                </div>
                <div class="text-end pt-4">
                  <button
                    type="button"
                    class="btn btn-transparent text-muted mx-3"
                    [disabled]="isLoading"
                    (click)="cancelForm()">
                    Cancel
                  </button>
                  <button
                    class="btn btn-primary mt-1 mx-auto"
                    type="submit"
                    [disabled]="isLoading">
                    <div
                      *ngIf="isLoading"
                      class="spinner-border spinner-border-sm mr-2"
                      role="status"></div>
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
