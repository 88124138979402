<irembogov-full-width-layout-wrapper title="Users">
  <ul ngbNav #nav="ngbNav" [activeId]="route.fragment | async" class="nav-tabs">
    <li
      *ngFor="let tabLink of getTabsMenuList()"
      [ngbNavItem]="tabLink.fragment">
      <a routerLink="." [fragment]="tabLink.fragment" ngbNavLink>{{
        tabLink.title
      }}</a>
      <ng-template ngbNavContent>
        <ng-container
          *ngTemplateOutlet="getTabViewList(tabLink.fragment)"></ng-container>
      </ng-template>
    </li>
  </ul>
  <router-outlet [ngbNavOutlet]="nav" class="mt-2 p-3"></router-outlet>
</irembogov-full-width-layout-wrapper>

<ng-template #clientUsersTab>
  <irembogov-client-manager-users-list></irembogov-client-manager-users-list>
</ng-template>

<ng-template #clientchangeRequestsTab>
  <irembogov-client-manager-change-request-list></irembogov-client-manager-change-request-list>
</ng-template>
