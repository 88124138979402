import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  IUiAlertContent,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { UsersYouCanApproveChangeRequests } from '../../../../core/config/manage-users-route-actions.config';
import { finalize, Subscription } from 'rxjs';
import IIremboUser, {
  _BLANK_PROFILE,
} from '../../../../core/models/users/irembo-user-model';
import { UserRolesEnum } from '../../../../core/models/users/user-roles.model';
import { AuthService } from '../../../../core/services/auth.service';
import {
  IApproveUserChangeRequestRequestModel,
  IBaseApproveUserChangeRequestAndType,
} from '../../../../core/models/users/create-new-user-change-request.model';
import { UserChangeRequestsService } from '../../../../core/services/user-change-requests.service';
import { ExtractHttpErrorMessage } from '../../../../core/utils/http-error-message-extractor.util';
import { ChangeRequestTypeEnum } from '../../../../core/models/change-request-type.enum.model';
import { IUserChangeRequest } from '../../../../core/models/users/user-change-request-response.model';

@Component({
  selector: 'irembogov-view-user-change-request',
  templateUrl: './view-user-change-request.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ViewUserChangeRequestComponent implements OnInit, OnDestroy {
  @Input() requestId!: string;
  @Output() OnRequestUpdated: EventEmitter<boolean> = new EventEmitter();
  userProfile: IIremboUser = _BLANK_PROFILE;
  _userProfile!: Subscription;
  isLoading = false;
  inSingleView = false;

  changeRequest!: IUserChangeRequest;

  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };

  @ViewChild('deactivateWarningContent') deactivateWarningContent!: ElementRef;

  showGetChangeRequestErrorMessage = false;

  constructor(
    public route: ActivatedRoute,
    private userChangeRequestService: UserChangeRequestsService,
    private authService: AuthService,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this._userProfile = this.authService.userProfile$.subscribe(
      (profile: IIremboUser) => {
        this.userProfile = profile;
      }
    );

    this.route.paramMap.subscribe((params: ParamMap) => {
      const paramRequestId: string | null = params.get('requestId');
      if (paramRequestId) {
        this.inSingleView = true;
        this.requestId = paramRequestId;
      }
    });
  }

  ngOnInit(): void {
    this.getUserChangeRequestById();
  }

  getUserChangeRequestById(): void {
    this.showGetChangeRequestErrorMessage = false;
    this.isLoading = true;
    this.userChangeRequestService
      .getChangeRequestById(this.requestId)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (response: IUserChangeRequest) => {
          this.changeRequest = response;
        },
        error: (error: unknown) => {
          const errorMessage = ExtractHttpErrorMessage(
            error,
            `Could not fetch change request: #${this.requestId}`
          );
          this.formAlertContent.type = 'danger';
          this.formAlertContent.title = 'Error encountered: ';
          this.formAlertContent.message = errorMessage;
          this.showGetChangeRequestErrorMessage = true;
        },
      });
  }

  approveOrRejectChangeRequest(
    event: IBaseApproveUserChangeRequestAndType,
    changeRequest: IUserChangeRequest
  ): void {
    this.isLoading = true;
    this.toggleStatusLoaderProcess(event.requestStatus, true);
    const req: IApproveUserChangeRequestRequestModel = {
      id: String(changeRequest.id),
      userRole: changeRequest.role,
      userId: changeRequest.userId,
      requestType: event.requestType,
      requestStatus: event.requestStatus,
    };
    this.userChangeRequestService
      .approveUserChangeRequest(req)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.toggleStatusLoaderProcess(event.requestStatus, false);
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe({
        next: (res: { message: string }) => {
          const message = res.message || `Update change request succesful.`;
          this.toastService.show({ body: message, type: 'success' });
          this.getUserChangeRequestById();
          this.OnRequestUpdated.emit(true);
        },
        error: (error: any) => {
          const errorMesage = ExtractHttpErrorMessage(
            error,
            'Failed to update change Request'
          );
          this.toastService.show({ body: errorMesage, type: 'error' });
        },
      });
  }

  toggleStatusLoaderProcess(
    requestStatusType: ChangeRequestTypeEnum,
    value: boolean
  ): void {
    this.changeRequest.isProcessing = value;
    switch (requestStatusType) {
      case ChangeRequestTypeEnum.APPROVED:
        this.changeRequest.isApproving = value;
        this.changeRequest.isDeactivating = value;
        this.changeRequest.isActivating = value;
        break;
      case ChangeRequestTypeEnum.REJECT_DEACTIVATION:
      case ChangeRequestTypeEnum.REJECTED:
        this.changeRequest.isRejecting = value;
        break;
      default:
        return;
    }
  }

  checkIfCanApproveRequests(request: IUserChangeRequest) {
    if (!(request && request.role)) return false;
    const approveRoles: UserRolesEnum[] = UsersYouCanApproveChangeRequests(
      this.userProfile.primaryRole.key
    );
    return approveRoles.indexOf(request.role) > -1;
  }

  ngOnDestroy(): void {
    if (this._userProfile) this._userProfile.unsubscribe();
  }
}
