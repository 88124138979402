import { RolesEnum } from '../enums/roles.enum';

export const IremboPasswordRegExp =
  /^(?=.*\d)(?=.*[a-zA-Z-#$.%&*@])(?=.*[a-zA-Z]).{8,}$/;
export const IremboPasswordRegExpErrorMsg =
  'Password should  be at least 8 characters long, and may contain letters and numbers';

const rolesToLabelHash: Record<string, RolesEnum> = {
  'Super Admin': RolesEnum.ROLE_SUPER_ADMIN,
  'IremboGov Admin': RolesEnum.ROLE_IREMBOGOV_ADMIN,
  'Client Manager': RolesEnum.ROLE_PARTNERSHIP_MANAGER,
  'Integration Manager': RolesEnum.ROLE_INTEGRATION_MANAGER,
  'Service Manager': RolesEnum.ROLE_SERVICE_MANAGER,
  'QA Reviewer': RolesEnum.ROLE_QA_REVIEWER,
  'Organization Admin': RolesEnum.ROLE_ORGANIZATION_ADMIN,
  'Officer Admin': RolesEnum.ROLE_OFFICER_ADMIN,
  'Organization Support': RolesEnum.ROLE_ORGANIZATION_SUPPORT,
  'Organization Executive': RolesEnum.ROLE_ORGANIZATION_EXECUTIVE,
  Officer: RolesEnum.ROLE_OFFICER,
  'Officer Manager': RolesEnum.ROLE_OFFICER_MANAGER,
  'Integration Ops': RolesEnum.ROLE_INTEGRATION_OPS,
  'Government Executive': RolesEnum.ROLE_GOVERNMENT_EXEC,
  Citizen: RolesEnum.ROLE_CITIZEN,
  Agent: RolesEnum.ROLE_AGENT,
  'Service Distribution Manager': RolesEnum.ROLE_SERVICE_DISTRIBUTION_MANAGER,
};

export const RoleEnumToLabelUtil = (role: RolesEnum | undefined): string => {
  let matchedLabel = '';
  if (!role) return matchedLabel;

  Object.keys(rolesToLabelHash).some((key: string) => {
    if (rolesToLabelHash[key] === role) {
      matchedLabel = key;
      return true;
    }
    return false;
  });

  return matchedLabel;
};

export const RoleLabelToEnumUtil = (label: string | undefined): RolesEnum => {
  let matchedRole: RolesEnum = RolesEnum._UNKNOWN_ROLE_;
  if (!label) return matchedRole;

  Object.keys(rolesToLabelHash).some((key: string) => {
    if (key.toLowerCase() === label.toLowerCase()) {
      matchedRole = rolesToLabelHash[key];
      return true;
    }
    return false;
  });

  return matchedRole;
};
