import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullWidthLayoutWrapperComponent } from './full-width-layout-wrapper/full-width-layout-wrapper.component';
import { FileUploadInputComponent } from './file-upload-input/file-upload-input.component';
import { FormsModule } from '@angular/forms';
import { ChangeRequestStatusBadgeComponent } from './change-request-status-badge/change-request-status-badge.component';
import { LoadingWrapperComponent } from './loading-wrapper/loading-wrapper.component';
import { ChangeRequestTypeToLabelAndIconPipe } from '../../core/pipes/change-request-type-to-label-and-icon.pipe';
import { ChangeRequestTypeBadgeComponent } from './change-request-type-badge/change-request-type-badge.component';
import { ChangeRequestTakeActionButtonsComponent } from './change-request-take-action-buttons/change-request-take-action-buttons.component';
import { StatusValueEnumToLabelAndIconPipe } from '../../core/pipes/status-value-to-label-and-icon.pipe';
import { AgentEducationLevelToLabelPipe } from '../../core/pipes/agent-education-level-to-label.pipe';
import { AgencyStatusValueEnumToLabelAndIconPipe } from '../../core/pipes/agency-status-value-to-label-and-icon.pipe';
import { SwitchComponent } from './switch/switch.component';
import { CardComponent } from './card/card.component';
import { FetchedUserDetailsComponent } from './fetched-user-details/fetched-user-details.component';

@NgModule({
  declarations: [
    FullWidthLayoutWrapperComponent,
    FileUploadInputComponent,
    ChangeRequestStatusBadgeComponent,
    ChangeRequestTypeBadgeComponent,
    ChangeRequestTakeActionButtonsComponent,
    LoadingWrapperComponent,
    ChangeRequestTypeToLabelAndIconPipe,
    StatusValueEnumToLabelAndIconPipe,
    AgencyStatusValueEnumToLabelAndIconPipe,
    AgentEducationLevelToLabelPipe,
    CardComponent,
    SwitchComponent,
    FetchedUserDetailsComponent,
  ],
  imports: [CommonModule, FormsModule],
  exports: [
    FullWidthLayoutWrapperComponent,
    FileUploadInputComponent,
    ChangeRequestStatusBadgeComponent,
    ChangeRequestTypeBadgeComponent,
    ChangeRequestTakeActionButtonsComponent,
    LoadingWrapperComponent,
    ChangeRequestTypeToLabelAndIconPipe,
    AgencyStatusValueEnumToLabelAndIconPipe,
    StatusValueEnumToLabelAndIconPipe,
    AgentEducationLevelToLabelPipe,
    CardComponent,
    SwitchComponent,
    FetchedUserDetailsComponent,
  ],
})
export class SharedModule {}
