import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'irembogov-password-strength-meter',
  templateUrl: './password-strength-meter.component.html',
  styleUrls: ['./password-strength-meter.component.css'],
})
export class PasswordStrengthMeterComponent implements OnChanges {
  @Input() password!: string;

  private lengthWeight = 0.125;
  private uppercaseWeight = 0.5;
  private lowercaseWeight = 0.5;
  private numberWeight = 1;
  private symbolWeight = 1;
  strength = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['password']) {
      this.calculatePasswordStrength(changes['password']?.currentValue);
    }
  }

  calculatePasswordStrength(password: string) {
    this.strength = 0;

    // Calculate the strength based on the password length
    this.strength += Math.min(8, password.length) * this.lengthWeight;

    // Calculate the strength based on uppercase letters
    if (/[A-Z]/.test(password)) {
      this.strength += this.uppercaseWeight;
    }

    // Calculate the strength based on lowercase letters
    if (/[a-z]/.test(password)) {
      this.strength += this.lowercaseWeight;
    }

    // Calculate the strength based on numbers
    if (/[0-9]/.test(password)) {
      this.strength += this.numberWeight;
    }

    // Calculate the strength based on symbols
    if (/[^A-Za-z0-9]/.test(password)) {
      this.strength += this.symbolWeight;
    }
  }
}
