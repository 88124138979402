import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RolesEnum } from '@irembo-andela/irembogov3-common';
import { Subject, takeUntil } from 'rxjs';
import IIremboUser from '../../../core/models/users/irembo-user-model';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'irembogov-manage-users',
  templateUrl: './manage-users.component.html',
})
export class ManageUsersComponent implements OnDestroy {
  userProfile!: IIremboUser;
  private unsubscribe$: Subject<void>;

  RolesEnum = RolesEnum;

  constructor(public route: ActivatedRoute, private authService: AuthService) {
    this.unsubscribe$ = new Subject<void>();
    this.authService.userProfile$
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile: IIremboUser) => (this.userProfile = profile));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
