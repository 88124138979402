<ng-template [ngIf]="resendRequestStatus === 'pending'">
  <irembogov-resend-auth-otp
    (OnCancelClicked)="onGoToLogin()"
    (OnGoToActionClicked)="onContinue()"
    (OnResendToEmailClicked)="resendToEmailClicked($event)"
    (OnResendToPhoneClicked)="
      resendToPhoneClicked($event)
    "></irembogov-resend-auth-otp>
</ng-template>

<ng-template [ngIf]="resendRequestStatus === 'success'">
  <irembogov-token-sent
    (goBack)="onGoToLogin()"
    (resend)="resetToRetry()"
    [recipient]="recipient"
    [title]="successTitle"
    [noReceiveQuestion]="successQuestion"
    recipientMessage="{{
      'We sent account activation link to' | translate
    }}"></irembogov-token-sent>
</ng-template>

<ng-template [ngIf]="resendRequestStatus === 'failure'">
  <irembogov-activation-link-expired
    title="Token not sent"
    [description]="sendFailMessage"
    resendDescription="Do you want to try again?"
    resendLinkText="Click here"
    (resendLinkClick)="resetToRetry()"
    [showResendLink]="true"
    (goBack)="onGoToLogin()"></irembogov-activation-link-expired>
</ng-template>
