<ng-container *ngIf="isVerifyingToken">
  <div
    class="w-99 d-flex align-items-center justify-content-center text-center h-100">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!isVerifyingToken">
  <ng-template [ngIf]="accountActivationRequestStatus === 'otp'">
    <irembogov-otp-entry
      (OnResendClicked)="OnGoToResendOtp.emit(true)"></irembogov-otp-entry>
  </ng-template>

  <ng-template [ngIf]="accountActivationRequestStatus === 'pending'">
    <irembogov-activation-activate-account
      [title]="accountActivationTitle"
      [description]="accountActivationDescription"
      [buttonActionLabel]="accountActivationButtonActionLabel"
      [isLoading]="isSubmitting"
      [responseErrorMessage]="activatedErrorMessage"
      [showResendButton]="false"
      [showBackToLogin]="showBackToLogin"
      [showLogo]="showLogo"
      (resendClicked)="OnGoToResendOtp.emit(true)"
      (formSubmit)="onActivationFormSubmit($event)"
      [showPasswordStrengthMeter]="showPasswordStrengthMeter"
      [regExpErrorMsg]="accountActivationRegexErrorMessage"
      [passwordMatchErrorMsg]="accountActivationPasswordMatchErrorMessage"
      (goBack)="onGoToLogin()"></irembogov-activation-activate-account>
  </ng-template>

  <ng-template [ngIf]="accountActivationRequestStatus === 'success'">
    <irembogov-auth-action-successful
      [title]="successTitle"
      [description]="successMessage"
      [buttonActionLabel]="successButtonActionLabel"
      (continue)="onContinue()"></irembogov-auth-action-successful>
  </ng-template>

  <ng-template [ngIf]="accountActivationRequestStatus === 'failure'">
    <irembogov-activation-link-expired
      (resendLinkClick)="OnGoToResendOtp.emit(true)"
      [showResendLink]="true"
      [description]="failureMessage"
      [title]="failureTitle"
      [showNavigation]="false"
      [showLogo]="showExpiredLogo"
      [resendLinkType]="resendLinkType"
      [resendLinkText]="resendLinkText"
      (goBack)="onGoToLogin()"></irembogov-activation-link-expired>
  </ng-template>

  <ng-template [ngIf]="accountActivationRequestStatus === 'expired'">
    <irembogov-activation-link-expired
      (resendLinkClick)="resendWithExpiredOtp()"
      [showResendLink]="true"
      [description]="failureMessage"
      [resendDescription]="failureResendDescription"
      [title]="failureTitle"
      [showNavigation]="showBackToLogin"
      [showLogo]="showExpiredLogo"
      [resendLinkType]="resendLinkType"
      [resendLinkText]="resendLinkText"
      (goBack)="onGoToLogin()"></irembogov-activation-link-expired>
  </ng-template>

  <ng-template [ngIf]="accountActivationRequestStatus === 'activated'">
    <irembogov-activation-account-activated
      [title]="defaultActivatedErrorTitle"
      [description]="defaultActivatedErrorMessage"
      [buttonActionLabel]="activatedButtonActionLabel"
      [showLogo]="showActivatedLogo"
      (goToLogin)="onGoToLogin()">
    </irembogov-activation-account-activated>
  </ng-template>

  <ng-template [ngIf]="accountActivationRequestStatus === 'tokenSent'">
    <irembogov-token-sent
      (goBack)="onGoToLogin()"
      (resend)="resendWithExpiredOtp()"
      [title]="successTitle"
      [showLogo]="showLogo"
      [showBackToLogin]="showBackToLogin"
      [resendLinkText]="resendLinkText"
      [recipientMessage]="successMessage">
      ></irembogov-token-sent
    >
  </ng-template>
</ng-container>
