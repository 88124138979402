import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, finalize, Subject, takeUntil } from 'rxjs';
import {
  IFilterAndPaginationFieldsBase,
  IIrembogovBasicLabelKeyPair,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { IremboGovAdminManageList } from '../../../../core/config/manager-users-list-filters.config';
import { IremboManagersData } from '../../../../core/models/users/irembo-user-model';
import { AuthService } from '../../../../core/services/auth.service';
import { UsersService } from '../../../../core/services/users.service';
import { ExtractHttpErrorMessage } from '../../../../core/utils/http-error-message-extractor.util';
import { IHttpPagedResponse } from '@irembo-andela/irembogov3-common';
import { FormControl } from '@angular/forms';
import { UserRolesEnum } from '../../../../core/models/users/user-roles.model';

interface IFilterFields extends IFilterAndPaginationFieldsBase {
  role: string;
  sortBy: string;
  sortDirection: 'ASC' | 'DESC';
}

type SidePaneViewType = 'CREATE_USER' | 'VIEW_USER';

@Component({
  selector: 'irembogov-users-table-for-irembogov-admin',
  templateUrl: './users-table-for-irembogov-admin.component.html',
  styleUrls: ['./users-table-for-irembogov-admin.component.scss'],
})
export class UsersTableForIremboGovAdminComponent implements OnDestroy, OnInit {
  userForViewPane!: IremboManagersData;
  userProfile!: unknown;

  searchFieldControl: FormControl = new FormControl('');

  isSidePaneOpen = false;
  isLoadingUserList = false;

  sortOptions: IIrembogovBasicLabelKeyPair<Record<string, string>>[] = [
    {
      label: 'Date Created, Ascending',
      key: { sortBy: 'createdOn', sortDirection: 'ASC' },
    },
    {
      label: 'Date Created, Descending',
      key: { sortBy: 'createdOn', sortDirection: 'DESC' },
    },
  ];
  page = 0;
  pageSize = 10;
  collectionSize = 0;
  usersList?: IHttpPagedResponse<IremboManagersData>;
  roleFilterButtons: IIrembogovBasicLabelKeyPair<string>[] =
    IremboGovAdminManageList?.map(i => ({
      label: i.label,
      key: i.role?.toString() || '',
    }));
  sidePaneViewType: SidePaneViewType;

  private unSubscriber$ = new Subject<void>();
  _filterObject: BehaviorSubject<IFilterFields>;

  constructor(
    private usersService: UsersService,
    private toastService: ToastService,
    private authService: AuthService
  ) {
    this.sidePaneViewType = 'CREATE_USER';

    this.authService.userProfile$
      .pipe(takeUntil(this.unSubscriber$))
      .subscribe(profile => {
        this.userProfile = profile;
      });

    this._filterObject = new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 10,
      role: UserRolesEnum.ROLE_SERVICE_MANAGER,
      sortBy: 'createdOn',
      sortDirection: 'ASC',
    });
  }

  ngOnInit(): void {
    this.subscribeToQueryObject();
  }

  ngOnDestroy(): void {
    this.unSubscriber$.next();
    this.unSubscriber$.complete();
  }

  subscribeToQueryObject() {
    this._filterObject
      .pipe(takeUntil(this.unSubscriber$))
      .subscribe((filter: IFilterFields) => {
        this.page = filter.page ? filter.page : 0;
        this.pageSize = filter.size ? filter.size : 10;
        this.getUsersByRoles(filter);
      });
  }

  updateFilter(keyPair: Partial<IFilterFields>) {
    this._filterObject.next({
      ...this._filterObject.getValue(),
      ...keyPair,
    });
  }

  private refreshList() {
    this.updateFilter({
      page: 0,
      size: 10,
      role: UserRolesEnum.ROLE_SERVICE_MANAGER,
      sortBy: 'createdOn',
      sortDirection: 'ASC',
    });
  }

  private getUsersByRoles(filters: IFilterFields): void {
    this.isLoadingUserList = true;
    this.usersService
      .getUsersByRolesforIremboGovAdmin(
        filters.role,
        filters.sortBy,
        filters.sortDirection,
        filters.page || 0,
        filters.size || 10
      )
      .pipe(finalize(() => (this.isLoadingUserList = false)))
      .subscribe({
        next: (response: IHttpPagedResponse<IremboManagersData>) => {
          this.usersList = response;
          this.collectionSize = this.pageSize * response.data.totalPages;
        },
        error: err => {
          const errorMessage = ExtractHttpErrorMessage(
            err,
            'Failed to fetch users.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  updateSidePaneToggleState(
    openState: boolean,
    sidePaneViewType: SidePaneViewType
  ) {
    this.isSidePaneOpen = openState;
    this.sidePaneViewType = sidePaneViewType;
  }

  onNewUserInvited(message: string) {
    this.updateSidePaneToggleState(false, 'CREATE_USER');
    this.toastService.show({ body: message, type: 'success' });
    this.refreshList();
  }

  setUserForViewPane(user: IremboManagersData) {
    this.userForViewPane = { ...user };
    this.updateSidePaneToggleState(true, 'VIEW_USER');
  }

  userInfoViewClosed() {
    this.updateSidePaneToggleState(false, 'CREATE_USER');
    this.refreshList();
  }
}
